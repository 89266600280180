import { tokenProvider } from './tokenProvider';

export const authProvider = () => {
  const provider = tokenProvider();

  const login: typeof provider.setToken = (newTokens) => {
    provider.setToken(newTokens);
  };

  const logout = () => {
    provider.setToken(null);
    provider.setProfile(null);
  };

  const isAuth = () => {
    return !!provider.isLoggedIn();
  };

  const setProfile: typeof provider.setProfile = (profile) => {
    provider.setProfile(profile);
  };

  const profile = provider.getProfile();

  const token = provider.getToken();

  return {
    login,
    logout,
    isAuth,
    token,
    profile,
    setProfile,
  };
};
