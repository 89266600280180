import React, { useState, useMemo, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { AUTH_FIELDS, InputValuesType } from '../../types';
import { Text, AppInput, ProgressBar, AppButton } from '../../components';
import { API, COLORS } from '../../const';
import { Wrapper, Container, FormContainer, BackButton, PasswordBlock } from './styled';
import { authProvider } from '../../utils';

import closePath from '../../assets/icons/close.svg';

export const EMAIL_REXEXP = /\S+@\S+\.\S+/;
export const LENGTH_REGEXP = /^(?!.* ).{8,16}$/;
export const LATIN_REGEXP = /^\w/;
export const CHARS_REGEXP = /^(?=.*[a-z])(?=.*[A-Z])(?!.* )/;
export const NUMBERS_REGEXP = /^(?=.*[0-9])(?!.* )/;

type Props = {
  onNext: () => void;
};

export const Form: FC<Props> = ({ onNext }) => {
  const [repeatValue, setRepeatValue] = useState('');
  const [passwordsMismatch, setPasswordsMismatch] = useState(false);

  const [lengthError, setLengthError] = useState(false);
  const [latinError, setLatinError] = useState(false);
  const [charsError, setCharsError] = useState(false);
  const [numbersError, setNumbersError] = useState(false);

  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [regError, setRegError] = useState('');

  const [values, setValues] = useState<InputValuesType>({
    [AUTH_FIELDS.BIRTHDAY]: '1990-01-01',
  });

  const passwordError = useMemo(() => {
    return lengthError || latinError || charsError || numbersError;
  }, [latinError, lengthError, charsError, numbersError]);

  const onValidateEmail = () => {
    if (
      values &&
      values[AUTH_FIELDS.USERNAME] &&
      !EMAIL_REXEXP.test(values[AUTH_FIELDS.USERNAME])
    ) {
      setIsEmailValid(false);
    } else {
      setIsEmailValid(true);
    }
  };

  const navigate = useNavigate();
  const { login } = authProvider();

  const onLogin = async () => {
    try {
      const response = await fetch(`${API}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (response.status === 200) {
        const token = await response.json();
        login(token);
        onNext();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const postStaticRegistation = async () => {
    try {
      const response = await fetch(`${API}/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
      if (response.status === 200) {
        onLogin();
      }
      if (response.status === 400) {
        setRegError('Email уже используется');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const isDisabled = useMemo(() => {
    return Object.values(values).some((value) => !value);
  }, [values]);

  const resetError = () => {
    setLatinError(false);
    setLengthError(false);
    setCharsError(false);
    setNumbersError(false);
  };

  const onChange = (name: string, value: string) => {
    if (name === AUTH_FIELDS.PASSWORD) {
      setPasswordsMismatch(false);
      resetError();
    }

    if (name === AUTH_FIELDS.USERNAME) {
      setIsEmailValid(true);
    }
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const onChangeRepeat = (name: string, value: string) => {
    setPasswordsMismatch(false);
    setRepeatValue(value);
  };

  const onValidatePassword = (name: string, value: string) => {
    if (value && !LENGTH_REGEXP.test(value)) {
      setLengthError(true);
    }

    if (value && !LATIN_REGEXP.test(value)) {
      setLatinError(true);
      setCharsError(true);
    }

    if (value && !CHARS_REGEXP.test(value)) {
      setCharsError(true);
    }

    if (value && !NUMBERS_REGEXP.test(value)) {
      setNumbersError(true);
    }
  };

  const onValidateRepeatPasswords = () => {
    if (
      repeatValue &&
      values[AUTH_FIELDS.PASSWORD] &&
      repeatValue !== values[AUTH_FIELDS.PASSWORD]
    ) {
      setPasswordsMismatch(true);
    } else {
      setPasswordsMismatch(false);
    }
  };

  const onBack = () => {
    navigate('/auth');
  };

  return (
    <Container>
      <Wrapper>
        <BackButton onClick={onBack}>
          <img src={closePath} alt="arrow" />
        </BackButton>
        <Text pb={16} size={16} weight={600} textAlign="center">
          Новый профиль
        </Text>

        <ProgressBar count={2} active={1} />

        <FormContainer>
          <AppInput
            name={AUTH_FIELDS.FIRST_NAME}
            value={values[AUTH_FIELDS.FIRST_NAME]}
            label="Имя"
            onChange={onChange}
          />

          <AppInput
            name={AUTH_FIELDS.LAST_NAME}
            value={values[AUTH_FIELDS.LAST_NAME]}
            label="Фамилия"
            onChange={onChange}
          />

          <AppInput
            type="date"
            name={AUTH_FIELDS.BIRTHDAY}
            value={values[AUTH_FIELDS.BIRTHDAY]}
            label="Дата рождения"
            onChange={onChange}
          />

          <AppInput
            name={AUTH_FIELDS.USERNAME}
            value={values[AUTH_FIELDS.USERNAME]}
            label="Электронная почта"
            onChange={onChange}
            onBlur={onValidateEmail}
            error={!isEmailValid || !!regError}
            errorMessage={regError ? regError : 'Неверный формат'}
          />

          <AppInput
            type="password"
            name={AUTH_FIELDS.PASSWORD}
            value={values[AUTH_FIELDS.PASSWORD]}
            label={!values[AUTH_FIELDS.PASSWORD] ? 'Пароль' : ''}
            onChange={onChange}
            onBlur={onValidatePassword}
            error={passwordsMismatch || passwordError}
            errorMessage={passwordsMismatch ? 'Пароли не совпадают' : ''}
          />

          {(!!values[AUTH_FIELDS.PASSWORD] || repeatValue) && (
            <AppInput
              type="password"
              name="password_repeat"
              value={repeatValue}
              label={!repeatValue ? 'Введите повторно' : ''}
              onChange={onChangeRepeat}
              onBlur={onValidateRepeatPasswords}
              error={passwordsMismatch}
              errorMessage="Пароли не совпадают"
            />
          )}
        </FormContainer>

        <PasswordBlock>
          <Text pt={16} size={12} color={COLORS.text_grey}>
            *Пароль должен содержать:
          </Text>
          <Text size={12} color={lengthError ? COLORS.main_red : COLORS.text_grey}>
            {' '}
            - от 8 до 16 символов
          </Text>
          <Text size={12} color={latinError ? COLORS.main_red : COLORS.text_grey}>
            {' '}
            - буквы латинского алфавита (a-Z)
          </Text>
          <Text size={12} color={charsError ? COLORS.main_red : COLORS.text_grey}>
            {' '}
            - строчные (a-z) и заглавные буквы (A-Z)
          </Text>
          <Text size={12} color={numbersError ? COLORS.main_red : COLORS.text_grey}>
            {' '}
            - по крайней мере одну цифру (0-9)
          </Text>
        </PasswordBlock>
      </Wrapper>

      <Wrapper>
        <AppButton title="Далее" bold onClick={postStaticRegistation} disabled={isDisabled} />
      </Wrapper>
    </Container>
  );
};
