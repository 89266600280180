import React, { FC, useState, useEffect, SyntheticEvent } from 'react';
import { OPTIONS_TYPES, OptionType, QuestionType } from '../../types';
import { QuestionContainer } from '../QuestionContainer';
import { RadioButton } from '../Buttons';
import { OptionWrapper, OptionText, DescriptionBlock } from './styled';
import { DescriptionIcon } from '../Icons';

type QuestionBlockProps = {
  question: QuestionType;
  saveAnswer: (question: QuestionType, value: OptionType[] | string) => void;
};

export const QuestionBlock: FC<QuestionBlockProps> = ({ question, saveAnswer }) => {
  const [checked, setChecked] = useState<OptionType>();

  const onCheckAnswer = (e: SyntheticEvent<HTMLDivElement>) => {
    const id = (e.currentTarget as Element).id;
    const selected = question.options?.find((option) => option.id.toString() === id);
    setChecked(selected);

    if (selected) {
      saveAnswer(question, [selected]);
    }
  };

  useEffect(() => {
    setChecked(undefined);
  }, [question]);

  return (
    <QuestionContainer question={question}>
      {question.options?.map((option) => (
        <OptionWrapper key={option.id} id={option.id.toString()} onClick={onCheckAnswer}>
          <RadioButton
            variant={question.type as OPTIONS_TYPES}
            option={option}
            checked={checked?.id === option.id}
          />
          <OptionText htmlFor={option.score.toString()}>{option.answer}</OptionText>
        </OptionWrapper>
      ))}

      {question.description && (
        <DescriptionBlock>
          <DescriptionIcon />
          <span>{question.description}</span>
        </DescriptionBlock>
      )}
    </QuestionContainer>
  );
};
