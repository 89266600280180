import { styled } from 'styled-components';

import { COLORS } from '../../const';

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 450px;
  height: 100vh;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #000;
`;

export const FormContainer = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
`;

export const FormWrapper = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledButton = styled.button`
  width: 100%;
  margin-top: 16px;
  padding: 12px 0;
  background-color: ${COLORS.main_green};
  border-radius: 16px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;

  &:disabled {
    opacity: 0.5;
  }
`;

export const SocialAuthContainer = styled.div`
  margin: 32px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SocialIconsWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;

  & button + button {
    margin-left: 16px;
  }
`;

export const BackButton = styled.button`
  position: absolute;
  top: 12px;
  right: 16px;

  & img {
    transform: scale(-1, 1);
  }
`;

export const UploadButton = styled.button`
  width: 100%;
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${COLORS.main_grey};
  cursor: pointer;
`;

export const FileInput = styled.input`
  position: absolute;
  width: 0;
  height: 0;
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: center;
`;

export const Avavar = styled.img`
  width: 160px;
  height: 160px;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
`;

export const DeleteButton = styled.button`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 12px;
  right: calc(50% - 70px);
`;

export const PasswordBlock = styled.div`
  display: flex;
  flex-direction: column;
`;
