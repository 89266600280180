import React, { FC, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

import './video-js.css';

type Props = {
  id: string;
  options: any;
  onReady?: any;
};

export const VideoJS: FC<Props> = ({ id, options, onReady }) => {
  const videoRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<any>(null);

  React.useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement('video-js');

      videoElement.addEventListener(
        'click',
        function () {
          videoElement.focus();
        },
        false
      );

      videoElement.classList.add('vjs-big-play-centered');
      videoRef?.current?.appendChild(videoElement);

      playerRef.current = videojs(videoElement, options, () => {
        console.log('player is ready');
      });

      const player = playerRef.current;
      onReady && onReady(player);
    }
  }, [options, videoRef, onReady, playerRef]);

  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player style={{ width: '100%' }} id={id}>
      <div ref={videoRef} />
    </div>
  );
};
