import React, { FC } from 'react';
import { ResultTypeWithIndex } from '../../types';
import { TableHeader } from './TableHeader';
import { TableRow } from './TableRow';
import { TableWrapper } from './styled';

type TableProps = {
  tableData: ResultTypeWithIndex[];
  onOpenResult: (id: string) => void;
};

export const Table: FC<TableProps> = ({ tableData, onOpenResult }) => {
  const isMobile = window.screen.availWidth < 450;

  return (
    <TableWrapper>
      {!isMobile && <TableHeader />}
      {tableData.map((data) => (
        <TableRow key={data.id} data={data} index={data.resultIndex} onOpenResult={onOpenResult} />
      ))}
    </TableWrapper>
  );
};
