import React from 'react';
import { COLORS } from '../../../const';

export const SearchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 13.9497C4.26633 11.2161 4.26633 6.78392 7 4.05025C9.73367 1.31658 14.1658 1.31658 16.8995 4.05025C19.6332 6.78392 19.6332 11.2161 16.8995 13.9497C14.1658 16.6834 9.73367 16.6834 7 13.9497ZM4.91756 14.6181C2.08732 11.0844 2.31007 5.91176 5.58579 2.63604C9.10051 -0.87868 14.799 -0.87868 18.3137 2.63604C21.8284 6.15076 21.8284 11.8492 18.3137 15.364C15.038 18.6397 9.86547 18.8624 6.33178 16.0323L1.70711 20.6569C1.31658 21.0475 0.683417 21.0475 0.292893 20.6569C-0.0976312 20.2664 -0.0976308 19.6332 0.292893 19.2427L4.91756 14.6181Z"
      fill={`${COLORS.main_green}`}
    />
  </svg>
);
