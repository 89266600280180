export const LogoWhiteIcon = () => {
  return (
    <svg
      width="185"
      height="51"
      viewBox="0 0 185 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M178.246 0V17.9987C178.246 20.8548 176.572 23.317 174.183 24.4496C173.248 24.9174 172.189 25.1637 171.081 25.1637C169.973 25.1637 168.914 24.9174 167.979 24.4496C165.566 23.2924 163.916 20.8302 163.916 17.9987V0H157.711V17.9987C157.711 24.3019 162.094 29.6202 168.003 31.0237V37.4746C168.003 41.4388 164.778 44.6396 160.814 44.6396C156.849 44.6396 153.649 41.4142 153.649 37.4746V19.476H147.444V37.4746C147.444 44.8612 153.452 50.869 160.814 50.869C168.2 50.869 174.208 44.8612 174.208 37.4746V31.0237C180.093 29.6202 184.5 24.3265 184.5 17.9987V0H178.246Z"
        fill="url(#paint0_linear_1544_86530)"
      />
      <path
        d="M178.246 0V17.9987C178.246 20.8548 176.572 23.317 174.183 24.4496C173.248 24.9174 172.189 25.1637 171.081 25.1637C169.973 25.1637 168.914 24.9174 167.979 24.4496C165.566 23.2924 163.916 20.8302 163.916 17.9987V0H157.711V17.9987C157.711 24.3019 162.094 29.6202 168.003 31.0237V37.4746C168.003 41.4388 164.778 44.6396 160.814 44.6396C156.849 44.6396 153.649 41.4142 153.649 37.4746V19.476H147.444V37.4746C147.444 44.8612 153.452 50.869 160.814 50.869C168.2 50.869 174.208 44.8612 174.208 37.4746V31.0237C180.093 29.6202 184.5 24.3265 184.5 17.9987V0H178.246Z"
        fill="url(#paint1_linear_1544_86530)"
      />
      <path
        d="M25.9591 7.18969C24.7773 4.82598 22.9799 3.0532 20.6408 1.84672C19.3112 1.13268 17.9324 0.664865 16.4797 0.418645C15.027 0.172425 13.5496 0.0493164 12.0477 0.0493164H0.5V31.3438H12.3432C17.3907 31.3438 21.2071 29.9896 23.817 27.3305C26.4269 24.6467 27.7319 20.781 27.7319 15.7581C27.7319 12.4095 27.141 9.5534 25.9591 7.18969ZM20.2468 20.978C19.6559 22.5046 18.7449 23.6618 17.5384 24.4989C17.2429 24.6713 16.8982 24.8436 16.4797 24.9914C16.0611 25.1391 15.6179 25.2622 15.1501 25.3607C14.6823 25.4592 14.2144 25.5577 13.7466 25.6069C13.2788 25.6562 12.8356 25.7054 12.417 25.7054H6.92633V5.76161H12.0477C13.2788 5.76161 14.5345 5.93396 15.8149 6.30329C17.0952 6.648 18.1539 7.3128 18.9665 8.29768C19.7051 9.18407 20.2468 10.2674 20.5915 11.5232C20.9362 12.7789 21.1086 14.2069 21.1086 15.7581C21.1578 17.7033 20.8377 19.4514 20.2468 20.978Z"
        fill="white"
      />
      <path
        d="M53.6587 14.601C53.117 13.1236 52.3291 11.8433 51.3196 10.7846C50.3101 9.72582 49.0544 8.91329 47.6017 8.37161C46.149 7.82992 44.4993 7.55908 42.6773 7.55908C40.8306 7.55908 39.1564 7.82992 37.7037 8.37161C36.251 8.91329 35.0199 9.72582 34.035 10.7846C33.0501 11.8433 32.2868 13.1236 31.7451 14.601C31.2035 16.1029 30.9326 17.7526 30.9326 19.5746C30.9326 23.391 31.9421 26.3456 33.9857 28.4878C36.0294 30.6299 38.9101 31.6886 42.7019 31.6886C46.4691 31.6886 49.3745 30.6299 51.4181 28.5124C53.4617 26.3949 54.4958 23.4156 54.4958 19.5746C54.4712 17.7526 54.2004 16.1029 53.6587 14.601ZM46.8877 24.302C46.0013 25.5331 44.5978 26.1487 42.6773 26.1487C40.7568 26.1487 39.3533 25.5331 38.4423 24.302C37.5313 23.0709 37.0635 21.4951 37.0635 19.6238C37.0635 17.7772 37.5313 16.226 38.4423 14.9949C39.3533 13.7638 40.7814 13.1483 42.6773 13.1483C44.5732 13.1483 46.0013 13.7638 46.8877 14.9949C47.774 16.226 48.2172 17.7772 48.2172 19.6238C48.1926 21.5197 47.7494 23.0709 46.8877 24.302Z"
        fill="white"
      />
      <path
        d="M71.953 25.3115C71.2636 25.8778 70.328 26.1486 69.1461 26.1486C68.1613 26.1486 67.3487 26.0009 66.6593 25.6808C65.9699 25.3607 65.4036 24.9175 65.0096 24.3512C64.5911 23.7849 64.2956 23.0955 64.074 22.3076C63.877 21.5197 63.7539 20.6579 63.7539 19.7223C63.7539 18.8359 63.8524 17.9987 64.0248 17.2108C64.1971 16.4229 64.4926 15.7089 64.9111 15.0933C65.3297 14.4778 65.8714 13.9853 66.5854 13.6406C67.2995 13.2959 68.1366 13.099 69.0969 13.099C70.2541 13.099 71.1897 13.3944 71.9038 13.9361C72.6178 14.5024 73.061 15.118 73.2087 15.832H79.7582C79.5612 14.3793 79.1426 13.1482 78.5517 12.0895C77.9362 11.0307 77.1729 10.1936 76.2619 9.50415C75.3262 8.81473 74.2675 8.32229 73.0856 8.00221C71.9038 7.68212 70.6234 7.50977 69.2692 7.50977C67.4226 7.50977 65.7729 7.80523 64.3202 8.37154C62.8675 8.93784 61.661 9.77499 60.6762 10.8337C59.6913 11.8925 58.928 13.1728 58.4109 14.6748C57.8693 16.1767 57.623 17.8264 57.623 19.6238C57.623 21.4704 57.8693 23.1447 58.3617 24.6467C58.8541 26.124 59.5682 27.4043 60.5038 28.4384C61.4394 29.4972 62.6213 30.2851 64.074 30.8268C65.5021 31.3685 67.1764 31.6393 69.1461 31.6393C70.4511 31.6393 71.6822 31.4916 72.9133 31.1961C74.1198 30.9006 75.2031 30.4082 76.1634 29.7434C77.1236 29.054 77.9115 28.2168 78.5763 27.1827C79.2165 26.1486 79.6597 24.8929 79.8567 23.3909H73.3072C73.061 24.1296 72.6671 24.7698 71.953 25.3115Z"
        fill="white"
      />
      <path
        d="M98.1505 25.1886C97.7073 25.4594 97.1903 25.681 96.5994 25.8534C96.0084 26.0257 95.4667 26.1242 94.9989 26.1242C94.2849 26.1242 93.7186 26.0011 93.2508 25.7549C92.7829 25.5087 92.4382 25.164 92.192 24.77C91.9458 24.3514 91.7735 23.9082 91.6996 23.4158C91.6257 22.9234 91.6011 22.4063 91.6011 21.8892V13.4931H98.446V8.05169H91.6011V1.69922H85.3717V8.05169H81.8262V13.4931H85.3717V23.3419C85.3717 24.6469 85.5195 25.8041 85.8396 26.8382C86.1596 27.8724 86.6275 28.7095 87.2676 29.3497C87.9817 30.113 88.8681 30.7039 89.9514 31.0732C91.0348 31.4426 92.2659 31.6395 93.694 31.6395C94.1372 31.6395 94.6296 31.6149 95.1467 31.541C95.6637 31.4918 96.23 31.3687 96.8456 31.221C97.4365 31.0486 98.0028 30.8763 98.5199 30.6547C99.0369 30.4331 99.5048 30.1868 99.9233 29.916L98.1505 25.1886Z"
        fill="white"
      />
      <path
        d="M124.349 14.601C123.807 13.1236 123.019 11.8433 122.01 10.7846C121 9.72582 119.744 8.91329 118.292 8.37161C116.839 7.82992 115.189 7.55908 113.367 7.55908C111.521 7.55908 109.846 7.82992 108.394 8.37161C106.941 8.91329 105.71 9.72582 104.725 10.7846C103.74 11.8433 102.977 13.1236 102.435 14.601C101.893 16.1029 101.623 17.7526 101.623 19.5746C101.623 23.391 102.632 26.3456 104.676 28.4878C106.719 30.6299 109.6 31.6886 113.392 31.6886C117.159 31.6886 120.064 30.6299 122.108 28.5124C124.152 26.3949 125.186 23.4156 125.186 19.5746C125.161 17.7526 124.89 16.1029 124.349 14.601ZM117.553 24.302C116.667 25.5331 115.263 26.1487 113.343 26.1487C111.422 26.1487 110.019 25.5331 109.108 24.302C108.197 23.0709 107.729 21.4951 107.729 19.6238C107.729 17.7772 108.197 16.226 109.108 14.9949C110.019 13.7638 111.447 13.1483 113.343 13.1483C115.239 13.1483 116.667 13.7638 117.553 14.9949C118.439 16.226 118.883 17.7772 118.883 19.6238C118.883 21.5197 118.439 23.0709 117.553 24.302Z"
        fill="white"
      />
      <path
        d="M143.283 7.63274C142.938 7.60812 142.569 7.5835 142.175 7.5835C140.673 7.5835 139.393 7.85434 138.309 8.42064C137.226 8.98695 136.216 9.8241 135.281 10.9321L134.936 8.00207H129.199V31.2945H135.429V20.8301C135.429 19.6975 135.502 18.7126 135.675 17.8262C135.823 16.9398 136.266 16.1273 136.931 15.3394C137.374 14.8716 137.891 14.5023 138.457 14.2561C139.023 14.0098 139.639 13.8129 140.254 13.6897C140.87 13.5666 141.535 13.5174 142.224 13.4928C142.914 13.4681 143.603 13.4681 144.268 13.4681V7.73123C143.948 7.7066 143.628 7.68199 143.283 7.63274Z"
        fill="white"
      />
      <path d="M147.395 0.0493164V31.3438H153.624V0.0493164H147.395Z" fill="white" />
      <defs>
        <linearGradient
          id="paint0_linear_1544_86530"
          x1="165.929"
          y1="50.855"
          x2="165.929"
          y2="-0.00713946"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#76AC98" />
          <stop offset="1" stop-color="#99CDA2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1544_86530"
          x1="165.929"
          y1="50.855"
          x2="165.929"
          y2="-0.00713946"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#76AC98" />
          <stop offset="1" stop-color="#99CDA2" />
        </linearGradient>
      </defs>
    </svg>
  );
};
