import React, { ChangeEvent, useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Text, ProgressBar, AppButton } from '../../components';
import { API, COLORS } from '../../const';
import {
  Wrapper,
  Container,
  FormWrapper,
  UploadButton,
  FileInput,
  ImageWrapper,
  DeleteButton,
  Avavar,
  BackButton,
} from './styled';
import { authProvider } from '../../utils';

import arrowPath from '../../assets/icons/arrow.svg';
import deletePath from '../../assets/icons/delete.svg';
import closePath from '../../assets/icons/close.svg';

export const AvatarForm = () => {
  const [uploadedFile, setUploadedFile] = useState<File>();

  const url = useMemo(() => {
    if (uploadedFile) {
      return URL.createObjectURL(uploadedFile);
    } else {
      return '';
    }
  }, [uploadedFile]);

  const navigate = useNavigate();
  const { token } = authProvider();

  const onFinish = useCallback(() => {
    if (navigator.userAgent.includes('iPhone')) {
      window.location.replace(`doctorly://auth/result?token=${token}`);
      setTimeout(() => {
        navigate(`/profile`);
      }, 1000);
    } else {
      navigate('/profile');
    }
  }, [navigate, token]);

  const uploadAvatar = useCallback(async () => {
    const formData = new FormData();
    if (uploadedFile) {
      formData.append('file', uploadedFile, uploadedFile.name);
    }
    try {
      const response = await fetch(`${API}/profile/avatar`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      if (response.status === 200) {
        onFinish();
      }
    } catch (e) {
      console.log(e);
    }
  }, [uploadedFile, token, onFinish]);

  const onChangeFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setUploadedFile(e.target.files[0]);
    }
  };

  const onUpload = () => {
    const input = document.getElementById('upload_avatar');
    if (input) {
      input.click();
    }
  };

  const deleteFile = () => {
    setUploadedFile(undefined);
  };

  const onCreate = useCallback(async () => {
    if (uploadedFile) {
      await uploadAvatar();
    } else {
      onFinish();
    }
  }, [uploadedFile, uploadAvatar, onFinish]);

  return (
    <Container>
      <Wrapper>
        <Text pb={16} size={16} weight={600} textAlign="center">
          Добавить аватар профиля
        </Text>
        <BackButton onClick={onFinish}>
          <img src={closePath} alt="arrow" />
        </BackButton>

        <ProgressBar count={2} active={2} />

        <FormWrapper>
          {!url && (
            <>
              <UploadButton onClick={onUpload}>
                <Text size={14} color={COLORS.text_grey}>
                  Добавить фотографию
                </Text>
                <img src={arrowPath} alt="arrow" />
              </UploadButton>
              <FileInput
                id="upload_avatar"
                type="file"
                multiple={false}
                accept="image/*"
                onChange={onChangeFile}
              />
            </>
          )}

          <Text size={12} color={COLORS.text_grey}>
            Ваша фотография будет использована в качестве аватара на Вашей странице профиля.
            {!url && 'Вы можете добавить фотографию позже, на странице профиля.'}
          </Text>

          {url && (
            <ImageWrapper>
              <DeleteButton onClick={deleteFile}>
                <img src={deletePath} alt="delete" />
              </DeleteButton>
              <Avavar src={url} alt="upload" />
            </ImageWrapper>
          )}
        </FormWrapper>
      </Wrapper>

      <Wrapper>
        <AppButton title="Создать" bold onClick={onCreate} />
      </Wrapper>
    </Container>
  );
};
