import React, {
  ChangeEvent,
  useState,
  useEffect,
  SyntheticEvent,
  KeyboardEvent,
  useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';
import Resizer from 'react-image-file-resizer';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { API, COLORS } from '../../../const';
import { NewPostType, PostType } from '../../../types';
import { authProvider, transcodeVideo } from '../../../utils';
import {
  AddButton,
  AppButton,
  AppContainer,
  CloseIcon,
  Loader,
  LogoIcon,
  Text,
} from '../../../components';
import {
  Input,
  Wrapper,
  TagContainer,
  Tag,
  FileInput,
  PreviewContainer,
  Preview,
  DeleteButton,
  Container,
  LogoContainer,
  TitleWrapper,
  CloseIconWrapper,
  VideoContainer,
  TagInputBlock,
  TagInput,
  TagSearch,
} from './styled';

import deletePath from '../../../assets/icons/delete.svg';
import deleteTagPath from '../../../assets/icons/remove.svg';

export const NewPostPage = () => {
  const [allTags, setAllTags] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const [titleValue, setTitleValue] = useState('');
  const [contentValue, setContentValue] = useState('');
  const [tagInputValue, setTagInputValue] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState<(File | null)[]>([]);

  const [imageResolution, setImageResolution] = useState<number[]>([1, 1]);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { token } = authProvider();

  const showUpload = useMemo(() => {
    return !uploadedFiles.some((f) => f?.type.includes('video'));
  }, [uploadedFiles]);

  const count = useMemo(() => {
    return Math.abs(5 - uploadedFiles.length);
  }, [uploadedFiles]);

  const info = useMemo(() => {
    if (count === 0) {
      return 'Максимум изображений';
    }

    if (count === 5) {
      return 'Вы может добавить 5 изображений или 1 видео к своей публикации';
    }

    const images = `изображени${count === 1 ? 'e' : count === 5 ? 'й' : 'я'}`;

    return `Вы можете добавить ${count} ${images}`;
  }, [count]);

  const aspect = useMemo(() => {
    return imageResolution[0] / imageResolution[1];
  }, [imageResolution]);

  const videoSrc = useMemo(() => {
    if (uploadedFiles[0]) return URL.createObjectURL(uploadedFiles[0] as File);
  }, [uploadedFiles]);

  const tagCount = useMemo(() => {
    return Math.abs(3 - selectedTags.length);
  }, [selectedTags]);

  const tagList = useMemo(() => {
    if (tagInputValue)
      return (
        allTags.filter((t) => t.includes(tagInputValue)).concat(tagInputValue) || [tagInputValue]
      );

    return [];
  }, [allTags, tagInputValue]);

  const isDisabled = useMemo(() => {
    return !titleValue || !contentValue || selectedTags.length === 0;
  }, [titleValue, contentValue, selectedTags]);

  const getResolution = (imageFile: File) => {
    const reader = new FileReader();

    reader.readAsDataURL(imageFile);
    reader.onload = function (e) {
      const image = new Image();
      image.src = e.target?.result as string;

      image.onload = function () {
        const height = image.naturalHeight;
        const width = image.naturalWidth;

        setImageResolution([width, height]);
      };
    };
  };

  const onClose = () => {
    navigate('/blog');
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setTitleValue(e.target.value);
  };

  const onChangeTextarea = (event: any, editor: any) => {
    const data = editor.getData();
    setContentValue(data);
  };

  const onChangeTagInput = (e: ChangeEvent<HTMLInputElement>) => {
    setTagInputValue(e.target.value);
  };

  const onKeyupTagInput = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Space' || e.code === 'Enter') {
      setSelectedTags((prev) => [...prev, tagInputValue.trim().replace('#', '')]);
      setTagInputValue('');
    }
  };

  const onTagClick = (e: SyntheticEvent<HTMLDivElement>) => {
    const id = (e.target as Element).id;
    setSelectedTags((prev) => [...prev, id]);
    setTagInputValue('');
  };

  const onDeleteTag = (e: SyntheticEvent<HTMLDivElement>) => {
    const id = (e.target as Element).id;
    setSelectedTags((prev) => {
      const newTags = prev.concat();
      const index = newTags.indexOf(id);
      newTags.splice(index, 1);
      return newTags;
    });
  };

  const onUpload = () => {
    const input = document.getElementById('upload_post_img');
    if (input) {
      input.click();
    }
  };

  const resizeFile = (file: File): Promise<File> =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1900,
        1600,
        'JPEG',
        90,
        0,
        (uri) => {
          resolve(uri as File);
        },
        'file'
      );
    });

  const onChangeFile = async (e: ChangeEvent<HTMLInputElement>) => {
    setUploading(true);
    if (e.target.files?.length) {
      let file = e.target.files.item(0) as File;
      if (e.target.files.item(0)?.type.includes('image')) {
        file = await resizeFile(file);
      } else {
        const buffer = await file.arrayBuffer();
        const fileData = new Uint8Array(buffer);
        file = await transcodeVideo(fileData, file.name);
      }

      setUploadedFiles((prev) => [...prev, file]);
      setUploading(false);
    }
  };

  const onDeleteFile = (e: SyntheticEvent<HTMLButtonElement>) => {
    const name = e.currentTarget.id;
    setUploadedFiles((prev) => prev.filter((item) => item?.name !== name));
  };

  const getTags = async () => {
    try {
      const response = await fetch(`${API}/tags`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const data: string[] = await response.json();
        setAllTags(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const postNewPostFiles = async (id: string) => {
    setLoading(true);
    try {
      const formData = new FormData();
      if (uploadedFiles.length === 1 && uploadedFiles[0]?.type.includes('video')) {
        formData.append('file', uploadedFiles[0]);
        await fetch(`${API}/posts/${id}/file?filename=${uploadedFiles[0].name}`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
      } else {
        if (uploadedFiles) {
          uploadedFiles.forEach((file) => {
            if (file) {
              formData.append('files', file);
            }
          });
        }
        await fetch(`${API}/posts/${id}/files`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const publishPost = async (id: string) => {
    try {
      await fetch(`${API}/posts/${id}/publish`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const postNewPost = async () => {
    try {
      const data: NewPostType = {
        title: titleValue,
        content: contentValue,
        tags: selectedTags,
      };
      const response = await fetch(`${API}/posts`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (response.status === 200 && uploadedFiles.length) {
        const post: PostType = await response.json();
        await postNewPostFiles(post.id);
        await publishPost(post.id);
        onClose();
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (uploadedFiles.length === 1 && uploadedFiles[0]?.type.includes('image')) {
      getResolution(uploadedFiles[0]);
    }
  }, [uploadedFiles]);

  return (
    <AppContainer title="">
      <LogoContainer>
        <LogoIcon />
      </LogoContainer>

      <Container>
        {loading && (
          <div
            style={{
              position: 'absolute',
              inset: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 20,
            }}
          >
            <Loader />
          </div>
        )}
        <TitleWrapper>
          <Text size={18} lineHeight={22} weight={600} textAlign="center">
            Новая публикация
          </Text>
          <CloseIconWrapper onClick={onClose}>
            <CloseIcon />
          </CloseIconWrapper>
        </TitleWrapper>

        <div style={{ overflow: 'scroll' }}>
          <Wrapper>
            {!!uploadedFiles.length && uploadedFiles[0]?.type.includes('video') && (
              <VideoContainer>
                <div style={{ position: 'relative' }}>
                  <video
                    style={{ width: '168px', maxHeight: '335px', borderRadius: '12px' }}
                    src={videoSrc}
                  />
                  <DeleteButton id={uploadedFiles[0].name} onClick={onDeleteFile}>
                    <img src={deletePath} alt="delete" />
                  </DeleteButton>
                </div>
              </VideoContainer>
            )}

            {!!uploadedFiles.length && uploadedFiles[0]?.type.includes('image') && (
              <PreviewContainer>
                {uploadedFiles.map((file) => {
                  return (
                    <Preview key={file?.name} width={aspect * 190}>
                      <img src={URL.createObjectURL(file as File)} alt="uploaded file" />

                      <DeleteButton id={file?.name} onClick={onDeleteFile}>
                        <img src={deletePath} alt="delete" />
                      </DeleteButton>
                    </Preview>
                  );
                })}
              </PreviewContainer>
            )}

            {uploadedFiles.length > 0 && showUpload && (
              <Text size={12} color={COLORS.text_grey}>
                Пропорции всех изображений в посте будут подстраиваться под пропорции первого
                добавленного изображения. Если Вы хотите использовать видео в публикации, то сначала
                удалите все добавленные изображения
              </Text>
            )}

            {!showUpload && (
              <Text size={12} color={COLORS.text_grey}>
                Если Вы хотите заменить видео или добавить изображение вместо видео, то удалите
                сначала добавленное видео
              </Text>
            )}

            {uploading && (
              <div
                style={{
                  padding: '20px',
                  display: 'flex',
                  flexFlow: 'column',
                  alignItems: 'center',
                }}
              >
                <Loader />
              </div>
            )}

            {showUpload && !uploading && (
              <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                <AddButton onClick={onUpload} disabled={count === 0} />
                <FileInput
                  id="upload_post_img"
                  type="file"
                  multiple={false}
                  accept="image/*,video/*"
                  onChange={onChangeFile}
                />

                <Text size={12} color={COLORS.text_grey}>
                  {info}
                </Text>
              </div>
            )}

            <Input
              name="title"
              value={titleValue}
              onChange={onChangeInput}
              placeholder="Заголовок"
            />

            <CKEditor
              editor={ClassicEditor}
              data={contentValue}
              onChange={onChangeTextarea}
              config={{ toolbar: [], placeholder: 'Введите текст' }}
            />

            <TagInputBlock>
              <Text size={12} color={COLORS.text_grey}>
                Доступно {tagCount}
              </Text>
              <TagInput
                name="tag"
                value={tagInputValue}
                onChange={onChangeTagInput}
                onKeyUp={onKeyupTagInput}
                placeholder={!tagCount ? 'Максимум #Тэгов' : 'Добавить #Тэг'}
                disabled={!tagCount}
              />

              {tagList.length > 0 && (
                <TagSearch>
                  {tagList.map((tag, index) => (
                    <Tag key={index} id={tag} onClick={onTagClick}>
                      #{tag}
                    </Tag>
                  ))}
                </TagSearch>
              )}
            </TagInputBlock>

            {selectedTags.length > 0 && (
              <TagContainer>
                {selectedTags.map((tag, index) => (
                  <Tag key={index} id={tag} onClick={onDeleteTag}>
                    #{tag}
                    <button>
                      <img src={deleteTagPath} alt="delete" />
                    </button>
                  </Tag>
                ))}
              </TagContainer>
            )}

            <TitleWrapper>
              <AppButton
                title="Опубликовать"
                onClick={postNewPost}
                disabled={isDisabled || loading}
              />
            </TitleWrapper>
          </Wrapper>
        </div>
      </Container>
    </AppContainer>
  );
};
