import { styled } from 'styled-components';
import { COLORS } from '../../../const';

export const CardBlock = styled.div`
  position: relative;
  margin-bottom: 18px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: ${COLORS.main_white};
`;

export const CardBlock2 = styled(CardBlock)`
  margin-bottom: 0;
  border-radius: 12px 12px 0 0;
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const CardIcon = styled.img`
  position: absolute;
  right: 16px;
  bottom: 20px;
`;

export const AppointmentCard = styled.div`
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-bottom: 1px solid #edeff5;

  & div {
    display: flex;
    flex-direction: column;
  }
`;
