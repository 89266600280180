import React, { FC, PropsWithChildren } from 'react';
import { styled } from 'styled-components';
import { QuestionType } from '../../types';

type QuestionBlockProps = {
  question: QuestionType;
};

const Wrapper = styled.div`
  width: 654px;
  margin-bottom: 24px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  background-color: #edeef0;
  border-radius: 24px;

  @media screen and (max-width: 450px) {
    max-width: 100%;
    width: 100%;
    margin-bottom: 12px;
    padding: 16px;
  }
`;

const QuestionText = styled.span`
  margin-bottom: 16px;
  font-size: 28px;
  font-weight: 700;

  @media screen and (max-width: 450px) {
    margin-bottom: 6px;
    font-size: 16px;
    font-weight: 600;
  }
`;

export const QuestionContainer: FC<PropsWithChildren<QuestionBlockProps>> = ({
  question,
  children,
}) => {
  return (
    <Wrapper>
      <QuestionText>{question?.title}</QuestionText>
      {children}
    </Wrapper>
  );
};
