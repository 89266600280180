import { styled } from 'styled-components';
import { COLORS } from '../../const';

export const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  background-color: ${COLORS.main_white};
  border-radius: 16px;

  & div {
    height: 57px;
    width: 33%;
    flex-basis: 33%;
    padding: 0 16px;
    border-left: 1px solid ${COLORS.main_grey};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    & img {
      width: 30px;
      height: 30px;
    }
  }
`;
