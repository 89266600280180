import React, { ChangeEvent, FC } from 'react';
import { RegimentsType, PILL_UNIT, DAYTIME } from '../../../../types';
import { UNIT_OPTIONS, DAYTIME_OPTIONS } from '../../../../const';
import { SelectOptions, AppButton, Text } from '../../../../components';
import { getPillIcon } from '../../../../utils';
import {
  Wrapper,
  Input,
  Label,
  ButtonWrapper,
  UnitsWrapper,
  UnitIcon,
  UnitBlock,
  GapBlock,
} from './styled';

export type StepProps = {
  values: RegimentsType;
  onChange: (name: string, value: string | string[]) => void;
  onNextStep: () => void;
  onPrevStep?: () => void;
};

export const FirstStep: FC<StepProps> = ({ values, onChange, onNextStep }) => {
  const unitOptions = Object.keys(UNIT_OPTIONS).map((key) => ({
    value: key,
    title: UNIT_OPTIONS[key as PILL_UNIT],
  }));

  const daytimeOptions = Object.keys(DAYTIME_OPTIONS).map((key) => ({
    value: key,
    title: DAYTIME_OPTIONS[key as DAYTIME],
  }));

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    onChange(name, value);
  };

  return (
    <Wrapper>
      <UnitsWrapper>
        {Object.keys(UNIT_OPTIONS).map((key) => (
          <UnitBlock key={key}>
            <UnitIcon active={values.unit === key}>{getPillIcon(key as PILL_UNIT)}</UnitIcon>
            <Text size={10}>{UNIT_OPTIONS[key as PILL_UNIT]}</Text>
          </UnitBlock>
        ))}
      </UnitsWrapper>

      <Input
        name="drug_title"
        value={values.drug_title}
        onChange={onInputChange}
        placeholder="Название"
      />

      <div>
        <Label>Разовая доза</Label>
        <Input
          name="mera"
          value={values.mera || ''}
          onChange={onInputChange}
          placeholder="Количество от 0.5"
        />
      </div>

      <GapBlock>
        <SelectOptions
          type="text"
          name="unit"
          title="Вид"
          options={unitOptions}
          onChange={onChange}
        />

        <SelectOptions
          type="text"
          name="daytime"
          title="Прием"
          options={daytimeOptions}
          onChange={onChange}
        />

        <Input
          name="comment"
          value={values.comment}
          onChange={onInputChange}
          placeholder="Комментарий"
        />
      </GapBlock>

      <ButtonWrapper>
        <AppButton
          title="Далее"
          fullWidth
          onClick={onNextStep}
          disabled={!values.drug_title && !values.mera}
        />
      </ButtonWrapper>
    </Wrapper>
  );
};
