import React, { FC, useState, useEffect, SyntheticEvent } from 'react';
import { styled } from 'styled-components';
import { OPTIONS_TYPES, OptionType, QuestionType } from '../../types';
import { QuestionContainer } from '../QuestionContainer';
import { RadioButton } from '../Buttons';

export const OptionWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 450px) {
    margin-top: 12px;
  }
`;

export const OptionText = styled.label`
  width: 90%;
  margin-left: 12px;
  font-size: 24px;
  font-weight: 400;

  @media screen and (max-width: 450px) {
    font-size: 14px;
  }
`;

type QuestionBlockProps = {
  question: QuestionType;
  saveAnswer: (question: QuestionType, value: OptionType[] | string) => void;
};

type CheckedType = {
  [id: string]: {
    selectedOption: OptionType;
    checked: boolean;
  };
};

export const MultiSelectBlock: FC<QuestionBlockProps> = ({ question, saveAnswer }) => {
  const [checked, setChecked] = useState<CheckedType>({});

  const onCheckAnswer = (e: SyntheticEvent<HTMLDivElement>) => {
    const id = (e.currentTarget as Element).id;
    const selectedOption = question.options?.find((option) => option.id.toString() === id);

    if (selectedOption) {
      if (
        selectedOption.score === 0 ||
        Object.values(checked).some((check) => check.selectedOption.score === 0)
      ) {
        setChecked({ [id]: { selectedOption: selectedOption, checked: true } });
      } else {
        setChecked((prev) => ({
          ...prev,
          [id]: { selectedOption: selectedOption, checked: true },
        }));
      }
    }
  };

  useEffect(() => {
    const values = Object.values(checked).map((check) => check.selectedOption);
    if (values.length) {
      saveAnswer(question, values);
    }
  }, [checked, saveAnswer, question]);

  return (
    <QuestionContainer question={question}>
      {question.options?.map((option) => (
        <OptionWrapper key={option.id} id={option.id.toString()} onClick={onCheckAnswer}>
          <RadioButton
            variant={question.type as OPTIONS_TYPES}
            option={option}
            checked={checked[option.id]?.checked}
          />
          <OptionText htmlFor={option.score.toString()}>{option.answer}</OptionText>
        </OptionWrapper>
      ))}
    </QuestionContainer>
  );
};
