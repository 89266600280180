import React, { FC, useState } from 'react';
import { AUTH_FIELDS, InputValuesType } from '../../types';
import { AuthInput } from '../../components';
import { FormWrapper } from './styled';

export const EMAIL_REXEXP = /\S+@\S+\.\S+/;

export type AuthProps = {
  values: InputValuesType;
  onChange: (name: string, value: string) => void;
  error?: boolean | string;
};

export const Auth: FC<AuthProps> = ({ error, values, onChange }) => {
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);

  const onValidateEmail = () => {
    if (values[AUTH_FIELDS.USERNAME] && !EMAIL_REXEXP.test(values[AUTH_FIELDS.USERNAME])) {
      setIsEmailValid(false);
    } else {
      setIsEmailValid(true);
    }
  };

  const onChangeInput = (name: string, value: string) => {
    setIsEmailValid(true);
    onChange(name, value);
  };

  return (
    <FormWrapper>
      <AuthInput
        name={AUTH_FIELDS.USERNAME}
        value={values[AUTH_FIELDS.USERNAME]}
        label="Электронная почта"
        onChange={onChangeInput}
        onBlur={onValidateEmail}
        error={!isEmailValid}
        errorMessage="Неверный формат"
      />

      <AuthInput
        type="password"
        name={AUTH_FIELDS.PASSWORD}
        value={values[AUTH_FIELDS.PASSWORD]}
        label="Пароль:"
        onChange={onChange}
        error={!!error}
        errorMessage={error as string}
      />
    </FormWrapper>
  );
};
