import React, { FC, SyntheticEvent, PropsWithChildren } from 'react';
import { TabsType } from '../../types';
import { ButtonsContainer, Button, Container } from './styled';

type TabsProps = {
  onToggle: (id: string) => void;
  tabs: TabsType;
  active: string;
};

export const Tabs: FC<PropsWithChildren<TabsProps>> = ({ onToggle, tabs, active, children }) => {
  const onClick = (e: SyntheticEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const id = (e.currentTarget as Element).id;
    onToggle(id);
  };

  return (
    <>
      <ButtonsContainer onClick={onClick}>
        {Object.keys(tabs).map((tab) => (
          <Button key={tab} id={tab} isActive={active === tab} onClick={onClick}>
            {tabs[tab]}
          </Button>
        ))}
      </ButtonsContainer>
      <Container>{children}</Container>
    </>
  );
};
