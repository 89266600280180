import React, { ChangeEvent, FC, useState } from 'react';
import { AnswerType, SOURCES, SURVEYS_TYPES } from '../../../types';
import { API } from '../../../const';
import { Button, PageContainer, PdfDocument, Text } from '../../../components';
import { Wrapper, InputBlock, Input, PdfButton } from './styled';

import thankImgPath from '../../../assets/thankyou.png';
import thank2ImgPath from '../../../assets/thankyou2.png';
import { PDFDownloadLink } from '@react-pdf/renderer';

type EmailPageProps = {
  source: SOURCES | null;
  answers: AnswerType[];
  surveyType: SURVEYS_TYPES;
};

export const EmailPage: FC<EmailPageProps> = ({ source, answers, surveyType }) => {
  const [emailValue, setEmailValue] = useState<string>('');
  const [isSent, setIsSent] = useState(false);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmailValue(value);
  };

  const onSendEmail = async () => {
    try {
      await fetch(`${API}/feedback?email=${emailValue}&promocode=true`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setEmailValue('');
      setIsSent(true);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <PageContainer>
      <Wrapper>
        <img src={source === SOURCES.FORCED ? thank2ImgPath : thankImgPath} alt="Спасибо" />

        <Text pt={30} pb={12} size={28} weight={700} textAlign="center" lineHeight={34}>
          Спасибо
          <br />
          за прохождение опроса!
        </Text>

        {source !== SOURCES.FORCED && (
          <>
            <span>
              Укажите вашу электронную почту,
              <br />и мы пришлем Ваш промокод
              <br />
              на получение скидки
              <br />в будущем приложении.
            </span>

            <InputBlock>
              {!isSent && (
                <>
                  <Input placeholder="Email" onChange={onChange} />
                  <Button id="email" size="small" onClick={onSendEmail}>
                    Отправить
                  </Button>
                </>
              )}
              {isSent && <span>Email отправлен!</span>}
            </InputBlock>
          </>
        )}

        {source === SOURCES.FORCED && (
          <>
            <span>
              Ваши ответы в дальнейшем помогут
              <br />
              давать быстрый и точный диагноз
              <br />
              нуждающимся людям.
            </span>
            <PdfButton>
              <PDFDownloadLink
                document={<PdfDocument data={answers} surveyType={surveyType} />}
                fileName="опрос.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? 'Документ загружается...' : 'Скачать результаты опроса'
                }
              </PDFDownloadLink>
            </PdfButton>
          </>
        )}
      </Wrapper>
    </PageContainer>
  );
};
