import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { AppButton, Text, Avatar } from '../../../components';
import { AUTH_FIELDS, SettingsType } from '../../../types';
import { ProfileProps } from './Profile';
import {
  ButtonWrapper,
  DataColumn,
  Input,
  ProfileContainer,
  ProfileWrapper,
  SettingsTable,
  TitleColumn,
  UploadButton,
  FileInput,
} from './styled';

import uploadPath from '../../../assets/icons/upload.svg';

type ValuesType = {
  [name: string]: string;
};

export const Settings: FC<ProfileProps> = ({ profile, onUploadAvatar, onSave, goBack }) => {
  const [values, setValues] = useState<ValuesType | SettingsType>();
  const [disabled, setDisabled] = useState<boolean>(true);

  const setInitialValues = useCallback(() => {
    if (profile) {
      setValues(profile);
    }
  }, [profile]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setDisabled(false);
  };

  const onChangeFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && onUploadAvatar) {
      await onUploadAvatar(e.target.files[0]);
    }
  };

  const onUpload = () => {
    const input = document.getElementById('upload_avatar');
    if (input) {
      input.click();
    }
  };

  const saveProfile = () => {
    if (disabled && goBack) {
      goBack();
    } else {
      const data: SettingsType = {
        first_name: values?.first_name || '',
        last_name: values?.last_name || '',
      };
      if (values?.patronymic) {
        data.patronymic = values?.patronymic;
      }
      if (values?.height) {
        data.height = Number(values?.height);
      }
      if (values?.weight) {
        data.weight = Number(values?.weight);
      }
      if (values?.age) {
        data.age = Number(values?.age);
      }
      if (onSave) {
        onSave(data);
      }
    }
  };

  useEffect(() => {
    setInitialValues();
  }, [setInitialValues]);

  return (
    <ProfileContainer>
      <div>
        <ProfileWrapper>
          <Avatar size="small" path={profile?.avatar || ''} />

          <UploadButton onClick={onUpload}>
            <img src={uploadPath} alt="upload" />
            Изменить фото
          </UploadButton>
          <FileInput
            id="upload_avatar"
            type="file"
            multiple={false}
            accept="image/*"
            onChange={onChangeFile}
          />
        </ProfileWrapper>

        <SettingsTable>
          <TitleColumn>
            <div>
              <Text size={14}>Фамилия</Text>
            </div>
            <div>
              <Text size={14}>Имя</Text>
            </div>
            <div>
              <Text size={14}>Отчество</Text>
            </div>
            <div>
              <Text size={14}>Рост</Text>
            </div>
            <div>
              <Text size={14}>Вес</Text>
            </div>
            <div>
              <Text size={14}>Возраст</Text>
            </div>
          </TitleColumn>

          <DataColumn>
            <Input name={AUTH_FIELDS.LAST_NAME} value={values?.last_name} onChange={onChange} />
            <Input name={AUTH_FIELDS.FIRST_NAME} value={values?.first_name} onChange={onChange} />
            <Input name={AUTH_FIELDS.PATRONYMIC} value={values?.patronymic} onChange={onChange} />
            <Input name="height" value={values?.height} onChange={onChange} placeholder="см" />
            <Input name="weight" value={values?.weight} onChange={onChange} placeholder="кг" />
            <Input name="age" value={values?.age} onChange={onChange} />
          </DataColumn>
        </SettingsTable>
      </div>

      <ButtonWrapper>
        <AppButton title="Готово" onClick={saveProfile} />
      </ButtonWrapper>
    </ProfileContainer>
  );
};
