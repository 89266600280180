import { styled } from 'styled-components';

export const Tab = styled.div.withConfig({
  shouldForwardProp: (p) => !['active'].includes(p),
})<{
  active?: boolean;
}>`
  margin-top: 18px;
  margin-left: 40px;
  color: ${(props) => (props.active ? '#35373F' : '#959AB4')};
  font-size: 20px;
  cursor: pointer;
`;

export const BackButton = styled.button`
  position: absolute;
  top: 70px;
  left: 8px;
`;

export const TabList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SurveyTab = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  border-radius: 12px;
  background-color: #edeef0;
`;
