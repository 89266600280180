import React, { FC, ChangeEvent } from 'react';
import { styled } from 'styled-components';
import { COLORS } from '../../const';
import { OptionType, QuestionType } from '../../types';
import { QuestionContainer } from '../QuestionContainer';
import { DescriptionIcon } from '../Icons';

const Textarea = styled.textarea`
  height: 58px;
  padding: 14px;
  border: 2px solid ${COLORS.main_green};
  border-radius: 12px;
  font-size: 20px;
  resize: none;

  &::placeholder {
    color: ${COLORS.main_grey1};
    font-size: 20px;
    font-family: 'Lato', sans-serif;
  }

  @media screen and (max-width: 450px) {
    border: 1px solid ${COLORS.main_green};
    font-size: 14px;

    &::placeholder {
      font-size: 14px;
    }
  }
`;

const DescriptionBlock = styled.div`
  margin-top: 16px;
  margin-left: 4px;
  display: flex;
  align-items: center;

  & span {
    width: 450px;
    margin-left: 12px;

    @media screen and (max-width: 450px) {
      font-size: 12px;
    }
  }
`;

type TextareaBlockProps = {
  question: QuestionType;
  saveAnswer: (question: QuestionType, value: OptionType[] | string) => void;
};

export const TextareaBlock: FC<TextareaBlockProps> = ({ question, saveAnswer }) => {
  const onInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    saveAnswer(question, e.target.value);
  };

  return (
    <QuestionContainer question={question}>
      <Textarea name={`${question.id}`} maxLength={50} placeholder={''} onChange={onInputChange} />
      {question.description && (
        <DescriptionBlock>
          <DescriptionIcon />
          <span>{question.description}</span>
        </DescriptionBlock>
      )}
    </QuestionContainer>
  );
};
