import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { COLORS } from '../../const';
import { LogoIcon, BlogIcon, ProfileIcon } from '../Icons';
import { Text } from '../Text';
import { Aside, Tab, TabsWrapper } from './styled';

export const DesktopPanel = () => {
  const location = useLocation();

  return (
    <Aside>
      <LogoIcon />

      <TabsWrapper>
        <Link to={'/profile'}>
          <Tab>
            <Text
              size={28}
              weight={700}
              color={
                location.pathname.split('/')[1] === 'profile'
                  ? `${COLORS.main_green}`
                  : `${COLORS.text_grey}`
              }
            >
              Профиль
            </Text>
            <ProfileIcon isActive={location.pathname.split('/')[1] === 'profile'} />
          </Tab>
        </Link>

        {/* <Link to={'/medcard'}>
          <Tab>
            <Text
              size={28}
              weight={700}
              color={
                location.pathname.split('/')[1] === 'medcard'
                  ? `${COLORS.main_green}`
                  : `${COLORS.text_grey}`
              }
            >
              Медкарта
            </Text>
            <CardIcon isActive={location.pathname.split('/')[1] === 'medcard'} />
          </Tab>
        </Link> */}

        <Link to={'/blog'}>
          <Tab>
            <Text
              size={28}
              weight={700}
              color={
                location.pathname.split('/')[1] === 'blog'
                  ? `${COLORS.main_green}`
                  : `${COLORS.text_grey}`
              }
            >
              Новости
            </Text>
            <BlogIcon isActive={location.pathname.split('/')[1] === 'blog'} />
          </Tab>
        </Link>
      </TabsWrapper>
    </Aside>
  );
};
