import React, { FC } from 'react';
import { Page, Text, View, Document, Font, Image } from '@react-pdf/renderer';
import { AnswerType, SURVEYS_TYPES, OPTIONS_TYPES } from '../../types';
import { TABS } from '../../const';
import { styles } from './styles';

import FontLatoRegular from '../../assets/fonts/LatoRegular.ttf';
import FontLatoSemibold from '../../assets/fonts/LatoSemibold.ttf';
import FontLatoBold from '../../assets/fonts/LatoBold.ttf';

import logoImgPath from '../../assets/icons/logo.png';
import whiteLogoImgPath from '../../assets/icons/whiteLogo.png';
import userImgPath from '../../assets/icons/user.png';
import emailImgPath from '../../assets/icons/email.png';

Font.register({
  family: 'LatoRegular',
  fonts: [
    {
      src: FontLatoRegular,
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    {
      src: FontLatoSemibold,
      fontStyle: 'normal',
      fontWeight: 'semibold',
    },
    {
      src: FontLatoBold,
      fontStyle: 'normal',
      fontWeight: 'bold',
    },
  ],
});

type PdfDocumentProps = {
  data: AnswerType[];
  surveyType: SURVEYS_TYPES;
};

export const PdfDocument: FC<PdfDocumentProps> = ({ data, surveyType }) => {
  const nameQuestion = data[0];

  const firstLineQuestions = data.slice(1, 6);

  const ageQuestion = firstLineQuestions.find(
    (answer) =>
      (surveyType === SURVEYS_TYPES.CARDIO && answer.question.id === 5) ||
      (SURVEYS_TYPES.THERAPY && answer.question.id === 33)
  );
  const sexQuestion = firstLineQuestions.find(
    (answer) => answer.question.type === OPTIONS_TYPES.SELECT
  );
  const inputQuestions = firstLineQuestions.slice(0, 3);

  const questions = data.slice(6);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image src={logoImgPath} style={styles.logo} />
          <View style={styles.iconBlock}>
            <Image src={userImgPath} style={styles.icon} />
            <Text style={styles.semiboldText}>{nameQuestion?.answer_body}</Text>
          </View>
        </View>

        <View>
          <Text style={styles.title}>{TABS[surveyType]} опрос</Text>
        </View>

        <View style={styles.rowWrapper}>
          <Text style={styles.rowNumber}>01</Text>

          <View style={styles.row}>
            <View style={[styles.rowSubrow, styles.rowSunrowFirst]}>
              <Text style={styles.semiboldText}>{sexQuestion?.question.title}: </Text>
              {sexQuestion?.answer_selected?.map((a) => (
                <Text key={a.id} style={styles.rowSubrowPadding}>
                  {a.answer}
                </Text>
              ))}

              <Text style={styles.semiboldText}>{ageQuestion?.question.title}: </Text>
              <Text>{ageQuestion?.answer_body}</Text>
            </View>

            <View style={styles.rowSubrow}>
              {inputQuestions.map((answer) => (
                <View key={answer.question.id} style={styles.rowSubrow}>
                  <Text style={[styles.semiboldText]}>{answer.question.title}: </Text>
                  <Text style={styles.rowSubrowPadding}>{answer?.answer_body}</Text>
                </View>
              ))}
            </View>
          </View>
        </View>

        {questions.map((answer, index) => (
          <View style={styles.rowWrapper} key={answer.question.id} wrap={false}>
            <Text style={styles.rowNumber}>{index + 2 < 10 ? `0${index + 2}` : index + 2}</Text>

            <View style={styles.row}>
              <Text style={[styles.semiboldText, styles.rowQuestion]}>{answer.question.title}</Text>
              {answer.answer_body && <Text>{answer.answer_body}</Text>}
              {answer.answer_selected &&
                answer.answer_selected.map((ans) => <Text key={ans.id}>{ans.answer}</Text>)}
            </View>
          </View>
        ))}

        <View style={styles.footer} fixed>
          <Image src={whiteLogoImgPath} style={styles.footerLogo} />
          <View style={styles.iconBlock}>
            <Image src={emailImgPath} style={styles.icon} />
            <Text style={[styles.semiboldText, styles.footerText]}>info@doctorly.ru</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
