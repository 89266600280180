import React from 'react';

export const LogoIcon = () => {
  return (
    <svg width="98" height="27" viewBox="0 0 98 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M94.6805 0V9.55324C94.6805 11.0692 93.7919 12.3761 92.5242 12.9773C92.0276 13.2256 91.4656 13.3562 90.8775 13.3562C90.2894 13.3562 89.7275 13.2256 89.2309 12.9773C87.9501 12.363 87.0745 11.0561 87.0745 9.55324V0H83.7812V9.55324C83.7812 12.8988 86.1074 15.7217 89.2439 16.4666V19.8906C89.2439 21.9947 87.5319 23.6936 85.4279 23.6936C83.3238 23.6936 81.6249 21.9816 81.6249 19.8906V10.3374H78.3315V19.8906C78.3315 23.8112 81.5203 27 85.4279 27C89.3485 27 92.5373 23.8112 92.5373 19.8906V16.4666C95.6607 15.7217 98 12.9119 98 9.55324V0H94.6805Z"
        fill="url(#paint0_linear_1363_77117)"
      />
      <path
        d="M94.6805 0V9.55324C94.6805 11.0692 93.7919 12.3761 92.5242 12.9773C92.0276 13.2256 91.4656 13.3562 90.8775 13.3562C90.2894 13.3562 89.7275 13.2256 89.2309 12.9773C87.9501 12.363 87.0745 11.0561 87.0745 9.55324V0H83.7812V9.55324C83.7812 12.8988 86.1074 15.7217 89.2439 16.4666V19.8906C89.2439 21.9947 87.5319 23.6936 85.4279 23.6936C83.3238 23.6936 81.6249 21.9816 81.6249 19.8906V10.3374H78.3315V19.8906C78.3315 23.8112 81.5203 27 85.4279 27C89.3485 27 92.5373 23.8112 92.5373 19.8906V16.4666C95.6607 15.7217 98 12.9119 98 9.55324V0H94.6805Z"
        fill="url(#paint1_linear_1363_77117)"
      />
      <path
        d="M13.8505 3.81607C13.2232 2.56147 12.2692 1.62052 11.0276 0.980155C10.3219 0.601162 9.59006 0.352856 8.81901 0.222169C8.04795 0.0914815 7.26383 0.0261383 6.46664 0.0261383H0.337402V16.6365H6.62346C9.30255 16.6365 11.3282 15.9177 12.7135 14.5063C14.0988 13.0818 14.7914 11.03 14.7914 8.36399C14.7914 6.58664 14.4778 5.07067 13.8505 3.81607ZM10.8185 11.1346C10.5049 11.9448 10.0213 12.5591 9.38096 13.0034C9.22414 13.0949 9.04118 13.1863 8.81901 13.2648C8.59684 13.3432 8.3616 13.4085 8.1133 13.4608C7.86499 13.5131 7.61668 13.5653 7.36838 13.5915C7.12007 13.6176 6.88484 13.6438 6.66267 13.6438H3.74834V3.05808H6.46664C7.12007 3.05808 7.78658 3.14956 8.46615 3.34559C9.14573 3.52856 9.70768 3.88141 10.1389 4.40416C10.531 4.87464 10.8185 5.44966 11.0015 6.11617C11.1844 6.78267 11.2759 7.54066 11.2759 8.36399C11.3021 9.39642 11.1322 10.3243 10.8185 11.1346Z"
        fill="#35373F"
      />
      <path
        d="M28.5527 7.74974C28.2652 6.96562 27.847 6.28605 27.3111 5.72409C26.7753 5.16214 26.1088 4.73087 25.3378 4.44335C24.5667 4.15584 23.6911 4.01208 22.724 4.01208C21.7439 4.01208 20.8552 4.15584 20.0841 4.44335C19.3131 4.73087 18.6596 5.16214 18.1369 5.72409C17.6141 6.28605 17.209 6.96562 16.9215 7.74974C16.634 8.54694 16.4902 9.42254 16.4902 10.3896C16.4902 12.4153 17.0261 13.9835 18.1108 15.1205C19.1955 16.2575 20.7245 16.8194 22.7371 16.8194C24.7366 16.8194 26.2787 16.2575 27.3634 15.1336C28.4481 14.0097 28.997 12.4284 28.997 10.3896C28.9839 9.42254 28.8402 8.54694 28.5527 7.74974ZM24.9588 12.8988C24.4883 13.5523 23.7434 13.879 22.724 13.879C21.7047 13.879 20.9597 13.5523 20.4762 12.8988C19.9927 12.2454 19.7443 11.409 19.7443 10.4158C19.7443 9.43561 19.9927 8.61228 20.4762 7.95884C20.9597 7.30541 21.7177 6.97869 22.724 6.97869C23.7303 6.97869 24.4883 7.30541 24.9588 7.95884C25.4292 8.61228 25.6645 9.43561 25.6645 10.4158C25.6514 11.4221 25.4162 12.2454 24.9588 12.8988Z"
        fill="#35373F"
      />
      <path
        d="M38.2627 13.4346C37.8968 13.7352 37.4002 13.879 36.7729 13.879C36.2502 13.879 35.8189 13.8006 35.453 13.6307C35.087 13.4608 34.7865 13.2255 34.5774 12.925C34.3552 12.6244 34.1984 12.2585 34.0807 11.8403C33.9762 11.4221 33.9108 10.9647 33.9108 10.468C33.9108 9.99757 33.9631 9.55323 34.0546 9.13503C34.1461 8.71683 34.3029 8.33784 34.5251 8.01112C34.7472 7.6844 35.0348 7.42303 35.4138 7.24006C35.7927 7.0571 36.2371 6.95255 36.7468 6.95255C37.361 6.95255 37.8576 7.10937 38.2366 7.39689C38.6156 7.69747 38.8508 8.02419 38.9292 8.40318H42.4055C42.301 7.63212 42.0788 6.97869 41.7652 6.41673C41.4384 5.85478 41.0333 5.41044 40.5498 5.04452C40.0532 4.67859 39.4912 4.41722 38.8639 4.24732C38.2366 4.07743 37.557 3.98595 36.8382 3.98595C35.8581 3.98595 34.9825 4.14277 34.2114 4.44335C33.4404 4.74393 32.8 5.18827 32.2773 5.75023C31.7545 6.31218 31.3494 6.99176 31.0749 7.78895C30.7874 8.58614 30.6567 9.46175 30.6567 10.4158C30.6567 11.3959 30.7874 12.2846 31.0488 13.0818C31.3102 13.8659 31.6892 14.5455 32.1858 15.0944C32.6824 15.6563 33.3097 16.0745 34.0807 16.362C34.8387 16.6496 35.7274 16.7933 36.7729 16.7933C37.4655 16.7933 38.119 16.7149 38.7724 16.5581C39.4128 16.4012 39.9878 16.1399 40.4975 15.787C41.0072 15.4211 41.4254 14.9768 41.7782 14.4279C42.118 13.879 42.3532 13.2125 42.4578 12.4153H38.9815C38.8508 12.8073 38.6417 13.1471 38.2627 13.4346Z"
        fill="#35373F"
      />
      <path
        d="M52.168 13.3693C51.9328 13.5131 51.6583 13.6307 51.3447 13.7222C51.031 13.8136 50.7435 13.8659 50.4952 13.8659C50.1162 13.8659 49.8156 13.8006 49.5673 13.6699C49.319 13.5392 49.136 13.3562 49.0054 13.1471C48.8747 12.925 48.7832 12.6897 48.744 12.4284C48.7048 12.167 48.6917 11.8925 48.6917 11.6181V7.16165H52.3248V4.27347H48.6917V0.901733H45.3853V4.27347H43.5034V7.16165H45.3853V12.3891C45.3853 13.0818 45.4637 13.696 45.6336 14.2449C45.8035 14.7938 46.0518 15.2381 46.3916 15.5779C46.7706 15.983 47.2411 16.2967 47.8161 16.4927C48.3911 16.6888 49.0446 16.7933 49.8025 16.7933C50.0378 16.7933 50.2992 16.7802 50.5736 16.741C50.848 16.7149 51.1486 16.6496 51.4753 16.5711C51.789 16.4797 52.0896 16.3882 52.364 16.2706C52.6385 16.1529 52.8868 16.0223 53.1089 15.8785L52.168 13.3693Z"
        fill="#35373F"
      />
      <path
        d="M66.0732 7.74974C65.7857 6.96562 65.3675 6.28605 64.8316 5.72409C64.2958 5.16214 63.6293 4.73087 62.8583 4.44335C62.0872 4.15584 61.2116 4.01208 60.2445 4.01208C59.2644 4.01208 58.3757 4.15584 57.6046 4.44335C56.8336 4.73087 56.1802 5.16214 55.6574 5.72409C55.1347 6.28605 54.7295 6.96562 54.442 7.74974C54.1545 8.54694 54.0107 9.42254 54.0107 10.3896C54.0107 12.4153 54.5466 13.9835 55.6313 15.1205C56.716 16.2575 58.245 16.8194 60.2576 16.8194C62.2571 16.8194 63.7992 16.2575 64.8839 15.1336C65.9686 14.0097 66.5175 12.4284 66.5175 10.3896C66.5044 9.42254 66.3607 8.54694 66.0732 7.74974ZM62.4662 12.8988C61.9957 13.5523 61.2508 13.879 60.2315 13.879C59.2121 13.879 58.4672 13.5523 57.9836 12.8988C57.5001 12.2454 57.2518 11.409 57.2518 10.4158C57.2518 9.43561 57.5001 8.61228 57.9836 7.95884C58.4672 7.30541 59.2252 6.97869 60.2315 6.97869C61.2378 6.97869 61.9957 7.30541 62.4662 7.95884C62.9367 8.61228 63.1719 9.43561 63.1719 10.4158C63.1719 11.4221 62.9367 12.2454 62.4662 12.8988Z"
        fill="#35373F"
      />
      <path
        d="M76.1228 4.0513C75.9398 4.03823 75.7438 4.02516 75.5347 4.02516C74.7375 4.02516 74.0579 4.16892 73.4829 4.4695C72.9079 4.77008 72.3721 5.21442 71.8754 5.80251L71.6925 4.24733H68.6475V16.6104H71.9539V11.0561C71.9539 10.455 71.9931 9.93223 72.0845 9.46175C72.163 8.99128 72.3982 8.56001 72.7511 8.14181C72.9863 7.89351 73.2607 7.69748 73.5613 7.56679C73.8619 7.4361 74.1886 7.33155 74.5153 7.26621C74.842 7.20086 75.1949 7.17473 75.5608 7.16166C75.9267 7.14859 76.2927 7.14859 76.6455 7.14859V4.10357C76.4756 4.0905 76.3057 4.07744 76.1228 4.0513Z"
        fill="#35373F"
      />
      <path d="M78.3057 0.0261383V16.6365H81.6121V0.0261383H78.3057Z" fill="#35373F" />
      <defs>
        <linearGradient
          id="paint0_linear_1363_77117"
          x1="88.1428"
          y1="26.9926"
          x2="88.1428"
          y2="-0.00378945"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#76AC98" />
          <stop offset="1" stop-color="#99CDA2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1363_77117"
          x1="88.1428"
          y1="26.9926"
          x2="88.1428"
          y2="-0.00378945"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#76AC98" />
          <stop offset="1" stop-color="#99CDA2" />
        </linearGradient>
      </defs>
    </svg>
  );
};
