import React, { FC } from 'react';
import { styled } from 'styled-components';

import defaultAvatarPath from '../../assets/icons/avatar.svg';
import defaultDoctorAvatarPath from '../../assets/icons/doc_avatar.svg';
import { HOST, IS_LOCAL } from '../../const';

const AvatarWrapper = styled.div.withConfig({
  shouldForwardProp: (p) => !['size'].includes(p),
})<{
  size?: 'small' | 'large';
}>`
  width: ${(props) => (props.size === 'large' ? '50px' : '48px')};
  height: ${(props) => (props.size === 'large' ? '50px' : '48px')};
  margin-right: 8px;
  border-radius: ${(props) => (props.size === 'large' ? '25px' : '24px')};

  & img {
    width: ${(props) => (props.size === 'large' ? '50px' : '44px')};
    height: ${(props) => (props.size === 'large' ? '50px' : '44px')};
    border-radius: ${(props) => (props.size === 'large' ? '25px' : '22px')};
  }
`;

type AvatarProps = {
  size: 'small' | 'large';
  path: string;
  isDoctor?: boolean;
};

export const Avatar: FC<AvatarProps> = ({ size, path, isDoctor }) => {
  const defaultAvatar = isDoctor ? defaultDoctorAvatarPath : defaultAvatarPath;
  const currentPath = IS_LOCAL ? `${HOST}${path}` : `${path}`;

  return (
    <AvatarWrapper size={size}>
      <img src={path ? currentPath : defaultAvatar} alt="avatar" />
    </AvatarWrapper>
  );
};
