import React, { FC } from 'react';
import { styled } from 'styled-components';
import { COLORS } from '../../../const';

type AppButtonProps = {
  title: string;
  fullWidth?: boolean;
  bold?: boolean;
  onClick?: () => void;
  icon?: JSX.Element;
  disabled?: boolean;
};

export const AppButton: FC<AppButtonProps> = ({
  title,
  fullWidth,
  bold,
  onClick,
  icon,
  disabled,
}) => {
  return (
    <StyledButton bold={bold} fullWidth={fullWidth} onClick={onClick} disabled={disabled}>
      {icon}
      {title}
    </StyledButton>
  );
};

const StyledButton = styled.button.withConfig({
  shouldForwardProp: (p) => !['bold', 'fullWidth'].includes(p),
})<{
  bold?: boolean;
  fullWidth?: boolean;
}>`
  min-width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  padding: 12px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border-radius: 12px;
  background-color: ${COLORS.main_green};
  color: #fff;
  font-weight: ${(props) => (props.bold ? 600 : 400)};

  &:disabled {
    background-color: ${COLORS.main_grey1};
  }
`;
