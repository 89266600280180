import { ProfileType } from '../../types';

export const tokenProvider = () => {
  let _token: string | null = localStorage.getItem('TOKEN_AUTH')
    ? JSON.parse(localStorage.getItem('TOKEN_AUTH') || '')
    : null;

  let _profile: ProfileType | null = localStorage.getItem('PROFILE')
    ? JSON.parse(localStorage.getItem('PROFILE') || '')
    : null;

  const getToken = () => {
    if (!_token) {
      return null;
    }
    return _token;
  };

  const isLoggedIn = () => {
    return !!_token;
  };

  const setToken = (token: typeof _token) => {
    if (token) {
      localStorage.setItem('TOKEN_AUTH', JSON.stringify(token));
    } else {
      localStorage.removeItem('TOKEN_AUTH');
    }
    _token = token;
  };

  const getProfile = () => {
    if (!_profile) {
      return null;
    }
    return _profile;
  };

  const setProfile = (profile: typeof _profile) => {
    if (profile) {
      localStorage.setItem('PROFILE', JSON.stringify(profile));
    } else {
      localStorage.removeItem('PROFILE');
    }
    _profile = profile;
  };

  return {
    getToken,
    isLoggedIn,
    setToken,
    getProfile,
    setProfile,
  };
};
