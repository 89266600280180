import { styled } from 'styled-components';
import { COLORS } from '../../const';

export const RowWrapper = styled.div`
  padding: 16px 0;
  display: flex;
  align-items: center;

  & + & {
    border-top: 1px solid ${COLORS.main_grey1};
  }
`;

export const Number = styled.div`
  color: ${COLORS.main_green};
  font-size: 32px;
  font-weight: 700;
`;

export const QuestionWrapper = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  font-size: 14px;

  & span:first-of-type {
    font-size: 16px;
    font-weight: 600;
  }

  & span:nth-of-type(2n) {
    margin-right: 21px;
  }

  & span + span,
  & div + div {
    padding-top: 6px;
  }
`;
