import { styled } from 'styled-components';
import { COLORS } from '../../../../const';

export const ImgContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: center;

  & img {
    width: 80%;
  }
`;

export const DefaultBlock = styled.div`
  position: absolute;
  bottom: 120px;
  width: 80%;
  display: flex;
  justify-content: space-between;
`;

export const PillCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const PillCard = styled.div`
  width: 48%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-radius: 16px;
  background-color: #f5f5f5;
`;

export const IconContainer = styled.div`
  min-height: 40px;
  display: flex;
  align-items: center;
`;

export const PillCardFooter = styled.div`
  padding: 6px;
  display: flex;
  gap: 17px;
  border-radius: 6px;
  background-color: #76ac98;

  & div {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
  }
`;

export const UnitBlock = styled.div`
  margin-top: -30px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

export const UnitIcon = styled.div.withConfig({
  shouldForwardProp: (p) => !['active'].includes(p),
})<{
  active?: boolean;
}>`
  position: relative;
  width: 58px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${(props) => (props.active ? `2px solid ${COLORS.main_green}` : 'none')};
  border-radius: 50%;
`;

export const GapBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Item = styled.div`
  width: 100%;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: ${COLORS.main_white};
  border-radius: 16px;
`;

export const AlarmBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ButtonsBlock = styled.div`
  width: 100%;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
