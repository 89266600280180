import styled, { keyframes } from 'styled-components';

export const appear = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const slide = keyframes`
  0% {
    transform: translateY(-150px);
  }
  100% {
    transform: translateY(0);
  }
`;

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(55, 55, 63, 0.3);
  animation-name: ${appear};
  animation-duration: 300ms;
`;

export const Dialog = styled.div.withConfig({
  shouldForwardProp: (p) => !['size'].includes(p),
})<{
  size?: 'small' | 'large';
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 358px;
  height: ${(props) => (props.size === 'large' ? '329px' : 'auto')};
  padding: 16px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: left;
  animation-name: ${slide};
  animation-duration: 0.5s;
`;

export const DialogTitle = styled.div`
  display: flex;
  justify-content: center;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 8px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 4px;
`;

export const DialogContent = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
