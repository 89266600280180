import React, { FC } from 'react';
import { COLORS } from '../../../const';

type ProfileIconProps = {
  isActive: boolean;
};

export const ProfileIcon: FC<ProfileIconProps> = ({ isActive }) => {
  return (
    <svg width="26" height="29" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path
          id="Vector"
          d="M13.0062 28.2125C10.2562 28.2125 7.49994 28.1562 4.74994 28.2312C2.59994 28.2875 0.449939 26.5312 0.681189 23.8125C0.862439 21.6937 1.68744 19.875 3.24369 18.4187C4.61869 17.1312 6.24994 16.3937 8.13744 16.2562C9.64994 16.1437 11.1624 16.225 12.6749 16.2062C14.1749 16.1937 15.6812 16.1937 17.1812 16.2062C21.0749 16.2375 24.3374 18.8875 25.1437 22.6937C25.3874 23.8375 25.4687 24.9812 24.8937 26.0687C24.1624 27.4437 23.0187 28.2 21.4499 28.2125C18.6437 28.225 15.8249 28.2125 13.0062 28.2125ZM12.9999 26.0562C15.7437 26.0562 18.4874 26.0562 21.2249 26.0562C22.1812 26.0562 22.8624 25.5812 23.0749 24.775C23.1749 24.4 23.1437 24.0062 23.0999 23.6312C22.6999 20.5812 20.2187 18.4062 17.1437 18.4C14.3687 18.3937 11.5937 18.3937 8.81869 18.4C5.77494 18.4062 3.27494 20.6125 2.89994 23.6375C2.83744 24.1437 2.78744 24.6687 3.06869 25.15C3.44994 25.8125 4.04994 26.0625 4.77494 26.0625C7.51869 26.0625 10.2562 26.0562 12.9999 26.0562Z"
          fill={isActive ? `${COLORS.main_green}` : `${COLORS.main_grey1}`}
        />
        <path
          id="Vector_2"
          d="M12.9937 14.1875C9.44371 14.175 6.29996 11.375 6.29371 7.48125C6.28746 3.4625 9.61246 0.762499 13.0187 0.768749C16.4125 0.774999 19.7125 3.4875 19.7062 7.4875C19.6937 11.35 16.5812 14.175 12.9937 14.1875ZM13.0062 11.9875C15.5062 11.9875 17.525 9.96875 17.525 7.475C17.525 4.99375 15.4937 2.91875 13.0125 2.94375C10.325 2.975 8.46871 5.1125 8.47496 7.4875C8.48121 9.98125 10.5 11.9875 13.0062 11.9875Z"
          fill={isActive ? `${COLORS.main_green}` : `${COLORS.main_grey1}`}
        />
      </g>
    </svg>
  );
};
