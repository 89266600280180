import React, { useState, useEffect } from 'react';
import { PageContainer } from '../../components';
import { Form } from './Form';
import { AvatarForm } from './AvatarForm';

export const RegistrationPage = () => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(true);

  const onNext = () => {
    setStep(2);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);

  return (
    <PageContainer isFull={true}>
      {!loading && step === 1 && <Form onNext={onNext} />}
      {!loading && step === 2 && <AvatarForm />}
    </PageContainer>
  );
};
