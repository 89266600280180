import React from 'react';
import { COLORS } from '../../../const';

export const DescriptionIcon = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
      <path
        id="Vector"
        d="M6.32421 23.1522L1.86977 19.8526L8.08399 13.0884L0 9.78883L2.3647 5.05942L9.45882 8.52399L9.12886 0.824951H14.9581L14.6282 8.52399L21.7223 5.05942L24.032 9.78883L15.948 13.1434L22.1622 19.9076L17.7078 23.2072L12.0435 15.7281L6.32421 23.1522Z"
        fill={COLORS.text_grey}
      />
    </g>
  </svg>
);
