import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Text } from '../../components';

import imgPath from '../../assets/recover.png';

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
`;

const Background = styled.div`
  position: absolute;
  top: 123px;
  left: calc(50% - 280px);
  width: 560px;
  height: 757px;
  background-image: url(${imgPath});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: top;
  z-index: 1;

  @media screen and (max-width: 560px) {
    top: 60px;
    left: 2%;
    right: -2%;
    right: 0;
    width: 96%;
    height: 100%;
  }
`;

const TextWrapper = styled.div`
  position: absolute;
  top: 260px;
  left: calc(50% - 280px);
  width: 560px;
  height: 300px;
  z-index: 2;

  @media screen and (max-width: 560px) {
    top: 180px;
    left: 5%;
    right: 5%;
    right: 0;
    width: 90%;
  }
`;

export const RecoverPage = () => {
  const [error, setError] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has('error')) {
      setError(true);
    }
  }, [searchParams]);

  return (
    <Container>
      <Background />

      {error && (
        <TextWrapper>
          <Text>Здравствуйте!</Text>
          <br />
          <br />
          <Text>Ссылка на получения пароля уже не активна.</Text>
          <br />
          <br />
          <Text>
            Если необходимо восстановить пароль, то повторите восстановление пароля в приложении.
          </Text>
        </TextWrapper>
      )}

      {!error && (
        <TextWrapper>
          <Text>Здравствуйте!</Text>
          <br />
          <br />
          <Text>На Вашу почту отправлен новый пароль.</Text>
        </TextWrapper>
      )}
    </Container>
  );
};
