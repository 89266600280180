import React, { FC } from 'react';
import { COLORS } from '../../../const';

type CircleIconProps = {
  size: 's' | 'sm' | 'm';
};

export const CircleIcon: FC<CircleIconProps> = ({ size }) => {
  if (size === 's') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <circle cx="10" cy="10" r="8.5" fill="#F5F5F5" stroke={`${COLORS.main_green}`} />
        <circle cx="10" cy="10" r="6" fill={`${COLORS.main_green}`} />
      </svg>
    );
  }

  if (size === 'sm') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <circle
          cx="12"
          cy="12"
          r="11"
          fill="#F5F5F5"
          stroke={`${COLORS.main_green}`}
          strokeWidth="2"
        />
        <circle cx="12" cy="12" r="8" fill={`${COLORS.main_green}`} />
      </svg>
    );
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <circle
        cx="16"
        cy="16"
        r="15"
        fill="#F5F5F5"
        stroke={`${COLORS.main_green}`}
        strokeWidth="2"
      />
      <circle cx="16" cy="16" r="10.6667" fill={`${COLORS.main_green}`} />
    </svg>
  );
};
