import React, { FC, ChangeEvent } from 'react';
import { styled } from 'styled-components';
import { CheckIcon, CircleIcon } from '../../Icons';
import { OPTIONS_TYPES, OptionType } from '../../../types';
import { COLORS } from '../../../const';

type RadioButtonProps = {
  variant: OPTIONS_TYPES;
  option: OptionType;
  onCheckAnswer?: (e: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
};

export const RadioButton: FC<RadioButtonProps> = ({ variant, option, onCheckAnswer, checked }) => {
  const isMobile = window.screen.availWidth < 450;

  const renderCheckedIcon = () => {
    if (variant === OPTIONS_TYPES.MULTISELECT) {
      return <CheckIcon size={isMobile ? 's' : 'm'} />;
    }

    if (variant === OPTIONS_TYPES.PICKER || variant === OPTIONS_TYPES.DECIMAL) {
      return <CircleIcon size={isMobile ? 's' : 'sm'} />;
    }

    return <CircleIcon size={isMobile ? 's' : 'm'} />;
  };

  return (
    <>
      {checked && renderCheckedIcon()}
      {!checked && (
        <StyledRadio
          id={option?.id.toString()}
          value={option?.score}
          type="radio"
          onChange={onCheckAnswer}
          variant={variant}
        />
      )}
    </>
  );
};

const StyledRadio = styled.input.withConfig({
  shouldForwardProp: (p) => !['variant'].includes(p),
})<{
  variant: OPTIONS_TYPES;
}>`
  width: ${(props) =>
    props.variant === OPTIONS_TYPES.PICKER || props.variant === OPTIONS_TYPES.DECIMAL
      ? '24px'
      : '32px'};
  height: ${(props) =>
    props.variant === OPTIONS_TYPES.PICKER || props.variant === OPTIONS_TYPES.DECIMAL
      ? '24px'
      : '32px'};
  transform: translate3d(0px, 0, 0px);

  &::before {
    content: '';
    width: ${(props) =>
      props.variant === OPTIONS_TYPES.PICKER || props.variant === OPTIONS_TYPES.DECIMAL
        ? '20px'
        : '28px'};
    height: ${(props) =>
      props.variant === OPTIONS_TYPES.PICKER || props.variant === OPTIONS_TYPES.DECIMAL
        ? '20px'
        : '28px'};
    display: flex;
    background-color: #f5f5f5;
    border: 2px solid ${COLORS.main_green};
    border-radius: ${(props) => (props.variant !== OPTIONS_TYPES.MULTISELECT ? '50%' : '6px')};
    transform: translate3d(0px, 0px, 2px);
  }

  @media screen and (max-width: 450px) {
    width: 20px;
    height: 20px;

    &::before {
      width: 16px;
      height: 16px;
      border: 1px solid ${COLORS.main_green};
    }
  }
`;
