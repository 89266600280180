import React, { FC, useMemo, useState } from 'react';
import { AppButton, SelectOptions, Text } from '../../../../components';
import { TakingType, RegimentsType, PILL_UNIT } from '../../../../types';
import { ButtonWrapper, GapBlock, InfoBlock } from './styled';

import calendarPath from '../../../../assets/icons/calendar.svg';
import { COLORS } from '../../../../const';
import { getPillIcon } from '../../../../utils';

export type StepProps = {
  values: RegimentsType;
  onChange: (value: TakingType[]) => void;
  onNextStep: () => void;
  onPrevStep?: () => void;
};

const dayIntervalOptions = [
  {
    value: '1',
    title: 'Каждый день',
  },
  {
    value: '2',
    title: 'Через день',
  },
  {
    value: '7',
    title: 'Раз в неделю',
  },
];

const dateEndOptions = [
  {
    value: '',
    title: 'Бессрочно',
  },
  {
    value: '1',
    title: 'Дата',
  },
];

export const ThirdStep: FC<StepProps> = ({ values, onChange, onNextStep, onPrevStep }) => {
  const [isDateEnd, setIsDateEnd] = useState(false);

  const onSelect = (name: string, value: string) => {
    const takings = values.taking_drugs.concat();
    const update: TakingType[] = takings.map((taking) => {
      return {
        ...taking,
        [name]: value,
      };
    });
    onChange(update);
  };

  const onSelectDateEndType = (name: string, value: string) => {
    setIsDateEnd(!!value);
  };

  const unit = useMemo(() => {
    if (values.unit === PILL_UNIT.PILL) {
      return 'таблеток';
    }
    if (values.unit === PILL_UNIT.CAPSULE) {
      return 'капсул';
    }
    if (values.unit === PILL_UNIT.INJECTION) {
      return 'уколов';
    }
  }, [values]);

  return (
    <GapBlock>
      <SelectOptions
        type="text"
        name="day_interval"
        title="Дни приема"
        options={dayIntervalOptions}
        onChange={onSelect}
      />

      <SelectOptions type="date" name="date_start" title="Начало" onChange={onSelect} />

      <SelectOptions
        type="text"
        name="date_end_type"
        title="Окончание"
        options={dateEndOptions}
        onChange={onSelectDateEndType}
      />

      {isDateEnd && <SelectOptions type="date" name="date_end" title="Дата" onChange={onSelect} />}

      {values.taking_drugs.length && values.taking_drugs[0].date_end && (
        <InfoBlock>
          <div></div>
          <div>
            <img src={calendarPath} alt="calendar" />
          </div>
        </InfoBlock>
      )}

      <InfoBlock>
        <div>
          <Text size={14}>27 декабря</Text>
          <br />
          <Text size={12} color={COLORS.text_grey}>
            Последний день курса
          </Text>
        </div>
        <div>
          <img src={calendarPath} alt="calendar" />
        </div>
      </InfoBlock>

      <InfoBlock>
        <div>
          <Text size={14}>36 {unit}</Text>
          <br />
          <Text size={12} color={COLORS.text_grey}>
            Всего будет принято лекарств
          </Text>
        </div>
        <div>{getPillIcon(values.unit)}</div>
      </InfoBlock>

      <ButtonWrapper>
        <AppButton title="Назад" onClick={onPrevStep} />
        <AppButton
          title="Добавить"
          onClick={onNextStep}
          disabled={values.taking_drugs.every((take) => !take.date_start && !take.day_interval)}
        />
      </ButtonWrapper>
    </GapBlock>
  );
};
