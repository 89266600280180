import React, { FC, useState, useEffect, SyntheticEvent } from 'react';
import { styled } from 'styled-components';
import { PageContainer, Button, ProgressBar } from '../../../components';
import { API } from '../../../const';
import { AnswerType, OptionType, QuestionType, SURVEYS_TYPES } from '../../../types';
import { FirstStep } from './FirstStep';
import { Step } from './Step';

const Wrapper = styled.div`
  max-width: 100%;
  padding: 0 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

type QuestionPageProps = {
  questions: QuestionType[];
  surveyType: SURVEYS_TYPES;
  onSendTest: (answers: AnswerType[]) => void;
};

export const QuestionPage: FC<QuestionPageProps> = ({ questions, surveyType, onSendTest }) => {
  const [currentQuestions, setCurrentQuestions] = useState<QuestionType[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [answers, setAnswers] = useState<AnswerType[]>([]);
  const [disabled, setDisabled] = useState<boolean>(true);

  const saveAnswer = (question: QuestionType, value: OptionType[] | string) => {
    let isString = typeof value === 'string' ? true : false;
    const answer: AnswerType = {
      question: question,
    };

    if (isString) {
      answer.answer_body = value as string;
    } else {
      answer.answer_selected = value as OptionType[];
    }

    const currentAnswer = answers.find((a) => a.question.id === question.id);
    if (currentAnswer) {
      if (isString) {
        currentAnswer.answer_body = value as string;
      } else {
        currentAnswer.answer_selected = value as OptionType[];
      }
    } else {
      setAnswers((prev) => [...prev, answer]);
    }

    if (question.id === currentQuestions[currentQuestions.length - 1].id) {
      setDisabled(false);
    }
  };

  const goNext = (e: SyntheticEvent<HTMLButtonElement>) => {
    const step: number = Number((e.currentTarget as Element).id);
    if (step < questions.length) {
      setCurrentPage(step + 1);
    }
  };

  const postAnswers = async () => {
    const data = answers;
    try {
      await fetch(`${API}/responses?type=${surveyType}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onButtonClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    if (currentPage === questions[questions.length - 1]?.page) {
      postAnswers();
      onSendTest(answers);
    } else {
      goNext(e);
    }
  };

  useEffect(() => {
    const pageQuestions = questions?.filter((q) => q.page === currentPage);
    setCurrentQuestions(pageQuestions);
    setDisabled(true);
  }, [currentPage, questions]);

  return (
    <PageContainer>
      <Wrapper>
        <ProgressBar count={questions[questions.length - 1]?.page} active={currentPage} />

        {currentPage === 1 && currentQuestions && (
          <FirstStep questions={currentQuestions} saveAnswer={saveAnswer} surveyType={surveyType} />
        )}

        {currentPage > 1 &&
          currentPage <= questions[questions.length - 1]?.page &&
          currentQuestions && <Step questions={currentQuestions} saveAnswer={saveAnswer} />}

        <Button id={currentPage.toString()} onClick={onButtonClick} disabled={disabled}>
          {currentPage === questions[questions.length - 1]?.page ? 'Отправить' : 'Далее'}
        </Button>
      </Wrapper>
    </PageContainer>
  );
};
