import { styled } from 'styled-components';
import { COLORS } from '../../const';

export const SurveyTooltipContainer = styled.div.withConfig({
  shouldForwardProp: (p) => !['size'].includes(p),
})<{
  size?: 'small' | 'large';
  top?: number;
}>`
  position: absolute;
  top: ${(props) => (!!props.top ? `${props.top}px` : '0px')};
  left: ${(props) => (props.size === 'small' ? '0px' : 'calc(50% - 200px);')};
  width: ${(props) => (props.size === 'small' ? '264px' : '368px')};
  height: ${(props) => (props.size === 'small' ? '154px' : '196px')};
  background-color: #fff;
  border: 2px solid ${COLORS.main_green};
  border-radius: 16px;
  font-size: ${(props) => (props.size === 'small' ? '14px' : '18px')};

  & span {
    text-align: center;
  }

  @media screen and (max-width: 450px) {
    left: ${(props) => (props.size === 'small' ? '0px' : '20px')};
    right: 20px;
    width: ${(props) => (props.size === 'small' ? '100%' : '90%')};
    font-size: 14px;
  }
`;

export const TooltipWrapper = styled.div`
  position: relative;
  padding: 48px;
  display: flex;
  flex-direction: column;

  & div {
    display: flex;
    justify-content: center;

    & a {
      padding: 0 8px;
      color: #6c6e78;
    }

    & a:first-of-type {
      padding: 0 8px;
      border-right: 1px solid ${COLORS.main_green};
    }
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
`;
