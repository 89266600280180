import React, { FC, useState, useEffect } from 'react';
import { OPTIONS_TYPES, QuestionType, OptionType } from '../../../types';
import { MultiSelectBlock, TextareaBlock, QuestionBlock } from '../../../components';
import { StepProps } from './FirstStep';

export const Step: FC<StepProps> = ({ questions, saveAnswer }) => {
  const [isShown, setIsShown] = useState<boolean>(false);

  const simpleQuestions = questions.filter((q) => q.type !== OPTIONS_TYPES.DECIMAL);
  const relatedQuestions = questions.filter((q) => q.type === OPTIONS_TYPES.DECIMAL);

  const checkQuestion = (question: QuestionType, value: OptionType[] | string) => {
    if (typeof value !== 'string' && value[0].score > 0) {
      setIsShown(true);
    } else {
      setIsShown(false);
    }
    saveAnswer(question, value);
  };

  const renderQuestion = (question: QuestionType) => {
    if (question.type === OPTIONS_TYPES.MULTISELECT) {
      return <MultiSelectBlock key={question.id} question={question} saveAnswer={saveAnswer} />;
    }
    if (question.type === OPTIONS_TYPES.TEXT) {
      return <TextareaBlock key={question.id} question={question} saveAnswer={saveAnswer} />;
    }
    return <QuestionBlock key={question.id} question={question} saveAnswer={checkQuestion} />;
  };

  useEffect(() => {
    setIsShown(false);
  }, [questions]);

  return (
    <>
      {simpleQuestions.map((q) => renderQuestion(q))}

      {isShown &&
        relatedQuestions &&
        relatedQuestions.map((q) => (
          <TextareaBlock key={q.id} question={q} saveAnswer={saveAnswer} />
        ))}
    </>
  );
};
