import React, { FC } from 'react';
import { PillsIconsType } from './TabletIcon';
import { COLORS } from '../../../const';

export const InjectionIcon: FC<PillsIconsType> = ({ isMissed }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none">
      <rect
        x="20.5061"
        y="9.49388"
        width="12"
        height="23"
        rx="1"
        transform="rotate(45 20.5061 9.49388)"
        fill={isMissed ? '#E48B83' : `${COLORS.main_green}`}
      />
      <rect
        x="6.36401"
        y="27.8787"
        width="6"
        height="4"
        transform="rotate(45 6.36401 27.8787)"
        fill={isMissed ? '#F0E0E1' : '#76AC98'}
      />
      <rect
        x="1.41431"
        y="28.5858"
        width="12"
        height="2"
        rx="1"
        transform="rotate(45 1.41431 28.5858)"
        fill={isMissed ? '#E48B83' : `${COLORS.main_green}`}
      />
      <rect
        x="33.2341"
        y="3.83714"
        width="2"
        height="13"
        transform="rotate(45 33.2341 3.83714)"
        fill={isMissed ? '#F0E0E1' : '#76AC98'}
      />
      <path
        d="M8.48535 21.5147L9.19246 20.8076L13.0815 24.6967C13.2768 24.892 13.2768 25.2085 13.0815 25.4038C12.8863 25.5991 12.5697 25.5991 12.3744 25.4038L8.48535 21.5147Z"
        fill={isMissed ? '#F0E0E1' : '#76AC98'}
      />
      <path
        d="M10.6067 19.3934L11.3138 18.6863L15.2029 22.5754C15.3981 22.7706 15.3981 23.0872 15.2029 23.2825C15.0076 23.4778 14.691 23.4778 14.4958 23.2825L10.6067 19.3934Z"
        fill={isMissed ? '#F0E0E1' : '#76AC98'}
      />
      <path
        d="M12.728 17.2721L13.4351 16.565L17.3242 20.4541C17.5195 20.6493 17.5195 20.9659 17.3242 21.1612C17.129 21.3564 16.8124 21.3564 16.6171 21.1612L12.728 17.2721Z"
        fill={isMissed ? '#F0E0E1' : '#76AC98'}
      />
      <path
        d="M14.8491 15.1508L15.5562 14.4437L19.4453 18.3327C19.6406 18.528 19.6406 18.8446 19.4453 19.0398C19.2501 19.2351 18.9335 19.2351 18.7382 19.0398L14.8491 15.1508Z"
        fill={isMissed ? '#F0E0E1' : '#76AC98'}
      />
    </svg>
  );
};
