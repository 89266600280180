import { styled } from 'styled-components';
import { COLORS } from '../../../../const';

export const AnswersBlockWrapper = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  background-color: #edeef0;
  border-radius: 24px;
  overflow-y: scroll;
`;

export const TitleRow = styled.div`
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const DiagnosisWrapper = styled.div`
  width: 100%;
  margin: 24px 0;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  background-color: #edeef0;
  border-radius: 24px;
`;

export const Diagnosis = styled.div.withConfig({
  shouldForwardProp: (p) => !['checked'].includes(p),
})<{
  checked?: boolean;
}>`
  padding: 12px 30px;
  display: flex;
  background-color: ${(props) => props.checked && '#D4F0E1'};
  border-radius: 16px;
  border: ${(props) => (props.checked ? `2px solid ${COLORS.main_green}` : '2px solid #babdcc')};
  color: ${(props) => (props.checked ? '#35373F' : '#babdcc')};
  font-size: 16px;
  font-weight: 600;

  & span {
    margin-left: 10px;
  }
`;

export const DiagnosisCheck = styled.input`
  width: 20px;
  height: 20px;
  transform: translate3d(0px, 0, 0px);

  &::before {
    content: '';
    width: 18px;
    height: 18px;
    display: flex;
    background-color: #f5f5f5;
    border: 2px solid ${COLORS.main_green}
    border-radius: 6px;
    transform: translate3d(0px, 0px, 2px);
  }
`;
