import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BlogIcon, ProfileIcon } from '../Icons';
import { StyledPanel } from './styled';

export const Panel = () => {
  const location = useLocation();

  return (
    <StyledPanel>
      <Link to={'/blog'}>
        <BlogIcon isActive={location.pathname.split('/')[1] === 'blog'} />
      </Link>
      {/* <Link to={'/medcard'}>
        <CardIcon isActive={location.pathname.split('/')[1] === 'medcard'} />
      </Link> */}
      <Link to={'/profile'}>
        <ProfileIcon isActive={location.pathname.split('/')[1] === 'profile'} />
      </Link>
    </StyledPanel>
  );
};
