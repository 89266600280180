import React, { FC } from 'react';
import { COLORS } from '../../../const';

type CheckIconProps = {
  size: 's' | 'm';
};

export const CheckIcon: FC<CheckIconProps> = ({ size }) => {
  if (size === 's') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <rect width="20" height="20" rx="6" fill={`${COLORS.main_green}`} />
        <path
          d="M5 8.52273L9.78125 13.125L15.625 7.5"
          stroke="#F5F5F5"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <rect width="32" height="32" rx="6" fill={`${COLORS.main_green}`} />
      <path
        d="M8 13.6364L15.65 21L25 12"
        stroke="#F5F5F5"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
