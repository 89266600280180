import React, { FC, PropsWithChildren } from 'react';
import { RowWrapper, Number, QuestionWrapper } from './styled';

type AnswerRowProps = {
  number: string;
};

export const AnswerRow: FC<PropsWithChildren<AnswerRowProps>> = ({ number, children }) => {
  return (
    <RowWrapper>
      <Number>{number}</Number>
      <QuestionWrapper>{children}</QuestionWrapper>
    </RowWrapper>
  );
};
