import React, { FC, PropsWithChildren } from 'react';
import { styled } from 'styled-components';
import { COLORS } from '../../const';

const StyledText = styled.span.withConfig({
  shouldForwardProp: (p) =>
    !['size', 'weight', 'lineHeight', 'textAlign', 'color', 'pt', 'pb'].includes(p),
})<{
  size?: number;
  weight?: number;
  lineHeight?: number;
  textAlign?: string;
  color?: string;
  pt?: number;
  pb?: number;
  noWrap?: boolean;
}>`
  padding-top: ${(props) => (props.pt ? `${props.pt}px` : '0px')};
  padding-bottom: ${(props) => (props.pb ? `${props.pb}px` : '0px')};
  color: ${(props) => (props.color ? props.color : `${COLORS.text_dark}`)};
  font-size: ${(props) => (props.size ? `${props.size}px` : '16px')};
  font-weight: ${(props) => (props.weight ? props.weight : 400)};
  line-height: ${(props) => (props.lineHeight ? `${props.lineHeight}px` : '1.2')};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  white-space: ${(props) => (props.noWrap ? 'nowrap' : 'inherit')};
`;

type TextProps = {
  size?: number;
  weight?: number;
  lineHeight?: number;
  textAlign?: string;
  color?: string;
  pt?: number;
  pb?: number;
  onClick?: () => void;
  noWrap?: boolean;
};

export const Text: FC<PropsWithChildren<TextProps>> = ({
  size,
  weight,
  lineHeight,
  textAlign,
  color,
  pt,
  pb,
  onClick,
  noWrap,
  children,
}) => {
  return (
    <StyledText
      size={size}
      weight={weight}
      lineHeight={lineHeight}
      textAlign={textAlign}
      color={color}
      pt={pt}
      pb={pb}
      onClick={onClick}
      noWrap={noWrap}
    >
      {children}
    </StyledText>
  );
};
