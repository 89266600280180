import React, { FC } from 'react';
import { ResultType } from '../../../../types';
import { AnswerRow, Text } from '../../../../components';
import { AnswersBlockWrapper, TitleRow } from './styled';
import { formatDate, formatIndex } from '../../../../utils';

type AnswersBlockType = {
  data: ResultType;
  index: number;
};

export const AnswersBlock: FC<AnswersBlockType> = ({ data, index }) => {
  const firstAnswers = data?.answers?.slice(0, 5);
  const answers = data?.answers?.slice(5);

  const ANSWERS_NUMS = Array.from({ length: data?.answers?.length }).map((v, i) => {
    if (i + 1 > 9) return `${i + 1}`;
    return `0${i + 1}`;
  });

  return (
    <AnswersBlockWrapper>
      <TitleRow>
        <Text size={20} weight={700}>
          Кардио опрос №{formatIndex(index)}
        </Text>
        <Text color="#959ab4" size={14}>
          {formatDate(data.created_at)}
        </Text>
      </TitleRow>

      {firstAnswers && (
        <AnswerRow number={ANSWERS_NUMS[0]}>
          <div>
            <Text size={14}>{firstAnswers[0]?.question.title}: </Text>
            <Text size={14}>{firstAnswers[0]?.answer_body} </Text>
          </div>
          <div>
            <Text size={14} weight={600}>
              {firstAnswers[1]?.question.title}:{' '}
            </Text>
            <Text size={14}>{firstAnswers[1]?.answer_body} </Text>
            <Text size={14} weight={600}>
              {firstAnswers[2]?.question.title}:{' '}
            </Text>
            <Text size={14}>{firstAnswers[2]?.answer_body} </Text>
          </div>
          <div>
            <Text size={14} weight={600}>
              {firstAnswers[3]?.question.title}:{' '}
            </Text>
            <Text size={14}>{firstAnswers[3]?.answer_body} </Text>
          </div>

          <div>
            <Text size={14} weight={600}>
              {firstAnswers[4]?.question.title}:{' '}
            </Text>
            <Text size={14}>{firstAnswers[4]?.answer_body} </Text>
          </div>
        </AnswerRow>
      )}

      {answers.map((answer, index) => (
        <AnswerRow key={answer?.question.id} number={ANSWERS_NUMS[index + 1]}>
          <span>{answer?.question.title}</span>

          {answer?.answer_body && <span>{answer.answer_body}</span>}

          {!!answer?.answer_selected?.length &&
            answer?.answer_selected.map((answer) => <span key={answer.id}>{answer?.answer}</span>)}
        </AnswerRow>
      ))}
    </AnswersBlockWrapper>
  );
};
