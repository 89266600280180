import { styled } from 'styled-components';
import { COLORS } from '../../../const';

export const Container = styled.div`
  position: relative;
  max-height: 88vh;
  min-height: 540px;
  margin-top: 16px;
  padding: 32px 20px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: ${COLORS.main_white};
`;

export const LogoContainer = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const CloseIconWrapper = styled.button`
  position: absolute;
  top: 0px;
  right: 0;
`;

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const VideoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const PreviewContainer = styled.div`
  min-height: 200px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
  overflow-x: scroll;
`;

export const Preview = styled.div.withConfig({
  shouldForwardProp: (p) => !['width'].includes(p),
})<{
  width?: number;
}>`
  position: relative;
  max-width: 286px;
  width: ${(props) => `${props.width}px`};
  height: 190px;

  & img {
    width: ${(props) => `${props.width}px`};
    max-width: 286px;
    height: 190px;
    object-fit: cover;
    border-radius: 12px;
  }
`;

export const DeleteButton = styled.button`
  position: absolute;
  top: 8px;
  right: 12px;
  width: 18px;
  height: 18px;
  z-index: 10;

  & img {
    width: 24px;
    height: 24px;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 12px 8px;
  background-color: ${COLORS.main_white};
  font-family: 'Lato', sans-serif;
  border: 1px solid ${COLORS.main_grey1};
  border-radius: 12px;
  font-size: 16px;
  color: #35373f;

  &::placeholder {
    color: #959ab4;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
  }
`;

export const TagInputBlock = styled.div`
  position: relative;
  padding: 6px 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border: 1px solid ${COLORS.main_grey1};
  border-radius: 12px;
`;

export const TagInput = styled.input`
  width: 100%;
  background-color: ${COLORS.main_white};
  font-family: 'Lato', sans-serif;
  border: none;

  &::placeholder {
    color: #959ab4;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const TagSearch = styled.div`
  position: absolute;
  max-height: 100px;
  bottom: 20px;
  display: flex;
  gap: 8px;
  background-color: ${COLORS.main_white};
  z-index: 2;
`;

export const Tag = styled.div`
  position: relative;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: ${COLORS.main_grey1};
  border-radius: 24px;
  color: ${COLORS.main_white};
  font-size: 14px;
  cursor: pointer;

  & button {
    padding-top: 2px;
  }
`;

export const FileInput = styled.input`
  position: absolute;
  width: 0;
  height: 0;
`;
