import React, { FC } from 'react';
import {
  AppButton,
  AppContainer,
  OutlineButton,
  TakingDataCard,
  Text,
} from '../../../../components';
import { COLORS, UNIT_OPTIONS } from '../../../../const';
import { CourseType } from '../../../../types';
import { formatTakingDates, getDiffDays, getPillIcon } from '../../../../utils';
import { UnitBlock, UnitIcon, GapBlock, Item, ButtonsBlock, AlarmBlock } from './styled';

import alarmPath from '../../../../assets/icons/alarm.svg';

type PropsType = {
  course: CourseType;
  onClose: () => void;
};

export const CourseItem: FC<PropsType> = ({ course, onClose }) => {
  const diffDays = getDiffDays(course.date_start, course.date_end);

  const getDay = () => {
    let dayName = 'дней';

    if (diffDays === 1 || (diffDays > 20 && diffDays % 10 === 1)) {
      dayName = 'день';
    }

    if (
      (diffDays > 1 && diffDays < 5) ||
      (diffDays > 20 && diffDays % 10 > 1 && diffDays % 10 < 5)
    ) {
      dayName = 'дня';
    }

    return dayName;
  };

  return (
    <AppContainer title="" backAction={onClose}>
      <UnitBlock>
        <UnitIcon active>{getPillIcon(course.unit)}</UnitIcon>
        <Text weight={600}>{course.drug_title}</Text>
        <Text size={12}>{UNIT_OPTIONS[course.unit]}</Text>
      </UnitBlock>

      <GapBlock>
        <TakingDataCard
          unit={course.unit}
          mera={course.mera}
          daytime={course.daytime}
          time={course.date_start}
        />

        <Item>
          <Text size={14}>{course.comment || 'Комментарий'}</Text>
        </Item>

        <AlarmBlock>
          <img src={alarmPath} alt="alarm" />
          <Text size={14}>Напоминать в момент события</Text>
        </AlarmBlock>

        <div style={{ display: 'flex', gap: '8px' }}>
          <Item>
            <Text size={14}>{formatTakingDates(course.date_start)}</Text>
            <Text size={10} color={COLORS.text_grey}>
              начало
            </Text>
          </Item>

          <Item>
            <Text size={14}>{formatTakingDates(course.date_end)}</Text>
            <Text size={10} color={COLORS.text_grey}>
              окончание
            </Text>
          </Item>
        </div>

        <Item>
          <Text size={14}>
            {diffDays} {getDay()}
          </Text>
          <Text size={10} color={COLORS.text_grey}>
            продолжительность курса
          </Text>
        </Item>
      </GapBlock>

      <ButtonsBlock>
        <AppButton title="Завершить курс" fullWidth />
        <OutlineButton title="Удалить" fullWidth />
      </ButtonsBlock>
    </AppContainer>
  );
};
