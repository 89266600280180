import { FFmpeg } from '@ffmpeg/ffmpeg';
import { FileData } from '@ffmpeg/ffmpeg/dist/esm/types';
import { toBlobURL } from '@ffmpeg/util';
import { v4 as uuid } from 'uuid';

const ffmpeg = new FFmpeg();

export const loadFFmpeg = async () => {
  const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd';
  await ffmpeg.load({
    coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
    wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
  });
  console.log('FFmpeg loaded');
};

export const transcodeVideo = async (fileData: FileData, fileName: string): Promise<File> => {
  await loadFFmpeg();

  const name = uuid();
  await ffmpeg.writeFile(fileName, fileData);

  await ffmpeg.exec([
    '-i',
    fileName,
    '-c:v',
    'libx264',
    '-b:v',
    '1200k',
    '-preset',
    'ultrafast',
    '-f',
    'mp4',
    `${name}.mp4`,
  ]);
  const convertedFile = await ffmpeg.readFile(`${name}.mp4`);
  const data = new Uint8Array(convertedFile as ArrayBuffer);

  const file = new File([new Blob([data.buffer])], `${name}.mp4`, { type: 'video/mp4' });

  return file;
};
