import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import { COLORS } from '../../const';
import { Text } from '../Text';
import { SelectWrapper, OptionsWrapper, SelectedOption } from './styled';
import './timepiker.css';

import arrowPath from '../../assets/icons/arrow.svg';

type OptionsType = {
  value: string;
  title: string;
};

type SelectOptionsProps = {
  type: 'text' | 'time' | 'date';
  name: string;
  title?: string;
  options?: OptionsType[];
  onChange: (name: string, value: string) => void;
};

export const SelectOptions: FC<SelectOptionsProps> = ({ type, name, title, options, onChange }) => {
  const [optsIsOpen, setOptsIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<OptionsType>();
  const [selectedTime, setSelectedTime] = useState('9:00');

  const toggleOptions = () => {
    setOptsIsOpen((prev) => !prev);
  };

  const onSelect = (e: SyntheticEvent<HTMLDivElement>) => {
    const id = (e.currentTarget as Element).id;
    const selectedOption = options?.find((opt) => opt.value === id);
    if (selectedOption) {
      setSelectedValue(selectedOption);
      onChange(name, selectedOption.value);
    }
    setOptsIsOpen(false);
  };

  const onSelectTime = (value: moment.Moment) => {
    onChange('time', value.toISOString().split('T')[1]);
    setSelectedTime(value.format('HH:mm'));
    setOptsIsOpen(false);
  };

  useEffect(() => {
    if (options) {
      setSelectedValue(options[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SelectWrapper>
      <div>{title}</div>
      <SelectedOption onClick={toggleOptions}>
        <Text size={14} color={COLORS.text_grey}>
          {type === 'text' && selectedValue?.title}
          {type === 'time' && selectedTime}
        </Text>
        <img src={arrowPath} alt="arrow" />
      </SelectedOption>

      {optsIsOpen && type === 'text' && (
        <OptionsWrapper>
          {options?.map((option) => (
            <div key={option.value} id={option.value} onClick={onSelect}>
              {option.title}
            </div>
          ))}
        </OptionsWrapper>
      )}

      {optsIsOpen && type === 'time' && (
        <OptionsWrapper>
          <TimePicker
            defaultOpenValue={moment(selectedTime || '9:00', 'HH:mm')}
            showSecond={false}
            onChange={onSelectTime}
            open
          />
        </OptionsWrapper>
      )}
    </SelectWrapper>
  );
};
