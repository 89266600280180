import { styled } from 'styled-components';
import { COLORS } from '../../const';

export const Container = styled.div`
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${COLORS.main_grey};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledInput = styled.input.withConfig({
  shouldForwardProp: (p) => !['error'].includes(p),
})<{
  error?: boolean;
}>`
  width: '100%';
  color: ${(props) => (props.error ? '#E74D3F' : '#000')};
  background-color: ${COLORS.main_background};
  font-size: 14px;

  &::placeholder {
    color: ${COLORS.text_grey};
    font-size: 12px;
  }
`;
