import { styled } from 'styled-components';

import { COLORS } from '../../const';

export const Container = styled.div`
  width: 100%;
  max-width: 450px;
  height: 100vh;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #000;
`;

export const FormWrapper = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledButton = styled.button`
  width: 100%;
  margin-top: 16px;
  padding: 12px 0;
  background-color: ${COLORS.main_green};
  border-radius: 16px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;

  &:disabled {
    opacity: 0.5;
  }
`;

export const SocialAuthContainer = styled.div`
  margin: 32px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SocialIconsWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;

  & button + button {
    margin-left: 16px;
  }
`;

export const StyledInput = styled.input`
  -webkit-appearance: none;
  width: 100%;
  padding: 8px;
  background-color: #f5f5f5;
  border: 1px solid ${COLORS.main_grey};
  border-radius: 8px;
  font-size: 14px;
  color: '#E74D3F';
`;
