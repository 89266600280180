import React, { FC } from 'react';
import { COLORS } from '../../../const';

export type PillsIconsType = {
  isMissed?: boolean;
};

export const TabletIcon: FC<PillsIconsType> = ({ isMissed }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
      <circle cx="14.5" cy="14.5" r="14.5" fill={isMissed ? '#E48B83' : `${COLORS.main_green}`} />
      <rect
        x="6.36816"
        y="4.45406"
        width="25"
        height="2"
        rx="1"
        transform="rotate(45 6.36816 4.45406)"
        fill={isMissed ? '#F0E0E1' : '#76AC98'}
      />
    </svg>
  );
};
