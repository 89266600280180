import React, { useState, useEffect } from 'react';
import { useMatches } from 'react-router-dom';
import { API } from '../../../const';
import { AnswerType, QuestionType, SOURCES, SURVEYS_TYPES } from '../../../types';
import { QuestionPage } from '../QuestionPage';
import { EmailPage } from '../EmailPage';

export const QuizPage = () => {
  const [finishTest, setFinishTest] = useState<boolean>(false);
  const [source, setSource] = useState<SOURCES | null>(null);

  const [questions, setQuestions] = useState<QuestionType[]>([]);
  const [answers, setAnswers] = useState<AnswerType[]>([]);

  const matches = useMatches();

  const getSurvey = async () => {
    try {
      const response = await fetch(`${API}/surveys/${matches[1].id}`);
      if (response.status === 200) {
        const data = await response.json();
        setQuestions(data.questions);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSendTest = (answers: AnswerType[]) => {
    setAnswers(answers);
    setFinishTest(true);
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    if (url.searchParams.has('source')) {
      const sourceName = url.searchParams.get('source');
      setSource(sourceName as SOURCES);
    }
    getSurvey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (finishTest) {
    return (
      <EmailPage source={source} answers={answers} surveyType={matches[1].id as SURVEYS_TYPES} />
    );
  }

  return (
    <QuestionPage
      questions={questions}
      surveyType={matches[1].id as SURVEYS_TYPES}
      onSendTest={onSendTest}
    />
  );
};
