import React, { FC, memo } from 'react';
import { COLORS } from '../../const';

type Props = {
  current: number;
  all: number;
  isFull?: boolean;
};

export const ProgressDotsComponent: FC<Props> = ({ current, all, isFull = true }) => {
  const countArr = Array.from({ length: all }).map((v, i) => i + 1);

  return (
    <div
      style={{
        width: isFull ? '100%' : 'auto',
        height: 4,
        display: 'flex',
        justifyContent: 'center',
        gap: 4,
      }}
    >
      {countArr.map((step) => (
        <div
          key={step}
          style={
            step === current
              ? { width: 24, height: 4, borderRadius: 12, backgroundColor: COLORS.main_green }
              : { width: 4, height: 4, borderRadius: 2, backgroundColor: COLORS.main_grey1 }
          }
        ></div>
      ))}
    </div>
  );
};

export const ProgressDots = memo(ProgressDotsComponent);
