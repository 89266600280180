import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { TabsType, CourseType } from '../../../../types';
import { AppContainer, Tabs, Text } from '../../../../components';
import { CourseCard } from './CourseCard';
import { DefaultBlock, ImgContainer, PillCardContainer } from './styled';

import { PILLS } from '../../../../const';

import pillsPath from '../../../../assets/pills.svg';
import addPath from '../../../../assets/add.svg';
import { CourseItem } from './CourseItem';

const tabs: TabsType = {
  active: 'Активные',
  closed: 'Завершенные',
};

export const CoursesPage = () => {
  const [courses, setCourses] = useState<CourseType[]>([]);
  const [currentTab, setCurrentTab] = useState<string>('active');
  const [currentCourse, setCurrentCourse] = useState<CourseType>();

  const navigate = useNavigate();

  const onBack = () => {
    navigate('/profile');
  };

  const onOpenCourse = (id: string) => {
    const course = courses.find((c) => c.id === id);
    setCurrentCourse(course);
  };

  const onClose = () => {
    setCurrentCourse(undefined);
  };

  const onTabChange = (id: string) => {
    setCurrentTab(id);
  };

  useEffect(() => {
    setCourses(PILLS);
  }, []);

  return (
    <>
      {!currentCourse && (
        <AppContainer title="Курсы лекарств" backAction={onBack}>
          <Tabs tabs={tabs} active={currentTab} onToggle={onTabChange}>
            {courses.length === 0 && (
              <>
                <ImgContainer>
                  <img src={pillsPath} alt="pills" />
                </ImgContainer>
                <DefaultBlock>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text pb={6} weight={600}>
                      Следите за курсами
                      <br />
                      приема лекарств
                    </Text>
                    <Text size={14} color="#787D93">
                      Добавить лекарство
                    </Text>
                  </div>
                  <div>
                    <img src={addPath} alt="add" />
                  </div>
                </DefaultBlock>
              </>
            )}

            {courses.length > 1 && (
              <PillCardContainer>
                {courses.map((course) => (
                  <CourseCard course={course} onOpenCourse={onOpenCourse} />
                ))}
                <DefaultBlock>
                  <div></div>
                  <Link to="newpost">
                    <img src={addPath} alt="add" />
                  </Link>
                </DefaultBlock>
              </PillCardContainer>
            )}
          </Tabs>
        </AppContainer>
      )}

      {currentCourse && <CourseItem course={currentCourse} onClose={onClose} />}
    </>
  );
};
