import { styled } from 'styled-components';
import { COLORS } from '../../../const';

export const BlogWrapper = styled.div`
  margin-top: 16px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const LogoContainer = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
`;

export const Tag = styled.div.withConfig({
  shouldForwardProp: (p) => !['green'].includes(p),
})<{
  green?: boolean;
}>`
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.green ? `${COLORS.main_green}` : '#cbcedf')};
  border-radius: 24px;
  color: ${(props) => (props.green ? '#35373F' : '#787d93')};
  font-size: 12px;
  cursor: pointer;
`;

export const SmallTagsWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const SmallTag = styled.div`
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.main_grey_light};
  border-radius: 12px;
  color: ${COLORS.text_dark};
  font-size: 16px;
`;

export const PostCardContainer = styled.div`
  max-width: 800px;
  margin-bottom: 32px;
  padding: 16px 32px 32px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: ${COLORS.main_white};
`;

export const PostCardVideo = styled.div.withConfig({
  shouldForwardProp: (p) => !['big'].includes(p),
})<{
  big?: boolean;
}>`
  width: ${(props) => (props.big ? '1000px' : '800px')};
  max-height: ${(props) => (props.big ? '890px' : '726px')};
  margin-bottom: 32px;
  padding: 0;
  display: flex;
  border-radius: 16px;
  background-color: ${COLORS.main_white};

  @media screen and (max-width: 768px) {
    width: 100%;
    max-height: fit-content;
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const PostContent = styled.div`
  width: 50%;
  padding: 16px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  width: 50%;
  display: flex;
  align-items: center;
  background: #000;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  @media screen and (max-width: 768px) {
    width: 60%;
  }
`;

export const MuteButton = styled.button`
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 20;
`;

export const PostHeader = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PostHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PostCardImg = styled.div.withConfig({
  shouldForwardProp: (p) => !['size'].includes(p),
})<{
  acpect: number;
}>`
  min-width: 736px;
  height: ${(props) => `calc(${props.acpect} * 800px)`};
  display: flex;
  scroll-snap-align: center;

  & img {
    width: 100%;
    object-fit: cover;
  }
`;

export const PostDescription = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  & p,
  span {
    font-size: 14px !important;
    line-height: 20px !important;
  }
`;

export const PostControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PostImgContainer = styled.div.withConfig({
  shouldForwardProp: (p) => !['size'].includes(p),
})<{
  acpect: number;
}>`
  width: 736px;
  height: ${(props) => `calc(${props.acpect} * 800px)`};
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-padding-left: 0;
  scroll-snap-type: x mandatory;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const PostBack = styled.button`
  margin: 16px 0;
  display: flex;
  gap: 32px;
`;

export const PostDots = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  gap: 12px;
`;

export const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #babdcc;
`;
