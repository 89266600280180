import { styled } from 'styled-components';

export const OptionWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 450px) {
    margin-top: 12px;
  }
`;

export const OptionText = styled.label`
  width: 90%;
  margin-left: 12px;
  font-size: 24px;
  font-weight: 400;

  @media screen and (max-width: 450px) {
    font-size: 14px;
  }
`;

export const DescriptionBlock = styled.div`
  margin-top: 16px;
  margin-left: 4px;
  display: flex;
  align-items: center;

  & span {
    width: 450px;
    margin-left: 12px;

    @media screen and (max-width: 450px) {
      font-size: 12px;
    }
  }
`;
