import React from 'react';

export const BackIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M7.24991 12C7.24897 11.8009 7.32827 11.6099 7.46991 11.47L15.4699 3.46997C15.7654 3.19461 16.2259 3.20274 16.5115 3.48835C16.7971 3.77396 16.8053 4.23446 16.5299 4.52997L9.05991 12L16.5299 19.47C16.8053 19.7655 16.7971 20.226 16.5115 20.5116C16.2259 20.7972 15.7654 20.8053 15.4699 20.53L7.46991 12.53C7.32827 12.3901 7.24897 12.199 7.24991 12Z"
      fill="black"
    />
  </svg>
);
