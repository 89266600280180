import React, { useMemo, useState } from 'react';
import Calendar from 'react-calendar';
import { Value } from 'react-calendar/dist/cjs/shared/types';
import { useParams, Link } from 'react-router-dom';
import { AppContainer, Text } from '../../../../components';
import { TakingData } from './TakingData';
import { DefaultBlock, Touch, AddButton } from './styled';
import './calendar.css';

import addPath from '../../../../assets/add.svg';

const currentDate = new Date();

export const CalendarPage = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [isOpened, setIsOpened] = useState(false);

  const currentMounth = () => {
    const mounth = currentDate.toLocaleString('ru', { month: 'long' });
    return mounth.charAt(0).toUpperCase() + mounth.slice(1);
  };

  const displayedDate = useMemo(() => {
    const weekday = selectedDate.toLocaleString('ru', { weekday: 'long' });
    const day = selectedDate.getDate();
    const mounth = selectedDate.toLocaleString('ru', { month: 'long' });

    return `${weekday.charAt(0).toUpperCase() + weekday.slice(1)}, ${day} ${mounth}`;
  }, [selectedDate]);

  const { id } = useParams();
  console.log(id);

  const onSelectDate = (value: Value) => {
    setSelectedDate(value as Date);
  };

  const onToggleBlock = () => {
    console.log('up');
    setIsOpened((prev) => !prev);
  };

  return (
    <AppContainer title={currentMounth().toString()}>
      <Calendar
        value={currentDate}
        showNeighboringMonth={false}
        tileClassName={'tile--green'}
        onChange={onSelectDate}
      />
      <DefaultBlock isOpen={isOpened} onClick={onToggleBlock}>
        <Touch />
        <div style={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
          <Text pb={4} size={14} color="#959AB4">
            {selectedDate.getDate() === currentDate.getDate() ? 'Сегодня' : 'День'}
          </Text>
          <Text pb={isOpened ? 18 : 0} weight={600}>
            {displayedDate}
          </Text>
        </div>

        {isOpened && <TakingData />}

        <AddButton>
          <Link to={'/profile'}>
            <img src={addPath} alt="add" />
          </Link>
        </AddButton>
      </DefaultBlock>
    </AppContainer>
  );
};
