import React, { ChangeEvent, FC } from 'react';
import { DiagnosisType } from '../../../../types';
import { Button } from '../../../../components';
import { CheckIcon } from '../../../../components/Icons';
import { DiagnosisWrapper, Diagnosis, DiagnosisCheck } from './styled';

type DiagnosisBlockType = {
  diagnosises: DiagnosisType[];
  onSelectDiagnosis: (selected: DiagnosisType) => void;
  onSend: () => Promise<void>;
  checkedDiagnosis?: DiagnosisType;
};

export const DiagnosisBlock: FC<DiagnosisBlockType> = ({
  diagnosises,
  checkedDiagnosis,
  onSelectDiagnosis,
  onSend,
}) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    diagnosises.forEach((d) => {
      d.is_selected = false;
    });
    const currentDiagnosis = diagnosises.find((d) => d.title === value);
    if (currentDiagnosis) {
      currentDiagnosis.is_selected = true;
      onSelectDiagnosis(currentDiagnosis);
    }
  };

  return (
    <DiagnosisWrapper>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '6px' }}>
        {diagnosises.map((diagnosis) => (
          <Diagnosis key={diagnosis.title} checked={checkedDiagnosis?.title === diagnosis.title}>
            {diagnosis.is_selected && <CheckIcon size="s" />}
            {!diagnosis.is_selected && (
              <DiagnosisCheck type="checkbox" value={diagnosis.title} onChange={onChange} />
            )}
            <span>{diagnosis.title}</span>
          </Diagnosis>
        ))}
      </div>

      <div>
        <Button size="small" onClick={onSend}>
          {checkedDiagnosis ? 'Отправить' : 'Закрыть'}
        </Button>
      </div>
    </DiagnosisWrapper>
  );
};
