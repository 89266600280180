import { styled, keyframes } from 'styled-components';

export const DefaultBlock = styled.div.withConfig({
  shouldForwardProp: (p) => !['isOpen'].includes(p),
})<{
  isOpen?: boolean;
}>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 83px;
  min-height: ${(prosp) => (prosp.isOpen ? '500px' : '100px')};
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 16px 16px 0px 0px;
  background-color: #f5f5f5;
`;

export const Touch = styled.div`
  position: absolute;
  top: 4px;
  left: calc(50% - 16px);
  width: 32px;
  height: 4px;
  border-radius: 10px;
  background-color: #cbcedf;
`;

export const AddButton = styled.div`
  position: absolute;
  right: 16px;
  bottom: 16px;
`;

export const TimeBlock = styled.div`
  padding: 8px 0;
  display: flex;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid #edeff5;
`;

export const TakingBlock = styled(TimeBlock)`
  min-width: 100%;
  justify-content: space-between;
`;

export const IconContainer = styled.div`
  min-height: 47px;
  display: flex;
  align-items: center;
`;

const Swipe = keyframes`
    from  {right: -20%}
    to {left: 20px;}
`;

export const Wiper = styled.div`
  position: absolute;
  right: -40px;
  width: 100%;
  height: 80px;
  background: #012d48;
  animation: ${Swipe} 0.2s linear;
`;

export const WiperPath = styled.div`
  position: absolute;
  top: 0;
  right: -20px;
  width: 10px;
  height: 80px;
  background: #012d48;
`;
