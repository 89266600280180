import React, { FC, useEffect, ChangeEvent, useState } from 'react';
import { QuestionType, OptionType } from '../../types';
import { Container, Wrapper, QuestionText, Input } from './styled';

type InputBlockProps = {
  questions: QuestionType[];
  saveAnswer: (question: QuestionType, value: OptionType[] | string) => void;
};

type InputValues = {
  [key: string]: string;
};

export const InputBlock: FC<InputBlockProps> = ({ questions, saveAnswer }) => {
  const [value, setValue] = useState<InputValues>({ Рост: '', Вес: '', ИМТ: '' });
  const [imt, setImt] = useState<string>('');

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const questionId = e.target.name;
    const currentQuestion = questions.find((q) => q.title === questionId);
    let currentValue = e.target.value.replace(/[^\d]/g, '');
    setValue({ ...value, [questionId]: currentValue });

    if (currentQuestion) {
      saveAnswer(currentQuestion, currentValue);
    }
  };

  useEffect(() => {
    if (value['Рост'] && value['Вес']) {
      const imtValue: string = (
        Number(value['Вес']) /
        ((Number(value['Рост']) * Number(value['Рост'])) / 10000)
      ).toFixed(2);
      setImt(imtValue);
      const currentQuestion = questions.find((q) => q.title === 'ИМТ');
      if (currentQuestion) {
        saveAnswer(currentQuestion, imtValue);
      } else {
        setImt('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Container>
      {questions.map((question) => (
        <Wrapper key={question.id}>
          <QuestionText>{question.title}</QuestionText>
          <Input
            name={question.title}
            type="text"
            value={question.title === 'ИМТ' ? imt : value[question.title]}
            onChange={onInputChange}
            placeholder={''}
          />
        </Wrapper>
      ))}
    </Container>
  );
};
