import { styled } from 'styled-components';
import { COLORS } from '../../const';

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${COLORS.main_background};
  overflow: hidden;
`;

export const Wrapper = styled.div.withConfig({
  shouldForwardProp: (p) => !['big'].includes(p),
})<{
  big?: boolean;
}>`
  width: 100%;
  max-width: ${(props) => (props.big ? '1000px' : '800px')};
  max-height: 100vh;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.main_background};
  color: ${COLORS.text_dark};
  overflow: hidden;
`;

export const TitleWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledPanel = styled.div`
  position: fixed;
  bottom: 0;
  height: 83px;
  width: 100%;
  max-width: 450px;
  padding: 10px 44px 44px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: ${COLORS.main_white};
`;

export const Aside = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 243px;
  min-height: 100vh;
  padding: 33px;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.main_white};
`;

export const Tab = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

export const TabsWrapper = styled.div`
  margin-top: 23px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const BackButton = styled.button`
  position: absolute;
  top: 70px;
  left: 8px;

  @media screen and (min-width: 1024px) {
    top: 70px;
    left: 260px;
  }
`;
