import React, { SyntheticEvent } from 'react';
import { PROVIDES } from '../../types';
import { API } from '../../const';
import { Text } from '../../components';
import { SocialAuthContainer, SocialIconsWrapper } from './styled';

import googleImgPath from '../../assets/icons/google.svg';
import vkImgPath from '../../assets/icons/vk.svg';
import yandexImgPath from '../../assets/icons/yandex.svg';
import appleImgPath from '../../assets/icons/apple.svg';

export const SocialAuth = () => {
  const authenticate = async (provider: PROVIDES) => {
    const link = `${API}/auth/${provider}/authenticate`;
    window.open(link, '_self');
  };

  const onAuth = (e: SyntheticEvent<HTMLButtonElement>) => {
    const provider = e.currentTarget.id;
    authenticate(provider as PROVIDES);
  };

  return (
    <SocialAuthContainer>
      <Text pt={12} size={14} textAlign="center">
        Войти через:
      </Text>

      <SocialIconsWrapper>
        <button id={PROVIDES.APPLE} onClick={onAuth}>
          <img src={appleImgPath} alt="apple" />
        </button>
        <button id={PROVIDES.GOOGLE} onClick={onAuth}>
          <img src={googleImgPath} alt="google" />
        </button>
        <button id={PROVIDES.VK} onClick={onAuth}>
          <img src={vkImgPath} alt="vk" />
        </button>
        <button id={PROVIDES.YANDEX} onClick={onAuth}>
          <img src={yandexImgPath} alt="yandex" />
        </button>
      </SocialIconsWrapper>
    </SocialAuthContainer>
  );
};
