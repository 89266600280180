import { styled } from 'styled-components';
import { COLORS } from '../../const';

export const Container = styled.div`
  width: 100%;
  display: flex;

  & div + div {
    margin-left: 6px;

    @media screen and (max-width: 450px) {
      margin-left: 2px;
    }
  }

  @media screen and (max-width: 450px) {
    width: 100%;
    margin-bottom: 12px;
  }
`;

export const Cell = styled.div.withConfig({
  shouldForwardProp: (p) => !['active'].includes(p),
})<{
  active?: boolean;
}>`
  width: 100%;
  height: 4px;
  background-color: ${(props) => (props.active ? `${COLORS.main_green}` : `${COLORS.main_grey}`)};
  border-radius: 4px;
`;
