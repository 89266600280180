import React from 'react';
import { COLORS } from '../../../const';

export const CloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.8461 10.2752C22.4319 9.68944 22.4319 8.7397 21.8461 8.15391C21.2603 7.56812 20.3106 7.56812 19.7248 8.15391L15 12.8787L10.2753 8.15392C9.68947 7.56813 8.73973 7.56813 8.15394 8.15392C7.56815 8.73971 7.56815 9.68945 8.15394 10.2752L12.8787 15L8.15394 19.7247C7.56815 20.3105 7.56815 21.2603 8.15394 21.8461C8.73973 22.4319 9.68947 22.4319 10.2753 21.8461L15 17.1213L19.7248 21.8461C20.3106 22.4319 21.2603 22.4319 21.8461 21.8461C22.4319 21.2603 22.4319 20.3105 21.8461 19.7248L17.1213 15L21.8461 10.2752Z"
      fill={COLORS.text_dark}
    />
  </svg>
);
