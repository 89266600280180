import { styled } from 'styled-components';
import { COLORS } from '../../const';

export const Container = styled.div`
  width: 654px;
  margin-bottom: 24px;
  padding: 32px;
  display: flex;
  background-color: #edeef0;
  border-radius: 24px;

  @media screen and (max-width: 450px) {
    width: 100%;
    margin-bottom: 12px;
    padding: 16px;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 12px;
  }
`;

export const Wrapper = styled.div`
  flex-basis: 33%;
  display: flex;
  align-items: center;

  & + & {
    margin-left: 40px;
  }

  @media screen and (max-width: 450px) {
    & + & {
      margin-left: 0px;
    }
  }
`;

export const QuestionText = styled.span`
  margin-right: 12px;
  font-size: 28px;
  font-weight: 700;

  @media screen and (max-width: 450px) {
    font-size: 16px;
    font-weight: 600;
  }
`;

export const Input = styled.input`
  width: 100%;
  min-width: 90px;
  padding: 12px;
  border: 2px solid ${COLORS.main_green};
  border-radius: 12px;
  font-size: 20px;

  &::placeholder {
    color: ${COLORS.main_grey1};
    font-size: 20px;
    font-family: 'Lato', sans-serif;
  }

  @media screen and (max-width: 450px) {
    min-width: 53px;
    padding: 6px;
    border: 1px solid ${COLORS.main_green};
    font-size: 14px;
  }
`;
