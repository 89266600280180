import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Carousel from 'react-multi-carousel';
import { DoctorType, PostType } from '../../../types';
import { Avatar, ProgressDots, Text, VideoJS } from '../../../components';
import {
  MuteButton,
  PostCardContainer,
  PostCardImg,
  PostCardVideo,
  PostContent,
  PostDescription,
  PostHeader,
  PostHeaderWrapper,
  SmallTag,
  SmallTagsWrapper,
  VideoContainer,
} from './styled';
import { COLORS, HOST, IS_LOCAL } from '../../../const';
import { formatDate } from '../../../utils';

import mutedIcon from '../../../assets/icons/muted.svg';
import unmutedIcon from '../../../assets/icons/unmuted.svg';

import 'react-multi-carousel/lib/styles.css';

type PostCardProps = {
  post: PostType;
  onClick: (e: SyntheticEvent<HTMLDivElement>) => void;
  onLiked: (e: SyntheticEvent<HTMLButtonElement>) => void;
  isLiked: boolean;
  doctor?: DoctorType;
};

export const PostCard: FC<PostCardProps> = ({ post, onClick, onLiked, isLiked, doctor }) => {
  const [muted, setMuted] = useState(true);
  const [currentImage, setCurrentImage] = useState(1);

  const playerRef = useRef<any>(null);

  const content = useMemo(() => {
    return post.content?.slice(0, 250) === post.content
      ? post.content
      : post.content?.slice(0, 250) + '...';
  }, [post]);

  const isVideoPost = useMemo(() => {
    return !!post.video;
  }, [post]);

  const imageHeight = useMemo(() => {
    if (post && post.images.length) {
      const acpect = post.images[0].resolution[1] / post.images[0].resolution[0];
      return acpect;
    }

    return 1;
  }, [post]);

  const handlePlayerReady = (player: any) => {
    playerRef.current = player;
  };

  const autoplayVideo = useCallback((element: any) => {
    const elementBounding = element.getBoundingClientRect();
    const elementTop = elementBounding.bottom;
    const elementBottom = elementBounding.top;

    const screenHeight = window.innerHeight;

    const scrollIsAboveElement = elementBottom > 0;
    const elementIsVisibleOnScreen = elementTop < screenHeight;

    if (elementIsVisibleOnScreen && scrollIsAboveElement) {
      playerRef.current.autoplay('muted');
    } else {
      element.pause();
    }
  }, []);

  const toggleMute = () => {
    playerRef.current.muted(!muted);
    setMuted((prev) => !prev);
  };

  useEffect(() => {
    const scrollable = document.querySelector('#scrollable');
    scrollable?.scrollTo({ top: 1, left: 0 });

    const checkIsVideo = () => {
      const video = document.querySelector(`#${post.alias}_html5_api`);
      if (video) {
        autoplayVideo(video);
      }
    };

    scrollable?.addEventListener('scroll', checkIsVideo, false);

    return () => scrollable?.removeEventListener('scroll', checkIsVideo);
  }, [autoplayVideo, post.alias]);

  if (isVideoPost) {
    const videoJsOptions = {
      id: post.alias,
      autoplay: false,
      controls: true,
      responsive: true,
      inactivityTimeout: 0,
      muted: false,
      aspectRatio: '9:16',
      controlBar: {
        children: [
          'playToggle',
          'playbackRateMenuButton',
          'durationDisplay',
          'timeDivider',
          'currentTimeDisplay',
          'progressControl',
          'remainingTimeDisplay',
          'fullscreenToggle',
        ],
      },
      sources: [
        {
          src: `${HOST}${post.video.src}`,
          type: 'video/mp4',
        },
      ],
    };

    return (
      <PostCardVideo>
        <PostContent id={post.alias} onClick={onClick}>
          <PostHeader>
            <PostHeaderWrapper>
              <Avatar size="large" path={doctor?.avatar || ''} isDoctor />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text size={18} lineHeight={21} weight={600}>
                  {post.created_by}
                </Text>
                {doctor?.subname && (
                  <Text size={18} lineHeight={21} color={COLORS.text_grey} pt={4}>
                    {doctor?.subname}
                  </Text>
                )}
              </div>
            </PostHeaderWrapper>
            <Text color={COLORS.text_grey}>{formatDate(post.created_at)}</Text>
          </PostHeader>

          <PostDescription>
            {/* <LikeButton postId={post.id} count={post.likes} onToggle={onLiked} isLiked={isLiked} /> */}
            <Text size={18} weight={600} lineHeight={18} pb={16}>
              {post.title}
            </Text>
            <div style={{ maxHeight: '454px', overflow: 'hidden' }}>
              <Text size={14} weight={400} lineHeight={20} color={COLORS.text_dark}>
                <p dangerouslySetInnerHTML={{ __html: post.content }} />
              </Text>
            </div>

            {!!post.tags.length && (
              <SmallTagsWrapper>
                {post.tags?.map((tag) => (
                  <SmallTag key={tag}>#{tag}</SmallTag>
                ))}
              </SmallTagsWrapper>
            )}
          </PostDescription>
        </PostContent>

        <VideoContainer>
          <MuteButton onClick={toggleMute}>
            <img src={muted ? mutedIcon : unmutedIcon} alt="mute" />
          </MuteButton>
          <VideoJS id={post.id} options={videoJsOptions} onReady={handlePlayerReady} />
        </VideoContainer>
      </PostCardVideo>
    );
  }

  return (
    <PostCardContainer>
      <PostHeader>
        <PostHeaderWrapper>
          <Avatar size="large" path={doctor?.avatar || ''} isDoctor />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text size={18} lineHeight={21} weight={600}>
              {post.created_by}
            </Text>
            {doctor?.subname && (
              <Text size={18} lineHeight={21} color={COLORS.text_grey} pt={4}>
                {doctor?.subname}
              </Text>
            )}
          </div>
        </PostHeaderWrapper>
        <Text color={COLORS.text_grey}>{formatDate(post.created_at)}</Text>
      </PostHeader>

      {post && !!post.images.length && (
        <Carousel
          swipeable={false}
          draggable={false}
          showDots={false}
          responsive={{
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 1,
              slidesToSlide: 1,
            },
          }}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={300}
          beforeChange={(previousSlide) => {
            setCurrentImage(previousSlide + 1);
          }}
        >
          {post.images.map((image) => (
            <PostCardImg key={image.src} acpect={imageHeight}>
              <img src={`${IS_LOCAL ? HOST : ''}${image.src}`} alt="post img" />
              {/* <img src={'/196341364175.64297.jpg'} alt="post img" /> */}
            </PostCardImg>
          ))}
        </Carousel>
      )}

      <PostDescription id={post.alias} onClick={onClick}>
        <ProgressDots all={post?.images.length || 0} current={currentImage} />
        {/* <LikeButton postId={post.id} count={post.likes} onToggle={onLiked} isLiked={isLiked} /> */}
        <Text size={18} weight={600} lineHeight={18} pb={16}>
          {post.title}
        </Text>
        <div style={{ maxHeight: '60px', overflow: 'hidden' }}>
          <Text size={14} weight={400} lineHeight={18} color={COLORS.text_dark}>
            <span dangerouslySetInnerHTML={{ __html: content }} />
          </Text>
        </div>

        {!!post.tags.length && (
          <SmallTagsWrapper>
            {post.tags?.map((tag) => (
              <SmallTag key={tag}>#{tag}</SmallTag>
            ))}
          </SmallTagsWrapper>
        )}
      </PostDescription>
    </PostCardContainer>
  );
};
