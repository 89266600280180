import React, { FC, PropsWithChildren, SyntheticEvent } from 'react';
import { Text } from '../Text';
import { ModalBackdrop, Dialog, DialogTitle, CloseButton, DialogContent } from './styled';

import closeIconPath from '../../assets/icons/close.svg';

type ModalProps = {
  size?: 'small' | 'large';
  open: boolean;
  title?: string;
  showCloseButton?: boolean;
  onClose?: () => void;
};

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  size = 'large',
  open,
  title,
  showCloseButton,
  onClose,
  children,
}) => {
  const onCloseModal = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onClose) {
      onClose();
    }
  };

  if (open) {
    return (
      <ModalBackdrop onClick={(e) => e.stopPropagation()}>
        <Dialog size={size}>
          {title && (
            <DialogTitle>
              <Text weight={600}>{title}</Text>
            </DialogTitle>
          )}

          {showCloseButton && (
            <CloseButton onClick={onCloseModal}>
              <img src={closeIconPath} alt="close" />
            </CloseButton>
          )}

          <DialogContent>{children}</DialogContent>
        </Dialog>
      </ModalBackdrop>
    );
  }

  return null;
};
