import React, { FC, ChangeEvent, useCallback } from 'react';
import { COLORS } from '../../const';
import { Text } from '../Text';
import { Container, StyledInput, Wrapper } from './styled';

import arrowPath from '../../assets/icons/arrow.svg';

type InputProps = {
  name: string;
  value: string;
  onChange: (name: string, value: string) => void;
  label?: string;
  multiline?: boolean;
  error?: boolean;
  errorMessage?: string;
  needValidate?: boolean;
  onBlur?: (name: string, value: string) => void;
  disabled?: boolean;
  type?: string;
};

export const AppInput: FC<InputProps> = ({
  name,
  value,
  onChange,
  label,
  needValidate = false,
  onBlur,
  error,
  errorMessage,
  type = 'text',
}) => {
  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      console.log(name, value);
      onChange(name, value);
    },
    [onChange]
  );

  console.log(name, value);

  const onInputBlur = () => {
    if (needValidate) {
    }
    if (onBlur) {
      onBlur(name, value);
    }
  };

  return (
    <Container>
      <Wrapper>
        {!!value && (label || error) && (
          <Text color={error ? '#e74d3f' : COLORS.text_grey} size={12} weight={500} pb={4}>
            {error ? errorMessage : label}
          </Text>
        )}

        <StyledInput
          type={type}
          name={name}
          value={value}
          onChange={onInputChange}
          onBlur={onInputBlur}
          placeholder={label}
          error={error}
        />
      </Wrapper>

      <img src={arrowPath} alt="arrow" />
    </Container>
  );
};
