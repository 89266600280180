import React, { useState, useMemo, useEffect, ChangeEvent } from 'react';
import { useNavigate, useSearchParams, useMatches } from 'react-router-dom';
import { AUTH_FIELDS, AUTH_MATCH_TYPES, InputValuesType } from '../../types';
import { PageContainer, Text, Modal, AppButton } from '../../components';
import { API } from '../../const';
import { Auth } from './Auth';
import { Wrapper, StyledButton, Container, StyledInput } from './styled';
import { authProvider } from '../../utils';
import { SocialAuth } from './SocialAuth';

export const AppAuthPage = () => {
  const [recoverIsOpen, setRecoverIsOpen] = useState(false);
  const [isRecovered, setIsRecovered] = useState(false);
  const [email, setEmail] = useState('');

  const [error, setError] = useState<boolean | string>(false);
  const [values, setValues] = useState<InputValuesType>({
    [AUTH_FIELDS.USERNAME]: '',
    [AUTH_FIELDS.PASSWORD]: '',
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const matches = useMatches();

  const { login } = authProvider();

  const onLogin = async () => {
    try {
      const response = await fetch(`${API}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (response.status === 200) {
        const token = await response.json();
        login(token);
        if (navigator.userAgent.includes('iPhone')) {
          window.location.replace(`doctorly://auth/result?token=${token}`);
          setTimeout(() => {
            navigate(`/auth/result?token=${token}`);
          }, 1000);
        } else {
          navigate('/profile');
        }
      }

      if (response.status === 401) {
        setError('Неправильный пароль');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onConfirm = async (token: string) => {
    try {
      await fetch(`${API}/auth/confirm?token=${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onRecover = async () => {
    try {
      await fetch(`${API}/auth/get_password_recovery?email=${email}`, {
        method: 'GET',
      });
      setIsRecovered(true);
    } catch (e) {
      console.log(e);
    }
  };

  const isDisabled = useMemo(() => {
    return Object.values(values).some((value) => !value);
  }, [values]);

  const goToRegistation = () => {
    navigate('/registration');
  };

  const onRecoverPassword = () => {
    setRecoverIsOpen(true);
  };

  const closeRecoveryModal = () => {
    setRecoverIsOpen(false);
    setIsRecovered(false);
    setEmail('');
  };

  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
  };

  const onChangeValue = (name: string, value: string) => {
    setError(false);
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    const isConfirm = matches.some((match) => match.id === AUTH_MATCH_TYPES.CONFIRM);
    let token: string | null = null;

    if (searchParams.has('token')) {
      token = searchParams.get('token');
    }

    setSearchParams('');

    if (isConfirm && token) {
      onConfirm(token);
      navigate('/auth');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    const isResult = matches.some((match) => match.id === AUTH_MATCH_TYPES.RESULTS);
    let token: string | null = null;

    if (searchParams.has('token')) {
      token = searchParams.get('token');
    }

    setSearchParams('');

    if (isResult && token) {
      navigate('/profile');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <PageContainer isFull={true}>
      <Container>
        <Wrapper>
          <Text size={16} weight={600} textAlign="center">
            Вход/Регистрация
          </Text>

          <Auth error={error} values={values} onChange={onChangeValue} />

          <Text pt={16} size={14} textAlign="center" onClick={onRecoverPassword}>
            Забыли пароль?
          </Text>

          <StyledButton onClick={onLogin} disabled={isDisabled}>
            Войти
          </StyledButton>

          <SocialAuth />
        </Wrapper>

        <Wrapper>
          <Text pt={16} size={14} textAlign="center">
            Новый пользователь?
          </Text>

          <StyledButton onClick={goToRegistation}>Зарегистироваться</StyledButton>
        </Wrapper>
      </Container>

      <Modal
        open={recoverIsOpen}
        showCloseButton
        onClose={closeRecoveryModal}
        size="small"
        title="Забыли пароль?"
      >
        {isRecovered && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
            <Text size={14} lineHeight={21} textAlign="center">
              Письмо отправлено на указанную почту. Все инструкции будут доступны в письме.
            </Text>

            <AppButton title="Понятно" bold onClick={closeRecoveryModal} />
          </div>
        )}

        {!isRecovered && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
            <Text size={14} lineHeight={21} textAlign="center">
              Введите адрес электронной почты, на которую регистрировали аккаунт. Мы вышлем
              инструкции для смены пароля на указанную почту.
            </Text>

            <StyledInput
              name={AUTH_FIELDS.USERNAME}
              value={email}
              placeholder="Электронная почта"
              onChange={onChangeEmail}
            />

            <AppButton title="Отправить" bold onClick={onRecover} disabled={!email} />
          </div>
        )}
      </Modal>
    </PageContainer>
  );
};
