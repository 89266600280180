import React, { FC, useEffect, useState } from 'react';
import { RegimentsType, TakingType } from '../../../../types';
import { SelectOptions, Text, AppButton } from '../../../../components';
import { AddTakingBlock, ButtonWrapper } from './styled';

import addPath from '../../../../assets/icons/add.svg';

export type StepProps = {
  values: RegimentsType;
  onChange: (value: TakingType[]) => void;
  onNextStep: () => void;
  onPrevStep?: () => void;
};

export const SecondStep: FC<StepProps> = ({ values, onChange, onNextStep, onPrevStep }) => {
  const [takingCount, setTakingCount] = useState<number[]>([1]);
  const [takingTimes, setTakingTimes] = useState<string[]>([]);

  const onSelectTime = (name: string, value: string) => {
    setTakingTimes((prev) => [...prev, value]);
  };

  const renderTaking = (count: number) => {
    return (
      <SelectOptions
        type="time"
        name="taking_drugs"
        title={`Прием ${count}`}
        onChange={onSelectTime}
      />
    );
  };

  const onAdd = () => {
    setTakingCount((prev) => [...prev, 1]);
  };

  useEffect(() => {
    onChange(takingTimes.map((time) => ({ time: time })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [takingTimes]);

  return (
    <div style={{ marginTop: '20px' }}>
      {takingCount.map((_, index) => renderTaking(index + 1))}

      <AddTakingBlock>
        <Text weight={600} color="#76AC98">
          Еще
        </Text>
        <button onClick={onAdd}>
          <img src={addPath} alt="add" />
        </button>
      </AddTakingBlock>

      <ButtonWrapper>
        <AppButton title="Назад" onClick={onPrevStep} />
        <AppButton title="Далее" onClick={onNextStep} disabled={!takingTimes.length} />
      </ButtonWrapper>
    </div>
  );
};
