import React, { useState, useEffect, SyntheticEvent } from 'react';
import { ResultType, ResultTypeWithIndex, SURVEYS_TYPES, TabsType } from '../../../types';
import { API, TABS } from '../../../const';
import { authProvider, formatIndex } from '../../../utils';
import { AppContainer, Table, Text, Tabs } from '../../../components';
import { DiagnosisPage } from './DiagnosisPage';
import { SurveyTab, TabList } from './styled';

const tabs: TabsType = {
  open: 'Открытые',
  close: 'Закрытые',
};

export const CabinetPage = () => {
  const [results, setResults] = useState<ResultType[]>([]);

  const [currentResult, setCurrentResult] = useState<ResultType>();
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const [tabResults, setTabResults] = useState<ResultTypeWithIndex[]>([]);
  const [currentSurvey, setCurrentSurvey] = useState<SURVEYS_TYPES>();
  const [currentTab, setCurrentTab] = useState<string>('open');

  const { token } = authProvider();

  const onSurveyChange = (e: SyntheticEvent<HTMLDivElement>) => {
    const id = (e.currentTarget as Element).id;
    setCurrentSurvey(id as SURVEYS_TYPES);

    getTabResults();
  };

  const getTabResults = () => {
    const currentTabResults = results?.filter((result) => result.type === currentSurvey);
    (currentTabResults as ResultTypeWithIndex[]).forEach(
      (result, index) => (result.resultIndex = formatIndex(index + 1))
    );
    const data = currentTabResults.filter((result) => result.status === currentTab);
    setTabResults(data as ResultTypeWithIndex[]);
  };

  const getResults = async () => {
    try {
      const response = await fetch(`${API}/responses`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const data = await response.json();
        setResults(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const openFullResult = (id: string) => {
    const result = results.find((res) => res.id === id);
    if (result) {
      const index = results.indexOf(result);
      setCurrentIndex(index + 1);
    }
    setCurrentResult(result);
  };

  const closeFullResult = () => {
    setCurrentResult(undefined);
    getResults();
  };

  const closeSurvey = () => {
    setCurrentSurvey(undefined);
  };

  const onTabChange = (id: string) => {
    setCurrentTab(id);
  };

  useEffect(() => {
    getResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (results) {
      getTabResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSurvey, results, currentTab]);

  if (currentResult) {
    return <DiagnosisPage result={currentResult} index={currentIndex} onClose={closeFullResult} />;
  }

  return (
    <AppContainer
      title={currentSurvey ? `${TABS[currentSurvey]} опрос` : 'Проверка опросов'}
      backAction={currentSurvey && closeSurvey}
    >
      {!currentSurvey && (
        <TabList>
          {Object.keys(TABS).map((name) => (
            <SurveyTab key={name} id={name} onClick={onSurveyChange}>
              <Text weight={600}>{TABS[name]}</Text>
              <Text color="#787D93">Вопросы</Text>
            </SurveyTab>
          ))}
        </TabList>
      )}

      {currentSurvey && (
        <Tabs tabs={tabs} onToggle={onTabChange} active={currentTab}>
          {tabResults.length === 0 && (
            <Text pt={18} pb={13} size={18} weight={700} textAlign="center">
              Нет данных
            </Text>
          )}
          {tabResults.length > 0 && <Table tableData={tabResults} onOpenResult={openFullResult} />}
        </Tabs>
      )}
    </AppContainer>
  );
};
