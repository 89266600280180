import React, { FC } from 'react';

import addBtn from '../../../assets/icons/add-button.svg';
import styled from 'styled-components';

type ButtonProps = {
  onClick: () => void;
  disabled?: boolean;
};

const Button = styled.button`
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const AddButton: FC<ButtonProps> = ({ onClick, disabled }) => {
  return (
    <Button onClick={onClick} disabled={disabled}>
      <img src={addBtn} alt="add" />
    </Button>
  );
};
