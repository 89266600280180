import { styled } from 'styled-components';
import { COLORS } from '../../const';

export const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  background-color: ${COLORS.main_background};
  border-bottom: 1px solid ${COLORS.main_grey};
  font-size: 14px;
`;

export const OptionsWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 0;
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  border-radius: 10px;
  z-index: 10;

  & div {
    padding: 8px;
  }
`;

export const SelectedOption = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
