import React, { useState } from 'react';
import { PILL_UNIT, TakingDataType } from '../../../../types';
import { Text, Modal } from '../../../../components';
import { getPillIcon } from '../../../../utils';
import { IconContainer, TimeBlock, TakingBlock, WiperPath } from './styled';

import morningPath from '../../../../assets/icons/morning.svg';
import dayPath from '../../../../assets/icons/day.svg';

const TAKING: TakingDataType[] = [
  {
    time: 8,
    type: 'morning',
    title: 'Sonovan',
  },
  {
    time: 16,
    type: 'day',
    title: 'Sonovan',
  },
];

export const TakingData = () => {
  const [isOpened, setIsOpened] = useState(false);

  const morningTakings = TAKING.filter((taking) => taking.type === 'morning');
  const dayTakings = TAKING.filter((taking) => taking.type === 'day');

  const onCloseModal = () => {
    setIsOpened(false);
  };

  return (
    <>
      {morningTakings.length > 0 && (
        <>
          <TimeBlock>
            <img src={morningPath} alt="time icon" />
            <Text color="#959AB4">Утро</Text>
          </TimeBlock>
        </>
      )}

      <div style={{ display: 'flex', position: 'relative' }}>
        <TakingBlock>
          <TimeBlock>
            <IconContainer>{getPillIcon(PILL_UNIT.PILL)}</IconContainer>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text pb={4} weight={600}>
                Название
              </Text>
              <Text size={12} color="#959AB4">
                556 мг
              </Text>
            </div>
          </TimeBlock>
          <div>
            <Text size={20} color="#959AB4">
              13:45
            </Text>
            <WiperPath />
          </div>
        </TakingBlock>
      </div>

      {dayTakings.length > 0 && (
        <>
          <TimeBlock>
            <img src={dayPath} alt="time icon" />
            <Text color="#959AB4">День</Text>
          </TimeBlock>
        </>
      )}

      <Modal title="Sonovan" size="small" open={isOpened} showCloseButton onClose={onCloseModal}>
        modal
      </Modal>
    </>
  );
};
