import { styled } from 'styled-components';
import { COLORS } from '../../const';

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 14px;
  background-color: ${COLORS.main_grey};
`;

export const Button = styled.div.withConfig({
  shouldForwardProp: (p) => !['isActive'].includes(p),
})<{
  isActive?: boolean;
}>`
  width: 50%;
  margin: 4px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  background-color: ${(props) => (props.isActive ? '#EDEEF0' : `${COLORS.main_grey}`)};
`;

export const Container = styled.div`
  margin-top: 16px;
`;
