import { styled } from 'styled-components';
import { COLORS } from '../../const';

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const InputIcon = styled.div`
  position: absolute;
  top: 17px;
  right: 10px;

  @media screen and (max-width: 450px) {
    top: 7px;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 14px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid ${COLORS.main_green};
  border-radius: 12px;
  background-color: #f5f5f5;
  font-size: 20px;
  font-family: 'Lato', sans-serif;
  line-height: 14px;

  &::placeholder {
    color: ${COLORS.main_grey1};
    font-size: 20px;
    font-family: 'Lato', sans-serif;

    @media screen and (max-width: 450px) {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 450px) {
    padding: 8px;
    border: 1px solid ${COLORS.main_green};
    font-size: 14px;
  }
`;

export const OptionsContainer = styled.div.withConfig({
  shouldForwardProp: (p) => !['place'].includes(p),
})<{
  place: 'top' | 'bottom';
}>`
  position: absolute;
  top: ${(props) => (props.place === 'top' ? '56px' : 'auto')};
  bottom: ${(props) => (props.place === 'top' ? 'auto' : '56px')};
  left: 15px;
  width: 562px;
  max-height: 410px;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  overflow-y: scroll;
  z-index: 3;
  filter: drop-shadow(0px 4px 19px rgba(0, 0, 0, 0.15));
  cursor: pointer;

  @media screen and (max-width: 450px) {
    left: 12px;
    width: 202px;
    top: ${(props) => (props.place === 'top' ? '36px' : 'auto')};
    bottom: ${(props) => (props.place === 'top' ? 'auto' : '36px')};
  }
`;

export const Option = styled.div.withConfig({
  shouldForwardProp: (p) => !['checked'].includes(p),
})<{
  checked?: boolean;
}>`
  padding: 8px 24px;
  display: flex;
  align-items: center;
  font-size: 20px;
  border-bottom: 1px solid ${COLORS.main_grey};
  background-color: ${(props) => (props.checked ? '#D4F0E1' : '#F5F5F5')};

  & span {
    margin-left: 10px;
  }

  @media screen and (max-width: 450px) {
    padding: 8px;
    font-size: 14px;
  }
`;
