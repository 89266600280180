import React, {
  FC,
  useState,
  useEffect,
  useRef,
  MouseEventHandler,
  SyntheticEvent,
  ChangeEvent,
} from 'react';
import { OPTIONS_TYPES, OptionType, QuestionType } from '../../types';
import { RadioButton } from '../Buttons';
import { SearchIcon } from '../Icons';
import { QuestionContainer } from '../QuestionContainer';
import { Input, OptionsContainer, Option, InputContainer, InputIcon } from './styled';

const regExp = /^[?!,.a-zA-Z\s]+$/;

type SelectBlockProps = {
  question: QuestionType;
  saveAnswer: (question: QuestionType, value: OptionType[] | string) => void;
  place: 'top' | 'bottom';
};

export const SelectBlock: FC<SelectBlockProps> = ({ question, saveAnswer, place }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [checked, setChecked] = useState<OptionType>();

  const [searchValue, setSearchValue] = useState<string>('');
  const [searchOptions, setSearchOptions] = useState<OptionType[]>([]);

  const selectRef = useRef<HTMLInputElement>(null);

  const onCheckAnswer = (e: SyntheticEvent<HTMLDivElement>) => {
    const id = (e.currentTarget as Element).id;
    const selected = question.options?.find((option) => option.id.toString() === id);
    setChecked(selected);
    if (selected) {
      saveAnswer(question, selected.answer);
    }
  };

  const onOpenOptions: MouseEventHandler<HTMLDivElement> = () => {
    setIsOpen((prev) => !prev);
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(undefined);
    const value = e.target.value.replace(regExp, '');
    setSearchValue(value);
    const filteredOptions = question.options.filter(
      (o) => o.answer.toLowerCase().search(value.toLowerCase()) !== -1
    );
    setSearchOptions(filteredOptions);
  };

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const { target } = event;
      if (target instanceof Node && !selectRef.current?.contains(target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {
    if (!searchValue) {
      setSearchOptions(question.options);
    }
  }, [question, searchValue]);

  return (
    <QuestionContainer question={question}>
      <div style={{ position: 'relative' }}>
        <InputContainer>
          <Input
            ref={selectRef}
            value={checked ? checked.answer : searchValue}
            onChange={onInputChange}
            onClick={onOpenOptions}
            placeholder="Выберите"
          />
          <InputIcon>
            <SearchIcon />
          </InputIcon>
        </InputContainer>
        {isOpen && (
          <OptionsContainer place={place}>
            {searchOptions.map((option) => (
              <Option
                key={option.id}
                id={option.id.toString()}
                onClick={onCheckAnswer}
                checked={checked?.id === option.id}
              >
                <RadioButton
                  variant={question.type as OPTIONS_TYPES}
                  option={option}
                  checked={checked?.id === option.id}
                />
                <span>{option.answer}</span>
              </Option>
            ))}
          </OptionsContainer>
        )}
      </div>
    </QuestionContainer>
  );
};
