import React, { FC, SyntheticEvent } from 'react';
import { Text } from '../../../../components';
import { CourseType } from '../../../../types';
import { formatTakingDates, getDiffDays, getPillIcon } from '../../../../utils';
import { PillCard, IconContainer, PillCardFooter } from './styled';

type PropsType = {
  course: CourseType;
  onOpenCourse: (id: string) => void;
};

export const CourseCard: FC<PropsType> = ({ course, onOpenCourse }) => {
  const onClick = (e: SyntheticEvent<HTMLDivElement>) => {
    const id = (e.currentTarget as Element).id;
    onOpenCourse(id);
  };

  return (
    <PillCard key={course.id} id={course.id} onClick={onClick}>
      <IconContainer>{getPillIcon(course.unit)}</IconContainer>
      <Text weight={600}>{course.drug_title}</Text>
      <Text size={10}>
        {formatTakingDates(course.date_start)} -{' '}
        {course.date_end ? formatTakingDates(course.date_end) : 'бессрочно'}
      </Text>

      <PillCardFooter>
        <div>
          <Text weight={600} color="#F5F5F5">
            {getDiffDays(course.date_start, new Date().toDateString())}/
            {getDiffDays(course.date_start, course.date_end)}
          </Text>
          <Text size={12} weight={600} color="#F5F5F5">
            день
          </Text>
        </div>
        <div>
          <Text weight={600} color="#F5F5F5">
            {course.mera}
          </Text>
          <Text size={12} weight={600} color="#F5F5F5">
            шт
          </Text>
        </div>
      </PillCardFooter>
    </PillCard>
  );
};
