import { StyleSheet } from '@react-pdf/renderer';
import { COLORS } from '../../const';

export const styles = StyleSheet.create({
  page: {
    paddingTop: 40,
    paddingBottom: 80,
    backgroundColor: '#fff',
    fontFamily: 'LatoRegular',
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: 1.25,
  },
  header: {
    paddingLeft: 40,
    paddingBottom: 40,
    paddingRight: 40,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logo: {
    width: 85,
    height: 23,
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: 7,
  },
  iconBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  semiboldText: {
    fontSize: 16,
    fontWeight: 'semibold',
  },
  title: {
    paddingLeft: 40,
    paddingBottom: 16,
    paddingRight: 40,
    fontSize: 20,
    fontWeight: 'bold',
  },

  rowWrapper: {
    margin: '0 40px',
    padding: '8px 0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid #E4E5EA',
  },
  rowNumber: {
    marginRight: 16,
    fontSize: 32,
    fontWeight: 'bold',
    color: `${COLORS.main_green}`,
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
  },
  rowQuestion: {
    paddingRight: 40,
    paddingBottom: 6,
  },
  rowSubrow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowSunrowFirst: {
    paddingBottom: 16,
  },
  rowSubrowPadding: {
    paddingRight: 21,
  },

  footer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    padding: '18px 40px 14px 40px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    backgroundColor: `${COLORS.main_green}`,
  },
  footerLogo: {
    marginTop: 6,
    width: 81,
    height: 22,
  },
  footerText: {
    color: '#F5F5F5',
  },
});
