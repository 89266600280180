import React, { FC } from 'react';
import { DAYTIME, PILL_UNIT } from '../../types';
import { DAYTIME_OPTIONS } from '../../const';
import { Text } from '../Text';
import { Wrapper } from './styled';
import { formatTime, getPillIcon, getTimeIcon } from '../../utils';

import mealPath from '../../assets/icons/meal.svg';

type PropsType = {
  time: string;
  unit: PILL_UNIT;
  mera: number;
  daytime: DAYTIME;
};

export const TakingDataCard: FC<PropsType> = ({ time, unit, mera, daytime }) => {
  return (
    <Wrapper>
      <div style={{ borderLeft: 'none' }}>
        {getTimeIcon(time)}
        <Text size={14}>{formatTime(time)}</Text>
      </div>
      <div>
        {getPillIcon(unit)}
        <Text size={14}>{mera} шт</Text>
      </div>
      <div>
        <img src={mealPath} alt="meal" />
        <Text size={14} noWrap={true}>
          {DAYTIME_OPTIONS[daytime]}
        </Text>
      </div>
    </Wrapper>
  );
};
