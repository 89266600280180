import React, { FC, useState } from 'react';
import { AppContainer } from '../../../../components';
import { API } from '../../../../const';
import { DiagnosisType, ResultType } from '../../../../types';
import { authProvider } from '../../../../utils';
import { AnswersBlock } from './AnswersBlock';
import { DiagnosisBlock } from './DiagnosisBlock';

type DiagnosisPageType = {
  result: ResultType;
  index: number;
  onClose: () => void;
};

export const DiagnosisPage: FC<DiagnosisPageType> = ({ result, index, onClose }) => {
  const [selected, setSelected] = useState<DiagnosisType>();

  const { token } = authProvider();

  const onSelectDiagnosis = (selected: DiagnosisType) => {
    setSelected(selected);
  };

  const sendDiagnosis = async () => {
    if (selected) {
      const data = result.diagnoses;
      try {
        await fetch(`${API}/responses?id=${result?.id}`, {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
        onClose();
      } catch (e) {
        console.log(e);
      }
    } else {
      onClose();
    }
  };

  return (
    <AppContainer title="Опросы" backAction={onClose}>
      <div style={{ overflow: 'scroll' }}>
        <AnswersBlock data={result} index={index} />
        <DiagnosisBlock
          diagnosises={result.diagnoses}
          checkedDiagnosis={selected}
          onSelectDiagnosis={onSelectDiagnosis}
          onSend={sendDiagnosis}
        />
      </div>
    </AppContainer>
  );
};
