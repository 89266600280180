import { styled } from 'styled-components';
import { COLORS } from '../../const';

export const TableWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  border-radius: 12px;
  font-size: 16px;
`;

export const StyledHeader = styled.div`
  width: 100%;
  padding: 10px 12px;
  display: flex;
  border-bottom: 1px solid ${COLORS.main_grey1};
  font-size: 16px;
  font-weight: 600;

  & div {
    flex-basis: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:first-of-type() {
    justify-content: flex-start;
  }
`;

export const StyledRow = styled(StyledHeader)`
  padding: 6px 12px;
  background-color: #edeef0;
  font-size: 14px;
  font-weight: 400;

  @media screen and (max-width: 1024px) {
    & div {
      flex-basis: 33%;
    }
  }
`;

export const Status = styled.div.withConfig({
  shouldForwardProp: (p) => !['status'].includes(p),
})<{
  status: 'open' | 'closed';
}>`
  padding: 3px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.status === 'open' ? '#D4F0E1' : '#E4E5EA')};
  border-radius: 48px;
`;

export const StyledButton = styled.button`
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, ${COLORS.main_green} 0%, #76ac98 100%);
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
`;
