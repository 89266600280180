import { styled } from 'styled-components';
import { COLORS } from '../../const';

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledInput = styled.input.withConfig({
  shouldForwardProp: (p) => !['error', 'wide'].includes(p),
})<{
  error?: boolean;
  wide?: boolean;
}>`
  -webkit-appearance: none;
  width: 100%;
  height: 34px;
  padding: 8px;
  margin-bottom: ${(props) => (props.wide ? '18px' : '0px')};
  background-color: #f5f5f5;
  border: ${(props) => (props.error ? '1px solid #E74D3F' : `1px solid ${COLORS.main_grey1}`)};
  border-radius: 8px;
  font-size: 14px;
  color: ${(props) => (props.error ? '#E74D3F' : '#000')};
`;
