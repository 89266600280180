import React, { FC, PropsWithChildren } from 'react';
import { styled } from 'styled-components';

type ContainerType = {
  isFull?: boolean;
};

const StyledContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-color: #edeff5;
  overflow: hidden;
`;

const Wrapper = styled.div.withConfig({
  shouldForwardProp: (p) => !['isFull'].includes(p),
})<{
  isFull?: boolean;
}>`
  width: 100%;
  padding-top: ${(props) => (props.isFull ? '0px' : '42px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const PageContainer: FC<PropsWithChildren<ContainerType>> = ({
  children,
  isFull = false,
}) => {
  return (
    <StyledContainer>
      <Wrapper isFull={isFull}>{children}</Wrapper>
    </StyledContainer>
  );
};
