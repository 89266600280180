import React from 'react';
import { StyledHeader } from './styled';

export const TableHeader = () => {
  return (
    <StyledHeader>
      <div style={{ justifyContent: 'flex-start' }}>№ опроса</div>
      <div>Дата заполнения</div>
      <div>Статус</div>
      <div></div>
    </StyledHeader>
  );
};
