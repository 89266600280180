import React, { FC } from 'react';
import { Cell, Container } from './styled';

type ProgressBarType = {
  count: number;
  active: number;
};

export const ProgressBar: FC<ProgressBarType> = ({ count, active }) => {
  const countArr = Array.from({ length: count }).map((v, i) => i + 1);

  return (
    <Container>
      <>
        {countArr.map((count, index) => (
          <Cell key={index} active={count <= active}></Cell>
        ))}
      </>
    </Container>
  );
};
