import React, { FC } from 'react';

type PostIconProps = {
  color: string;
};

export const PostIcon: FC<PostIconProps> = ({ color }) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="calendar 2">
      <g id="Icon">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.43714 17.5694L10.1962 14.0155C10.3067 13.5216 10.5556 13.0697 10.9136 12.7128L17.849 5.82382C18.5523 5.10572 19.5071 4.69072 20.5108 4.66698C21.2678 4.65611 21.9977 4.94876 22.5384 5.47989C23.7198 6.87901 23.5672 8.96836 22.1952 10.1802L15.2599 17.1317C14.9038 17.4905 14.4529 17.74 13.9602 17.8508L10.4145 18.6116H10.217C9.95777 18.6215 9.7102 18.5033 9.55456 18.2953C9.39892 18.0873 9.35504 17.816 9.43714 17.5694ZM12.0158 13.8279C11.8701 13.9694 11.7689 14.1506 11.7247 14.349L11.2152 16.7773L13.6379 16.2562C13.8358 16.2119 14.0166 16.1104 14.1577 15.9644L21.0931 9.01295C21.8528 8.40329 22.0036 7.30424 21.4362 6.51167C21.1878 6.27374 20.8542 6.14604 20.5108 6.15732C19.9187 6.18115 19.3592 6.43538 18.9511 6.86602L12.0158 13.8279Z"
          fill={color}
        />
        <path
          d="M22.0393 13.0463C21.6109 13.0519 21.265 13.3986 21.2594 13.8279V19.758C21.2734 20.5962 20.9509 21.4049 20.3644 22.0026C19.7779 22.6003 18.9765 22.9368 18.1401 22.9367H8.231C6.50223 22.9027 5.11698 21.4911 5.11166 19.758V9.86755C5.14004 8.13238 6.55162 6.74072 8.28299 6.74095H14.1993C14.63 6.74095 14.9792 6.391 14.9792 5.95931C14.9792 5.52761 14.63 5.17766 14.1993 5.17766H8.231C5.63831 5.1775 3.52849 7.26899 3.5 9.86755V19.758C3.5 22.377 5.61814 24.5 8.231 24.5H18.0985C20.7073 24.4943 22.8191 22.3729 22.8191 19.758V13.8279C22.8135 13.3986 22.4676 13.0519 22.0393 13.0463Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);
