import { styled, css } from 'styled-components';
import { COLORS } from '../../../../const';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const UnitsWrapper = styled.div`
  margin: 5px 0 20px 0;
  display: flex;
  gap: 10px;
`;

export const UnitBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UnitIcon = styled.div.withConfig({
  shouldForwardProp: (p) => !['active'].includes(p),
})<{
  active?: boolean;
}>`
  position: relative;
  width: 58px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${(props) => (props.active ? `2px solid ${COLORS.main_green}` : 'none')};
  border-radius: 50%;

  /* ${(props) =>
    props.active &&
    css`
      &::before {
        content: '+++',
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
      }
    `}; */
`;

export const Input = styled.input`
  width: 100%;
  margin: 3.5px 0;
  padding: 8px 0;
  background-color: ${COLORS.main_background};
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  border-bottom: 1px solid #cbcedf;

  &::placeholder {
    color: #959ab4;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
  }
`;

export const Label = styled.p`
  font-size: 10px;
  color: #959ab4;
`;

export const GapBlock = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 100px;
  width: 85%;
  height: 60px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background-color: ${COLORS.main_background};

  @media screen and (min-width: 980px) {
    width: 700px;
  }
`;

export const AddTakingBlock = styled.div`
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
`;

export const InfoBlock = styled.div`
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  background-color: ${COLORS.main_white};
`;
