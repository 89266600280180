import React, { FC } from 'react';
import { PillsIconsType } from './TabletIcon';
import { COLORS } from '../../../const';

export const CapsuleIcon: FC<PillsIconsType> = ({ isMissed }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none">
      <rect
        x="36.0625"
        y="11.3137"
        width="35"
        height="16"
        rx="8"
        transform="rotate(135 36.0625 11.3137)"
        fill={isMissed ? '#E48B83' : `${COLORS.main_green}`}
      />
      <path
        d="M24.0417 23.3346L16.9707 30.4057C13.8465 33.5299 8.78117 33.5299 5.65697 30.4057C2.53278 27.2815 2.53278 22.2161 5.65697 19.092L12.728 12.0209L24.0417 23.3346Z"
        fill={isMissed ? '#F0E0E1' : '#76AC98'}
      />
    </svg>
  );
};
