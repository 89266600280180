export enum OPTIONS_TYPES {
  SELECT = 'select',
  MULTISELECT = 'multiselect',
  TEXT = 'text',
  DECIMAL = 'decimal',
  PICKER = 'picker',
}

export type OptionType = {
  id: number;
  score: number;
  answer: string;
  flag: string;
};

export type QuestionType = {
  id: number;
  title: string;
  type: string;
  page: number;
  options: OptionType[];
  description?: string;
  picker_type?: string;
};

export type AnswerType = {
  question: QuestionType;
  answer_selected?: OptionType[];
  answer_body?: string;
};

export type DiagnosisType = {
  title: string;
  is_selected: boolean;
};

export type ResultType = {
  id: string;
  type: SURVEYS_TYPES;
  status: 'open' | 'closed';
  diagnoses: DiagnosisType[];
  created_at: string;
  answers: AnswerType[];
};

export type ResultTypeWithIndex = ResultType & { resultIndex: string };

export enum SOURCES {
  MED = 'med',
  FORCED = 'forced',
  CAMPAIN = 'campain',
}

export enum SURVEYS_TYPES {
  CARDIO = 'cardio',
  THERAPY = 'therapy',
}

export enum AUTH_TABS_TYPES {
  AUTH = 'auth',
  REG = 'reg',
}

export type TabsType = {
  [name: string]: string;
};

export type InputValuesType = {
  [name: string]: string;
};

export enum AUTH_FIELDS {
  USERNAME = 'username',
  PASSWORD = 'password',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  PATRONYMIC = 'patronymic',
  BIRTHDAY = 'birthday',
}

export enum PROVIDES {
  VK = 'vk',
  GOOGLE = 'google',
  YANDEX = 'yandex',
  APPLE = 'apple',
}

export enum AUTH_MATCH_TYPES {
  RESULTS = 'result',
  CONFIRM = 'confirm',
}

export type ProfileType = {
  username: string;
  first_name: string;
  last_name: string;
  patronymic: string;
  avatar: string;
  height: number;
  weight: number;
  age: number;
  is_temporary: boolean;
  is_manager: boolean;
  created_at: string;
};

export type SettingsType = {
  first_name: string;
  last_name: string;
  patronymic?: string;
  height?: number;
  weight?: number;
  age?: number;
};

export type ImageType = {
  src: string;
  resolution: number[];
};

export type VideoType = {
  src: string;
  duration: number;
  resolution: number[];
};

export type PostType = {
  id: string;
  title: string;
  alias: string;
  description: string;
  content: string;
  images: ImageType[];
  tags: string[];
  likes: number;
  is_published: boolean;
  created_at: string;
  created_by: string;
  created_by_id: string;
  updated_at: string;
  updated_by: string;
  video: VideoType;
};

export type DoctorType = {
  id: string;
  first_name: string;
  last_name: string;
  patronymic: string;
  subname: string;
  avatar: string;
  degree: string;
  license: string;
  workplace: string;
  experience: number;
  description: string;
};

export type NewPostType = {
  title: string;
  content: string;
  tags: string[];
};

export type Paginator = {
  total: number;
  page: number;
  posts: PostType[];
};

export type TabsButtonsType = {
  tab1: string;
  tab2: string;
};

export enum PILL_UNIT {
  PILL = 'pill',
  CAPSULE = 'capsule',
  INJECTION = 'injection',
}
export enum DAYTIME {
  BEFORE = 'before_meal',
  AFTER = 'after_meal',
  NO_MATTER = 'no_matter',
}

export type TakingType = {
  time: string;
  date_start?: string;
  date_end?: string;
  day_interval?: number;
};

export type RegimentsType = {
  drug_title: string;
  mera: number;
  unit: PILL_UNIT;
  daytime: DAYTIME;
  comment: string;
  taking_drugs: TakingType[];
};

export type CourseType = {
  id: string;
  drug_title: string;
  mera: number;
  unit: PILL_UNIT;
  daytime: DAYTIME;
  comment: string;
  date_start: string;
  date_end: string;
};

export type TakingTimeType = 'morning' | 'day' | 'evening';

export type TakingDataType = {
  time: number;
  type: TakingTimeType;
  title: string;
};

export type RootStackParamList = {
  auth: undefined;
  registration: undefined;
};
