import { PILL_UNIT } from '../types';
import { TabletIcon, CapsuleIcon, InjectionIcon } from '../components';

import morningPath from '../assets/icons/morning.svg';
import dayPath from '../assets/icons/day.svg';
import eveningPath from '../assets/icons/evening.svg';

export const getPillIcon = (type: PILL_UNIT) => {
  switch (type) {
    case PILL_UNIT.PILL:
      return <TabletIcon />;
    case PILL_UNIT.CAPSULE:
      return <CapsuleIcon />;
    default:
      return <InjectionIcon />;
  }
};

export const getTimeIcon = (time: string) => {
  const date = new Date(time);
  const hours = date.getHours();

  if (hours < 12) {
    return <img src={morningPath} alt="morning" />;
  }

  if (hours < 18) {
    return <img src={dayPath} alt="day" />;
  }

  return <img src={eveningPath} alt="evening" />;
};
