import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DAYTIME, PILL_UNIT, RegimentsType, TakingType } from '../../../../types';
import { AppContainer, CloseIcon, ProgressBar } from '../../../../components';
import { FirstStep } from './Step1';
import { SecondStep } from './Step2';
import { ThirdStep } from './Step3';

type TitlesType = {
  [key: number]: string;
};

const TITLES: TitlesType = {
  1: 'Добавить лекарство',
  2: 'Расписание',
  3: 'Длительность',
};

export const NewCoursePage = () => {
  const [values, setValues] = useState<RegimentsType>({
    drug_title: '',
    mera: 0,
    unit: PILL_UNIT.PILL,
    daytime: DAYTIME.NO_MATTER,
    comment: '',
    taking_drugs: [],
  });
  const [currentStep, setCurrentStep] = useState<number>(1);
  const navigate = useNavigate();

  const title = useMemo(() => {
    return TITLES[currentStep];
  }, [currentStep]);

  const onClose = () => {
    navigate('/profile/course');
  };

  const onChangeValues = (name: string, value: string | string[]) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const onSaveTakings = (value: TakingType[]) => {
    setValues((prev) => ({ ...prev, taking_drugs: value as TakingType[] }));
  };

  const onNextStep = () => {
    if (currentStep === 3) {
    } else {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const onPrevStep = () => {
    setCurrentStep((prev) => prev - 1);
  };

  console.log(values);

  return (
    <AppContainer title={title} icon={<CloseIcon />} action={onClose}>
      <ProgressBar count={3} active={currentStep} />

      {currentStep === 1 && (
        <FirstStep values={values} onChange={onChangeValues} onNextStep={onNextStep} />
      )}
      {currentStep === 2 && (
        <SecondStep
          values={values}
          onChange={onSaveTakings}
          onNextStep={onNextStep}
          onPrevStep={onPrevStep}
        />
      )}
      {currentStep === 3 && (
        <ThirdStep
          values={values}
          onChange={onSaveTakings}
          onNextStep={onNextStep}
          onPrevStep={onPrevStep}
        />
      )}
    </AppContainer>
  );
};
