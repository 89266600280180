import React, { FC } from 'react';
import { InputBlock, QuestionBlock, SelectBlock, TextareaBlock } from '../../../components';
import { AGE_OPTIONS, REGION_OPTIONS } from '../../../const';
import { OPTIONS_TYPES, QuestionType, OptionType, SURVEYS_TYPES } from '../../../types';

export type StepProps = {
  questions: QuestionType[];
  saveAnswer: (question: QuestionType, value: OptionType[] | string) => void;
  surveyType?: SURVEYS_TYPES;
};

export const FirstStep: FC<StepProps> = ({ questions, saveAnswer, surveyType }) => {
  const nameQuestion = questions[0];
  const inputQuestions = questions.slice(1, 4);

  const countryQuestion = questions.find((q) => q.picker_type === 'region');
  if (countryQuestion) {
    countryQuestion.options = REGION_OPTIONS;
  }

  const ageQuestion = questions.find(
    (q) =>
      (surveyType === SURVEYS_TYPES.CARDIO && q.id === 5) || (SURVEYS_TYPES.THERAPY && q.id === 33)
  );

  if (ageQuestion) {
    ageQuestion.options = AGE_OPTIONS;
  }

  const sexQuestion = questions.find((q) => q.type === OPTIONS_TYPES.SELECT);

  const workQuestion = questions.find((q) => q.id === 36);

  return (
    <>
      {nameQuestion && <TextareaBlock question={nameQuestion} saveAnswer={saveAnswer} />}
      <InputBlock questions={inputQuestions} saveAnswer={saveAnswer} />
      {ageQuestion && <SelectBlock question={ageQuestion} saveAnswer={saveAnswer} place="top" />}
      {sexQuestion && <QuestionBlock question={sexQuestion} saveAnswer={saveAnswer} />}
      {countryQuestion && (
        <SelectBlock question={countryQuestion} saveAnswer={saveAnswer} place="bottom" />
      )}
      {surveyType === SURVEYS_TYPES.THERAPY && workQuestion && (
        <TextareaBlock question={workQuestion} saveAnswer={saveAnswer} />
      )}
    </>
  );
};
