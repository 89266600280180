import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import { API, COLORS, HOST, IS_LOCAL } from '../../../const';
import {
  AppContainer,
  Text,
  LogoIcon,
  Avatar,
  BackIcon,
  VideoJS,
  Loader,
  ProgressDots,
} from '../../../components';
import { DoctorType, PostType } from '../../../types';
import {
  PostDescription,
  LogoContainer,
  PostCardContainer,
  SmallTagsWrapper,
  SmallTag,
  PostHeader,
  PostHeaderWrapper,
  PostCardImg,
  PostBack,
  PostCardVideo,
  VideoContainer,
  MuteButton,
  PostContent,
} from './styled';
import { authProvider, formatDate } from '../../../utils';

import mutedIcon from '../../../assets/icons/muted.svg';
import unmutedIcon from '../../../assets/icons/unmuted.svg';

import 'react-multi-carousel/lib/styles.css';

export const Post: FC = () => {
  const [post, setPost] = useState<PostType>();
  const [doctor, setDoctor] = useState<DoctorType>();
  const [muted, setMuted] = useState(true);
  const [loading, setLoading] = useState(true);

  const [currentImage, setCurrentImage] = useState(1);

  const params = useParams();
  const nav = useNavigate();
  const { isAuth } = authProvider();

  const playerRef = useRef<any>(null);

  //const dots = post ? [...Array(post.images.length)].map((_, i) => i) : [];

  const isVideoPost = useMemo(() => {
    return !!post?.video;
  }, [post]);

  const imageHeight = useMemo(() => {
    if (post && post.images.length) {
      const acpect = post.images[0].resolution[1] / post.images[0].resolution[0];
      return acpect;
    }

    return 1;
  }, [post]);

  const getDoctors = useCallback(async (doctorId: string) => {
    try {
      const response = await fetch(`${API}/doctors/${doctorId}`);
      if (response.status === 200) {
        const data: DoctorType = await response.json();
        setDoctor({ ...data, id: doctorId });
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const getPost = async () => {
    try {
      const response = await fetch(`${API}/posts/${params.id}`);
      if (response.status === 200) {
        const data = await response.json();
        setPost(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // const setLike = async (postId: string) => {
  //   try {
  //     const response = await fetch(`${API}/posts/${postId}/likes`, {
  //       method: 'POST',
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     if (response.status === 200) {
  //       getPost();
  //     }
  //   } catch (e) {}
  // };

  // const onLikeClick = (e: SyntheticEvent<HTMLButtonElement>) => {
  //   const id = e.currentTarget.id;
  //   if (isAuth()) {
  //     setLike(id);
  //   }
  // };

  const handlePlayerReady = (player: any) => {
    playerRef.current = player;
  };

  const toggleMute = () => {
    playerRef.current.muted(!muted);
    setMuted((prev) => !prev);
  };

  const onClose = () => {
    nav('/blog');
  };

  useEffect(() => {
    getPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (post) {
      const doctorId = post.created_by_id;
      getDoctors(doctorId);
    }
  }, [getDoctors, post]);

  if (loading) {
    return (
      <AppContainer title="" hidePanel={!isAuth()} big>
        <Loader />
      </AppContainer>
    );
  }

  if (isVideoPost) {
    const videoJsOptions = {
      id: post?.alias,
      autoplay: true,
      controls: true,
      muted: true,
      responsive: true,
      inactivityTimeout: 0,
      aspectRatio: '9:16',
      controlBar: {
        children: [
          'playToggle',
          'playbackRateMenuButton',
          'durationDisplay',
          'timeDivider',
          'currentTimeDisplay',
          'progressControl',
          'remainingTimeDisplay',
          'fullscreenToggle',
        ],
      },
      sources: [
        {
          src: `${IS_LOCAL ? HOST : ''}${post?.video.src}`,
          type: 'video/mp4',
        },
      ],
    };

    return (
      <AppContainer title="" hidePanel={!isAuth()} big>
        <LogoContainer>
          <LogoIcon />
        </LogoContainer>

        <PostCardVideo big>
          <PostContent>
            <PostBack onClick={onClose}>
              <BackIcon />
              <Text size={18} weight={600}>
                Публикация
              </Text>
            </PostBack>

            <PostHeader>
              <PostHeaderWrapper>
                <Avatar size="large" path={doctor?.avatar || ''} isDoctor />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text size={18} lineHeight={21} weight={600}>
                    {post?.created_by}
                  </Text>
                  {doctor?.subname && (
                    <Text size={18} lineHeight={21} color={COLORS.text_grey} pt={4}>
                      {doctor?.subname}
                    </Text>
                  )}
                </div>
              </PostHeaderWrapper>
              <Text color={COLORS.text_grey}>{formatDate(post?.created_at || '')}</Text>
            </PostHeader>

            <PostDescription>
              {/* <LikeButton postId={post.id} count={post.likes} onToggle={onLiked} isLiked={isLiked} /> */}
              <Text size={18} weight={600} lineHeight={18} pb={16}>
                {post?.title}
              </Text>
              <div>
                <Text size={14} weight={400} lineHeight={18} color={COLORS.text_dark}>
                  <span dangerouslySetInnerHTML={{ __html: post?.content || '' }} />
                </Text>
              </div>

              {!!post?.tags.length && (
                <SmallTagsWrapper>
                  {post.tags?.map((tag) => (
                    <SmallTag key={tag}>#{tag}</SmallTag>
                  ))}
                </SmallTagsWrapper>
              )}
            </PostDescription>
          </PostContent>

          <VideoContainer>
            <MuteButton onClick={toggleMute}>
              <img src={muted ? mutedIcon : unmutedIcon} alt="mute" />
            </MuteButton>
            <VideoJS id={post?.id || ''} options={videoJsOptions} onReady={handlePlayerReady} />
          </VideoContainer>
        </PostCardVideo>
      </AppContainer>
    );
  }

  return (
    <AppContainer title="" hidePanel={!isAuth()}>
      <div style={{ overflowY: 'scroll' }}>
        <LogoContainer>
          <LogoIcon />
        </LogoContainer>

        <PostCardContainer>
          <PostBack onClick={onClose}>
            <BackIcon />
            <Text size={18} weight={600}>
              Публикация
            </Text>
          </PostBack>
          <PostHeader>
            <PostHeaderWrapper>
              <Avatar size="large" path={doctor?.avatar || ''} isDoctor />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text size={18} lineHeight={21} weight={600}>
                  {post?.created_by}
                </Text>
                {doctor?.subname && (
                  <Text size={18} lineHeight={21} color={COLORS.text_grey} pt={4}>
                    {doctor?.subname}
                  </Text>
                )}
              </div>
            </PostHeaderWrapper>
            <Text color={COLORS.text_grey}>{formatDate(post?.created_at || '')}</Text>
          </PostHeader>

          {post && !!post.images.length && (
            <Carousel
              swipeable={false}
              draggable={false}
              showDots={false}
              responsive={{
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 1,
                  slidesToSlide: 1,
                },
              }}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={300}
              beforeChange={(previousSlide) => {
                setCurrentImage(previousSlide + 1);
              }}
            >
              {post.images.map((image) => (
                <PostCardImg key={image.src} acpect={imageHeight}>
                  <img src={`${IS_LOCAL ? HOST : ''}${image.src}`} alt="post img" />
                  {/* <img src={'/196341364175.64297.jpg'} alt="post img" /> */}
                </PostCardImg>
              ))}
            </Carousel>
          )}

          <PostDescription>
            <ProgressDots all={post?.images.length || 0} current={currentImage} />
            {/* <LikeButton postId={post.id} count={post.likes} onToggle={onLiked} isLiked={isLiked} /> */}
            <Text size={18} weight={600} lineHeight={18} pb={16}>
              {post?.title}
            </Text>
            <div>
              <Text size={14} weight={400} lineHeight={18} color={COLORS.text_dark}>
                <span dangerouslySetInnerHTML={{ __html: post?.content || '' }} />
              </Text>
            </div>

            {!!post?.tags.length && (
              <SmallTagsWrapper>
                {post.tags?.map((tag) => (
                  <SmallTag key={tag}>#{tag}</SmallTag>
                ))}
              </SmallTagsWrapper>
            )}
          </PostDescription>
        </PostCardContainer>
      </div>
    </AppContainer>
  );
};
