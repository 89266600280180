import { styled } from 'styled-components';
import { COLORS } from '../../../const';

export const Wrapper = styled.div`
  width: 100%;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & span {
    text-align: center;
    line-height: 125%;
  }
`;

export const InputBlock = styled.div`
  margin-top: 30px;
  display: flex;

  @media screen and (max-width: 450px) {
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
`;

export const Input = styled.input`
  margin-right: 12px;
  width: 306px;
  min-width: 90px;
  padding: 12px;
  border: 2px solid ${COLORS.main_green};
  border-radius: 16px;
  font-size: 20px;

  &::placeholder {
    color: #babdcc;
    font-size: 20px;
    font-family: 'Lato', sans-serif;
  }
`;

export const PdfButton = styled.div`
  margin-top: 30px;
  padding: 12px 30px;
  border-radius: 16px;
  background: linear-gradient(180deg, #99cda2 0%, #76ac98 100%);

  & a {
    color: #fff;
    font-weight: 600;
  }
`;
