import React, { useState, useEffect, SyntheticEvent, useCallback } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { API } from '../../../const';
import { AppContainer, AppButton, PostIcon, LogoIcon } from '../../../components';
import { authProvider } from '../../../utils';
import { DoctorType, Paginator, PostType } from '../../../types';
import { PostCard } from './PostCard';
import { BlogWrapper, LogoContainer } from './styled';

const firebaseConfig = {
  apiKey: 'AIzaSyDAu79nGvpKeOiQ8tN85icBYG_RxMv5Zuc',
  authDomain: 'doctorly-398211.firebaseapp.com',
  projectId: 'doctorly-398211',
  storageBucket: 'doctorly-398211.appspot.com',
  messagingSenderId: '782730215527',
  appId: '1:782730215527:web:ec88579c722562834a54c2',
  measurementId: 'G-B88Q94W7FG',
};

export const BlogPage = () => {
  const [allPosts, setAllPosts] = useState<PostType[]>([]);
  const [doctors, setDoctors] = useState<DoctorType[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>();

  const [likedPostsIds, setLikedPostsIds] = useState<string[]>([]);

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  const search = useSearchParams();

  const { token, profile, isAuth } = authProvider();
  const nav = useNavigate();

  const getLikedPostsIds = useCallback(
    async (ids: string[]) => {
      try {
        const response = await fetch(`${API}/profile/likes`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(ids),
        });
        if (response.status === 200) {
          const data: string[] = await response.json();
          setLikedPostsIds((prev) => [...prev, ...data]);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [token]
  );

  const getPosts = useCallback(async () => {
    let API_URL = `${API}/posts?page=${currentPage}`;
    // if (selectedTag) {
    //   API_URL += `&tags=${selectedTag}`;
    // }

    try {
      const response = await fetch(API_URL);
      if (response.status === 200) {
        const data: Paginator = await response.json();
        if (currentPage === 1) {
          setAllPosts(data.posts);
          setTotalPages(Math.ceil(data.total / 10));
        } else {
          setAllPosts((prev) => [...prev, ...data.posts]);
        }
        if (isAuth()) {
          const ids = data.posts.map((post) => post.id);
          getLikedPostsIds(ids);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [currentPage, getLikedPostsIds, isAuth]);

  const getDoctors = useCallback(async (doctorId: string) => {
    try {
      const response = await fetch(`${API}/doctors/${doctorId}`);
      if (response.status === 200) {
        const data: DoctorType = await response.json();
        setDoctors((prev) => [...prev, { ...data, id: doctorId }]);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  // const getTags = async () => {
  //   try {
  //     const response = await fetch(`${API}/tags`);
  //     if (response.status === 200) {
  //       const data: string[] = await response.json();
  //       setTags(data);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const setLike = async (postId: string) => {
    try {
      const response = await fetch(`${API}/posts/${postId}/likes`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const likes = await response.json();
        const currentPost = allPosts.find((post) => post.id === postId);
        if (currentPost) {
          currentPost.likes = likes;
          const indexOfPost = allPosts.indexOf(currentPost);
          const newAllPosts = allPosts.concat();
          newAllPosts.splice(indexOfPost, 1, currentPost);
          setAllPosts(newAllPosts);
          setLikedPostsIds((prev) => [...prev, currentPost.id]);
        }
      }
    } catch (e) {}
  };

  const openPost = (e: SyntheticEvent<HTMLDivElement>) => {
    const currentAlias = (e.currentTarget as Element).id;
    nav(`${currentAlias}`);
  };

  const onScrollList = useCallback(
    (e: SyntheticEvent<HTMLDivElement>) => {
      const target = e.target as HTMLElement;
      const scrollBottom = target.scrollTop + target.offsetHeight + 0.5 === target.scrollHeight;

      if (scrollBottom && totalPages && currentPage < totalPages) {
        setCurrentPage((prev) => prev + 1);
      }
    },
    [totalPages, currentPage]
  );

  const onLikeClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    const id = e.currentTarget.id;
    setLike(id);
  };

  useEffect(() => {
    if (allPosts.length) {
      const doctorIds = Array.from(new Set(allPosts.map((p) => p.created_by_id)));
      doctorIds.forEach((id) => {
        getDoctors(id);
      });
    }
  }, [allPosts, getDoctors]);

  useEffect(() => {
    getPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    search.forEach((p) => {
      const paramString = p.toString();
      const title = paramString.split('=')[0];
      if (title === 'source') {
        logEvent(analytics, paramString.split('=')[1]);
      }
    });
  }, [search, analytics]);

  return (
    <AppContainer title="" hidePanel={!isAuth()}>
      <LogoContainer>
        <LogoIcon />
      </LogoContainer>

      {profile?.is_manager && (
        <Link to="/newpost">
          <AppButton title="Создать запись" fullWidth bold icon={<PostIcon color="#FFF" />} />
        </Link>
      )}

      <BlogWrapper id="scrollable" onScroll={onScrollList}>
        {allPosts.map((post) => (
          <PostCard
            key={post.id}
            post={post}
            onClick={openPost}
            onLiked={onLikeClick}
            isLiked={likedPostsIds.includes(post.id)}
            doctor={doctors.find((d) => d.id === post.created_by_id)}
          />
        ))}
      </BlogWrapper>
    </AppContainer>
  );
};
