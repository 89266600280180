import React, { FC, SyntheticEvent } from 'react';
import { COLORS } from '../../const';
import { ResultType } from '../../types';
import { formatDate } from '../../utils';
import { Text } from '../Text';
import { StyledRow, StyledButton, Status } from './styled';

import eyePath from '../../assets/icons/eye.svg';

type TableRowProps = {
  data: ResultType;
  index: string;
  onOpenResult: (id: string) => void;
};

export const TableRow: FC<TableRowProps> = ({ data, index, onOpenResult }) => {
  const isMobile = window.screen.availWidth < 450;

  const onButtonClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    const id = (e.currentTarget as Element).id;
    onOpenResult(id);
  };

  return (
    <StyledRow>
      {isMobile && (
        <div style={{ flexDirection: 'column', alignItems: 'flex-start', flexBasis: '50%' }}>
          <Text weight={700} pb={4}>
            {index}
          </Text>
          <Text size={12} color={COLORS.text_grey}>
            {formatDate(data.created_at)}
          </Text>
        </div>
      )}
      {!isMobile && (
        <>
          <div style={{ justifyContent: 'flex-start' }}>{index}</div>
          <div>{formatDate(data.created_at)}</div>
        </>
      )}
      <div>
        <Status status={data.status}>{data.status === 'open' ? 'Открыт' : 'Закрыт'}</Status>
      </div>
      <div>
        {isMobile && (
          <button id={data.id} onClick={onButtonClick}>
            <img src={eyePath} alt="show" />
          </button>
        )}
        {!isMobile && (
          <StyledButton id={data.id} onClick={onButtonClick}>
            Посмотреть
          </StyledButton>
        )}
      </div>
    </StyledRow>
  );
};
