import React, { FC, SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { authProvider } from '../../../utils';
import { Text } from '../../Text';
import { LikeIcon } from '../../Icons';
import { Modal } from '../../Modal';
import { AppButton } from '../AppButton';

import loginPath from '../../../assets/icons/login.svg';

const LikeButtonWrapper = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: center;
`;

type LikeButtonProps = {
  postId: string;
  count: number;
  onToggle: (e: SyntheticEvent<HTMLButtonElement>) => void;
  isLiked: boolean;
};

export const LikeButton: FC<LikeButtonProps> = ({ postId, count, onToggle, isLiked }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { isAuth } = authProvider();
  const navigate = useNavigate();

  const onClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (isAuth()) {
      setIsClicked(true);
      onToggle(e);
    } else {
      setModalIsOpen(true);
    }
  };

  const onSignIn = () => {
    navigate('/auth');
  };

  const onCloseModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <LikeButtonWrapper>
        <button id={postId} onClick={onClick} disabled={isClicked || isLiked}>
          <LikeIcon clicked={isClicked || isLiked} />
        </button>

        <Text size={10}>{count}</Text>
      </LikeButtonWrapper>

      <Modal open={modalIsOpen} showCloseButton onClose={onCloseModal}>
        <Text pb={16} size={22} color="#787D93" textAlign="center">
          Чтобы оценить запись,
          <br />
          войдите
        </Text>
        <AppButton title="Войти" onClick={onSignIn} icon={<img src={loginPath} alt="login" />} />
      </Modal>
    </>
  );
};
