import React, { FC, PropsWithChildren, SyntheticEvent } from 'react';
import { styled } from 'styled-components';
import { COLORS } from '../../../const';

type ButtonProps = {
  id?: string;
  onClick: (e: SyntheticEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  size?: 'small' | 'large';
};

export const Button: FC<PropsWithChildren<ButtonProps>> = ({
  id,
  onClick,
  disabled,
  size,
  children,
}) => {
  return (
    <StyledButton id={id} onClick={onClick} disabled={disabled} size={size}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button.withConfig({
  shouldForwardProp: (p) => !['size'].includes(p),
})<{
  size?: 'small' | 'large';
}>`
  padding: ${(props) => (props.size === 'small' ? '12px 30px' : '17px 32px')};
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, ${COLORS.main_green} 0%, #76ac98 100%);
  border-radius: ${(props) => (props.size === 'small' ? '16px' : '24px')};
  color: #fff;
  font-size: ${(props) => (props.size === 'small' ? '16px' : '28px')};
  font-weight: 700;

  & img {
    margin-right: 8px;
  }

  @media screen and (max-width: 450px) {
    padding: 12px 30px;
    font-size: 16px;
  }
`;
