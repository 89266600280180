import React, { ChangeEvent, FC } from 'react';
import { COLORS } from '../../const';
import { Text } from '../Text';
import { InputWrapper, StyledInput } from './styled';

type InputProps = {
  name: string;
  value: string;
  label: string;
  onChange: (name: string, value: string) => void;
  needValidateEmail?: boolean;
  isGap?: boolean;
  error?: boolean;
  errorMessage?: string;
  onBlur?: () => void;
  type?: string;
};

export const AuthInput: FC<InputProps> = ({
  name,
  value,
  label,
  onChange,
  needValidateEmail,
  isGap,
  error,
  errorMessage,
  onBlur,
  type,
}) => {
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    onChange(name, value);
  };

  const onInputBlur = () => {
    if (needValidateEmail) {
    }
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <InputWrapper>
      {!!value && (label || error) && (
        <Text color={error ? '#e74d3f' : COLORS.text_grey} size={12} weight={500}>
          {error ? errorMessage : label}
        </Text>
      )}
      <StyledInput
        type={type ? type : 'text'}
        value={value}
        name={name}
        onChange={onInputChange}
        onBlur={onInputBlur}
        wide={isGap}
        error={error}
        placeholder={value ? '' : label}
      />
    </InputWrapper>
  );
};
